import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import BorderButton from "../../../../components/button/border-button.component";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { isLogin } from "../../../utils";
import DrawerAvatar from "./mobile-drawer-avatar.component";

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
}));

interface Props {
  handleLogout: () => void;
  isLoading: boolean;
}

function MobileDrawerAppBar({ handleLogout, isLoading }: Props) {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down("md") && theme.breakpoints.up("sm"));

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
      <IconButton
        onClick={() => {
          handleOpenDrawer();
        }}
        size="large"
      >
        <MenuIcon sx={{ color: "#fff" }} />
      </IconButton>
      <Drawer
        anchor="right"
        variant="temporary"
        open={openDrawer}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: isTablet ? "35%" : "80%",
            minWidth: "300px",
            backgroundColor: "white",
          },
        }}
      >
        <DrawerAvatar handleDrawerToggle={handleDrawerToggle} />
        <Spacer position="top" size="m" />
        <Grid container paddingX="20px" spacing="10px">
          <Grid item xs={12}>
            <StyledLink to={routes.ABOUT_US} onClick={handleDrawerToggle}>
              <Typography variant="h6" color="text.primary">
                About Us
              </Typography>
            </StyledLink>
          </Grid>
          <Grid item xs={12}>
            <StyledLink
              to={
                isLogin() ? routes.HOME + "#menu-of-the-week" : routes.LANDING + "#menu-of-the-week"
              }
              onClick={handleDrawerToggle}
            >
              <Typography variant="h6" color="text.primary">
                Menu
              </Typography>
            </StyledLink>
          </Grid>
          <Grid item xs={12}>
            <StyledLink to={routes.CATERING} onClick={handleDrawerToggle}>
              <Typography variant="h6" color="text.primary">
                Catering Service
              </Typography>
            </StyledLink>
          </Grid>
          <Grid item xs={12}>
            <StyledLink to={routes.HOME} onClick={handleDrawerToggle}>
              <Typography variant="h6" color="text.primary">
                Order Now
              </Typography>
            </StyledLink>
          </Grid>
        </Grid>

        {isLogin() && (
          <Grid item marginTop="auto">
            <PaddedView multiples={2}>
              <BorderButton
                isLoading={isLoading}
                onClick={() => {
                  handleDrawerToggle();
                  handleLogout();
                }}
              >
                Logout
              </BorderButton>
            </PaddedView>
          </Grid>
        )}
      </Drawer>
    </Box>
  );
}

export default MobileDrawerAppBar;
