import { Grid, List, ListItem, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BackgroundButton from "../../../../components/button/background-button.component";
import routes from "../../../../components/navigation/routes";
import Loading from "../../../../components/notification/backdrop-loading.component";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import HorizontalDivider from "../../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { orderSelector, payOrder } from "../../../../services/order/order-slice.service";
import { useAppDispatch, useAppSelector } from "../../../../services/store";
import StageProgressBar from "../../components/stage-progress-bar.component";

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
}));

const SameDayOrderSummaryScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { sameDayOrderCreatedObj, sameDayOrderUpdatingObj } = useAppSelector(orderSelector);
  const isUpdateOrder = location.pathname === routes.SAME_DAY_ORDER_EDIT_SUMMARY;
  const orderObject = isUpdateOrder ? sameDayOrderUpdatingObj : sameDayOrderCreatedObj;
  const snackBar = useSnackBar();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!orderObject) {
      navigate(routes.HOME);
    }
  }, []);

  const confirmOrder = () => {
    setIsLoading(true);
    dispatch(payOrder({ payment_method: "others", order_id: orderObject?.id }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          setIsLoading(false);
          window.open(res.data.bill.remote_url, "_self");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const renderFoodSelections = (type: "meat" | "vege" | "side" | "carb" | "addon") =>
    orderObject &&
    orderObject.item &&
    orderObject.item.foods[type] &&
    orderObject.item.foods[type].length > 0 ? (
      <Grid item>
        <Typography fontWeight="bold" textTransform="capitalize">
          {type}:
        </Typography>
        <List sx={{ listStyleType: "decimal", pl: 5 }}>
          {orderObject.item.foods[type].map((item) => (
            <ListItem
              sx={{ display: "list-item", textAlign: "justify" }}
              key={item.id + item.identifier}
            >
              {isMobile ? (
                <Grid item>
                  <Typography>{item.identifier}</Typography>
                  <Typography>
                    ({item.no_of_pax} x RM {item.price})
                  </Typography>
                  <Typography textAlign="right" color="primary.dark" fontWeight="bold">
                    RM {(item.no_of_pax * parseFloat(item.price.replaceAll(",", ""))).toFixed(2)}
                  </Typography>
                </Grid>
              ) : (
                <Grid container justifyContent="space-between">
                  <Typography>
                    {item.identifier} x {item.no_of_pax} (RM {item.price})
                  </Typography>
                  <Typography color="primary.dark" fontWeight="bold">
                    RM {(item.no_of_pax * parseFloat(item.price.replaceAll(",", ""))).toFixed(2)}
                  </Typography>
                </Grid>
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    ) : null;

  return (
    orderObject &&
    orderObject.item && (
      <Grid container display="flex" flexDirection="column" rowSpacing={4}>
        <Loading isLoading={isLoading} />
        <Grid item xs={12}>
          <StageProgressBar />
        </Grid>
        <Grid item marginTop="50px">
          <StyledLink to={isUpdateOrder ? routes.SAME_DAY_ORDER_EDIT : routes.SAME_DAY_ORDER}>
            <Typography fontWeight="bold" color="primary.dark">
              {"<"} Edit Order Items
            </Typography>
          </StyledLink>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              borderRadius: theme.shape.borderSizes[1],
              backgroundColor: "bg.primary",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  borderRight: isTablet ? undefined : "1px solid",
                  borderColor: "text.disabled",
                }}
              >
                <PaddedView multiples={4}>
                  <Grid container flexDirection="column" spacing={1}>
                    <Grid item display="flex">
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ borderBottom: "3px solid", borderColor: "primary.main" }}
                      >
                        Items Selected
                      </Typography>
                    </Grid>
                    <Spacer size="l" />
                    {isUpdateOrder && (
                      <>
                        <Grid item>
                          <Typography fontWeight="bold">Order No: #{orderObject.id}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            fontWeight="bold"
                            textTransform="capitalize"
                            color={
                              orderObject.status === "ongoing"
                                ? "primary.main"
                                : orderObject.status === "pending_payment"
                                  ? "error.main"
                                  : "success.main"
                            }
                          >
                            <span style={{ color: theme.palette.text.primary }}>Status: </span>
                            {orderObject.status.replace("_", " ")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            fontWeight="bold"
                            textTransform="capitalize"
                            color={orderObject.item.confirmed ? "success.main" : "error"}
                          >
                            Order Confirmed: {orderObject.item.confirmed ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      <Typography fontWeight="bold" color="text.secondary">
                        Selected Date:{" "}
                        <span style={{ color: theme.palette.text.primary }}>
                          {format(new Date(), "dd MMM yyyy")}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontWeight="bold" color="text.secondary">
                        Pick Up Time:{" "}
                        <span style={{ color: theme.palette.text.primary }}>
                          {dayjs(new Date(orderObject.item.date_time)).format("h:mm A")} -{" "}
                          {dayjs(new Date(orderObject.item.date_time)).add(1, "h").format("h:mm A")}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <HorizontalDivider />
                    </Grid>
                    {orderObject.item.remark && (
                      <Grid item>
                        <Typography fontWeight="bold" color="text.secondary">
                          Remark: {orderObject.item.remark}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item>
                      <Typography fontWeight="bold" color="text.secondary">
                        Food Selection:
                      </Typography>
                    </Grid>
                    {renderFoodSelections("meat")}
                    {renderFoodSelections("vege")}
                    {renderFoodSelections("side")}
                    {renderFoodSelections("carb")}
                    {renderFoodSelections("addon")}
                  </Grid>
                </PaddedView>
              </Grid>
              <Grid item xs={12} md={5}>
                <Grid
                  item
                  sx={{
                    backgroundColor: "primary.main",
                    borderTopRightRadius: isTablet ? undefined : theme.shape.borderSizes[1],
                  }}
                >
                  <PaddedView multiples={4}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="text.white"
                      textTransform="uppercase"
                    >
                      Order Summary
                    </Typography>
                  </PaddedView>
                </Grid>
                <Grid item>
                  <PaddedView multiples={4}>
                    <Grid container flexDirection="column" spacing={1}>
                      <Grid item>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Typography
                              variant={isMobile ? "body2" : "h6"}
                              fontWeight="bold"
                              textTransform="uppercase"
                            >
                              Subtotal
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant={isMobile ? "body2" : "h6"}
                              fontWeight="bold"
                              color="primary.dark"
                            >
                              RM {orderObject.payment_summary.subtotal}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {orderObject.payment_summary.paid.total_amount !== "0.00" && (
                        <>
                          <Grid item>
                            <Grid container justifyContent="space-between">
                              <Grid item>
                                <Typography
                                  variant={isMobile ? "body2" : "h6"}
                                  fontWeight="bold"
                                  textTransform="uppercase"
                                >
                                  Total Paid
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant={isMobile ? "body2" : "h6"}
                                  fontWeight="bold"
                                  color="primary.dark"
                                >
                                  RM {orderObject.payment_summary.paid.total_amount}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid item sx={{ marginTop: "auto" }}>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Typography
                              variant={isMobile ? "body2" : "h6"}
                              fontWeight="bold"
                              textTransform="uppercase"
                            >
                              Total
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant={isMobile ? "body2" : "h6"}
                              fontWeight="bold"
                              color="primary.dark"
                            >
                              RM {orderObject.payment_summary.total}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {orderObject.payment_summary.paid.total_amount !== "0.00" && (
                        <>
                          {orderObject.payment_summary.outstanding_total !== "0.00" && (
                            <>
                              <Grid item>
                                <Grid container justifyContent="space-between">
                                  <Grid item>
                                    <Typography
                                      variant={isMobile ? "body2" : "h6"}
                                      fontWeight="bold"
                                      textTransform="uppercase"
                                      color="error"
                                    >
                                      Outstanding Total
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      variant={isMobile ? "body2" : "h6"}
                                      fontWeight="bold"
                                      color="error"
                                    >
                                      RM {orderObject.payment_summary.outstanding_total}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          )}
                          {orderObject.payment_summary.excess_total !== "0.00" && (
                            <>
                              <Spacer />
                              <Grid item>
                                <Grid container justifyContent="space-between">
                                  <Grid item>
                                    <Typography
                                      variant={isMobile ? "body2" : "h6"}
                                      fontWeight="bold"
                                      textTransform="uppercase"
                                      color="error"
                                    >
                                      Excess Total
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      variant={isMobile ? "body2" : "h6"}
                                      fontWeight="bold"
                                      color="error"
                                    >
                                      RM {orderObject.payment_summary.excess_total}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </PaddedView>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {orderObject.payment_summary.require_payment && (
          <>
            <Spacer size="m" />
            <Grid container display="flex" justifyContent="center">
              <Grid item xs={12} sm={3}>
                <BackgroundButton onClick={confirmOrder} isLoading={isLoading}>
                  Confirm Order
                </BackgroundButton>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    )
  );
};

export default SameDayOrderSummaryScreen;
