import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Catering1 from "../../../assets/images/catering-1.png";
import Catering2 from "../../../assets/images/catering-2.png";
import Order1 from "../../../assets/images/order-1.png";
import Order2 from "../../../assets/images/order-2.png";
import routes from "../../../components/navigation/routes";
import theme from "../../../infrastructure/theme";
import OrderCateringBox from "./order-catering-box.component";

function ExploreOrderCateringSection() {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <Grid container spacing={isMobile ? 10 : 6} paddingTop="150px">
      <Grid item xs={isMobile ? 12 : 6}>
        <OrderCateringBox
          image1={Order1}
          image2={Order2}
          content={
            <Typography color="text.secondary" textAlign="justify">
              Enjoy the ease of our{" "}
              <span style={{ fontWeight: "bold" }}>Food Delivery Service</span>. With a diverse menu
              of fresh, locally sourced meals, we bring the flavours to your doorstep. Simply order,
              sit back, and savour.
            </Typography>
          }
          buttonText="Order Now"
          onClick={() => {
            navigate(routes.HOME);
          }}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <OrderCateringBox
          image1={Catering1}
          image2={Catering2}
          content={
            <Typography color="text.secondary" textAlign="justify">
              Enhance your event with our{" "}
              <span style={{ fontWeight: "bold" }}>Catering Services</span>. From corporate
              functions to intimate gatherings, we provide tailored culinary excellence, making
              every moment memorable.
            </Typography>
          }
          buttonText="Explore Catering"
          onClick={() => {
            navigate(routes.CATERING);
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ExploreOrderCateringSection;
