import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackgroundButton from "../../../components/button/background-button.component";
import BorderButton from "../../../components/button/border-button.component";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";
import {
  cancelOrderBill,
  getOrderBill,
  orderBillSelector,
} from "../../../services/order/bill/order-bill-slice.service";
import { useAppDispatch, useAppSelector } from "../../../services/store";

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "50px",
});

const StyledTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  textAlign: "center",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: "center",
}));

export default function BillFailedScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getOrderBillObj, cancelOrderBillObj } = useAppSelector(orderBillSelector);

  useEffect(() => {
    dispatch(getOrderBill(location.state.remoteBillId));
  }, []);

  const handleCancelBill = () => {
    dispatch(cancelOrderBill(getOrderBillObj.data.bill.id)).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.HOME);
      }
    });
  };

  return (
    <StyledContainer>
      <Spacer size="xl" />
      <WarningRoundedIcon color="error" sx={{ fontSize: "150px" }} />
      <Spacer size="m" />
      <StyledTitle variant={isMobile ? "h4" : "h3"}>Payment Failed!</StyledTitle>
      <Spacer size="m" />
      <GreyText>
        Opps…Seems like something is wrong while the payment is processing. Please try again later.
      </GreyText>
      <Spacer size="xxxl" />
      <Grid container justifyContent="center">
        <Grid item xs={4}>
          <BackgroundButton
            isLoading={getOrderBillObj.status === "pending"}
            onClick={() => window.open(getOrderBillObj.data.bill.remote_url, "_self")}
          >
            Retry
          </BackgroundButton>
        </Grid>
      </Grid>
      <Spacer size="m" />
      <Grid container justifyContent="center">
        <Grid item xs={4}>
          <BorderButton
            isLoading={
              getOrderBillObj.status === "pending" || cancelOrderBillObj.status === "pending"
            }
            onClick={handleCancelBill}
          >
            Cancel
          </BorderButton>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
