import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import FormSelectFieldText, {
  selections,
} from "../../../../components/forms/form-select-field-text.component";
import CustomSkeleton from "../../../../components/utils/custom-skeleton.component";
import { foodSetSelector } from "../../../../services/food-set/food-set-slice.services";
import { useAppSelector } from "../../../../services/store";
import { FoodSetObjectType, PreorderMealPlanFormValues } from "../type/type";

const MealPlanForm = () => {
  const { setFieldValue, values } = useFormikContext<PreorderMealPlanFormValues>();
  const [paxSelections, setPaxSelections] = useState<selections[]>([]);
  const [setSelections, setSetSelections] = useState<selections[]>([]);
  const [filterPaxSelections, setFilterPaxSelection] = useState<selections[]>(paxSelections);
  const [filterSetSelections, setFilterSetSelection] = useState<selections[]>(setSelections);
  const { getFoodSetsObj } = useAppSelector(foodSetSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (getFoodSetsObj.status === "succeeded") {
      const minNoPax = getFoodSetsObj.data.reduce((a: FoodSetObjectType, b: FoodSetObjectType) =>
        a.rule.minNoPax < b.rule.minNoPax ? a : b,
      ).rule.minNoPax;

      const maxNoPax = getFoodSetsObj.data.reduce((a: FoodSetObjectType, b: FoodSetObjectType) =>
        a.rule.maxNoPax > b.rule.maxNoPax ? a : b,
      ).rule.maxNoPax;

      const selections = [];
      for (let i = minNoPax; i <= maxNoPax; i++) {
        selections.push({ type: i, value: i });
      }
      setPaxSelections(selections);
      setFilterPaxSelection(selections);

      setSetSelections(
        getFoodSetsObj.data.map((item: FoodSetObjectType) => ({ type: item.name, value: item.id })),
      );
      setFilterSetSelection(
        getFoodSetsObj.data.map((item: FoodSetObjectType) => ({ type: item.name, value: item.id })),
      );
    }
  }, [getFoodSetsObj.status]);

  useEffect(() => {
    if (values.no_of_pax) {
      const newSetSelection = getFoodSetsObj.data
        .filter(
          (item: FoodSetObjectType) =>
            values.no_of_pax >= item.rule.minNoPax && values.no_of_pax <= item.rule.maxNoPax,
        )
        .map((item: FoodSetObjectType) => ({ type: item.name, value: item.id }));

      if (
        !newSetSelection.some(
          (item: { type: string; value: number }) => item.value === values.food_set_id,
        )
      ) {
        setFieldValue("food_set_id", "");
      }
      setFilterSetSelection(newSetSelection);
    }
  }, [values.no_of_pax]);

  return (
    <Grid container rowSpacing={2}>
      <Grid
        container
        item
        display="flex"
        alignItems="center"
        justifyContent="center"
        rowSpacing={1}
      >
        <Grid item xs={isMobile ? 12 : 4}>
          <Typography variant="h6" fontWeight="bold" color="text.secondary">
            No of pax you are ordering for:
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          {getFoodSetsObj.status === "succeeded" ? (
            <FormSelectFieldText name="no_of_pax" selections={filterPaxSelections} />
          ) : (
            <CustomSkeleton height={40} />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        display="flex"
        alignItems="center"
        justifyContent="center"
        rowSpacing={1}
      >
        <Grid item xs={isMobile ? 12 : 4}>
          <Typography variant="h6" fontWeight="bold" color="text.secondary">
            Select Your Set:
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          {getFoodSetsObj.status === "succeeded" ? (
            <FormSelectFieldText name="food_set_id" selections={filterSetSelections} />
          ) : (
            <CustomSkeleton height={40} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MealPlanForm;
