import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import FoodItemCarouselLoader from "../loaders/food-item-carousel-loader.component";
import { PreorderItemFormValues } from "../preorder/type/type";
import { SameDayFormValues } from "../same-day/type/type";
import { OrderFoods } from "../type/type";
import FoodComponent from "./food-component";

interface Rule {
  min: number;
  max: number;
}

interface Props {
  foods: OrderFoods | null | undefined;
  rules?: {
    total_vege_side_combination: number;
    meat: Rule;
    vege: Rule;
    side: Rule;
    carb: Rule;
    addon: Rule;
  };
}

const SelectFoodSection = ({ foods, rules }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const { values } = useFormikContext<SameDayFormValues | PreorderItemFormValues>();

  const renderFoodSelections = (type: "meat" | "vege" | "side" | "carb" | "addon") =>
    foods ? (
      <ItemCarousel
        slidesToShow={isIpad ? (isMobile ? 1 : 2) : 3}
        slidesToScroll={isMobile || isIpad ? 1 : 2}
        showArrowOnMobileView
        arrowMarginTop="25px"
      >
        {foods[type].map((item) => (
          <FoodComponent
            type={type}
            key={item.id + item.identifier}
            itemDetails={item}
            rules={rules}
          />
        ))}
      </ItemCarousel>
    ) : (
      <FoodItemCarouselLoader />
    );

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold" color="text.secondary">
          Select Your Order:
        </Typography>
      </Grid>
      {values.date ? (
        !foods ||
        (foods &&
          !(foods.meat.length === 0 && foods.vege.length === 0 && foods.side.length === 0)) ? (
          <>
            {foods && foods.meat.length !== 0 && (
              <>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Typography variant="h6" fontWeight="bold" color="text.secondary">
                      Meat
                    </Typography>
                    {rules && (
                      <>
                        <Spacer position="left" size="m" />
                        <Typography fontWeight="bold" color="error" fontStyle="italic">
                          *Select {rules.meat.max === 1 ? "ONE" : rules.meat.max} only
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {renderFoodSelections("meat")}
                </Grid>
              </>
            )}

            {foods && foods.vege.length !== 0 && (
              <>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Typography variant="h6" fontWeight="bold" color="text.secondary">
                        Vege
                      </Typography>
                      {rules && (
                        <>
                          <Spacer position="left" size="m" />
                          {rules.vege.min !== 0 ? (
                            <Typography fontWeight="bold" color="error" fontStyle="italic">
                              *Select {rules.vege.max === 1 ? "ONE" : rules.vege.max} only
                            </Typography>
                          ) : (
                            <Typography fontWeight="bold" color="error" fontStyle="italic">
                              *Select maximum {rules.vege.max === 1 ? "ONE" : rules.vege.max} only
                              OR Select maximum {rules.side.max === 1 ? "ONE" : rules.side.max} side
                            </Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {renderFoodSelections("vege")}
                </Grid>
              </>
            )}

            {foods && foods.side.length !== 0 && (
              <>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Typography variant="h6" fontWeight="bold" color="text.secondary">
                        Side
                      </Typography>
                      {rules && (
                        <>
                          <Spacer position="left" size="m" />
                          {rules.side.min !== 0 ? (
                            <Typography fontWeight="bold" color="error" fontStyle="italic">
                              *Select {rules.side.max === 1 ? "ONE" : rules.side.max} only
                            </Typography>
                          ) : (
                            <Typography fontWeight="bold" color="error" fontStyle="italic">
                              *Select maximum {rules.side.max === 1 ? "ONE" : rules.side.max} only
                              OR Select maximum {rules.vege.max === 1 ? "ONE" : rules.vege.max} vege
                            </Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {renderFoodSelections("side")}
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Typography variant="h6" fontWeight="bold" color="text.secondary">
                    Carb
                  </Typography>
                  {rules && rules.carb.max !== 0 && (
                    <>
                      <Spacer position="left" size="m" />
                      <Typography fontWeight="bold" color="error" fontStyle="italic">
                        *Select ONE only
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {renderFoodSelections("carb")}
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Typography variant="h6" fontWeight="bold" color="text.secondary">
                    Add On
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {renderFoodSelections("addon")}
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <PaddedView multiples={4}>
              <Typography color="error" variant="h5" textAlign="center">
                Menu of the week is not ready.
              </Typography>
            </PaddedView>
          </Grid>
        )
      ) : (
        <Grid item xs={12}>
          <PaddedView multiples={4}>
            <Typography color="error" variant="h5" textAlign="center">
              Please select a date first.
            </Typography>
          </PaddedView>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectFoodSection;
