import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import * as Yup from "yup";
import FormCheckBox from "../../../../components/forms/form-checkbox.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import FormTextField from "../../../../components/forms/form-text-field.component";
import LabelLink from "../link-with-label.component";
import theme from "../../../../infrastructure/theme";
import { LoginFormValues } from "../../type/type";
import { Link } from "react-router-dom";

interface Props {
  handleSubmit: (values: LoginFormValues) => void;
  isLoading: boolean;
}

function LoginForm({ handleSubmit, isLoading }: Props) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().min(4).label("Password"),
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const initialValues: LoginFormValues = {
    email: localStorage.getItem("userEmail") || "",
    password: localStorage.getItem("userPassword") || "",
    isRemember: localStorage.getItem("isRemember") === "true" || false,
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.shape.borderSizes[2],
          flex: 1,
          maxWidth: 600,
          minWidth: isMobile ? undefined : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: "primary.main",
            paddingTop: "1px",
            paddingBottom: "12px",
            borderTopLeftRadius: theme.shape.borderSizes[2],
            borderTopRightRadius: theme.shape.borderSizes[2],
          }}
        >
          <TopTitleBox title="Log In" requireCloseButton={false} isWhiteTitle />
        </Box>

        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <PaddedView multiples={isMobile ? 3 : 4}>
            <FormTextField label="Email" name="email" showIcon={true} icon={<MailOutlineIcon />} />
            <Spacer size="m" />

            <FormTextField
              label="Password"
              name="password"
              showIcon={true}
              icon={<LockOutlinedIcon />}
            />
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ paddingTop: 1 }}
            >
              <Grid sx={{ width: 160 }}>
                <FormCheckBox name="isRemember" label="Remember Me" />
              </Grid>
              <Grid alignItems="center" justifyContent="center">
                <Link to={routes.FORGOT_PASSWORD} style={{ textDecoration: "none" }}>
                  <Typography color="primary">Forgot Password ?</Typography>
                </Link>
              </Grid>
            </Grid>
            <Spacer size="xl" />

            <Grid item container justifyContent="center">
              <Grid item textAlign="center" width="60%">
                <FormSubmitButton isLoading={isLoading}>Sign In</FormSubmitButton>
              </Grid>
            </Grid>

            <Spacer size="s" />
            <LabelLink label=" Don't have an account? " link="Sign Up" pushTo={routes.SIGN_UP} />
          </PaddedView>
        </Form>
      </Box>
    </Box>
  );
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoginForm;
