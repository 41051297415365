import { Grid, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";

import BorderButton from "../../../components/button/border-button.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import Spacer from "../../../components/utils/spacer.component";

interface Props {
  gender: string;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  handleSubmit: (values: any) => void;
  isGenderEdit: boolean;
  setIsGenderEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

function UpdateGenderForm({
  gender,
  setIsEditing,
  isEditing,
  handleSubmit,
  isGenderEdit,
  setIsGenderEdit,
  isLoading,
}: Props) {
  const validationSchema = Yup.object().shape({
    gender: Yup.string().nullable().notRequired().label("Gender"),
  });

  const handleEditPress = () => {
    setIsEditing(true);
    setIsGenderEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsGenderEdit(false);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography>Gender</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isGenderEdit ? (
                <BorderButton onClick={handleCancelPress}>Cancel</BorderButton>
              ) : (
                <BorderButton onClick={handleEditPress} disabled={isEditing}>
                  Edit
                </BorderButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isGenderEdit ? (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                gender,
              }}
              onSubmit={handleSubmit}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Spacer size="m" />
                    <FormSelectFieldText
                      name="gender"
                      selections={[
                        { type: "Male", value: "male" },
                        { type: "Female", value: "female" },
                        { type: "Others", value: "others" },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading}>Save</FormSubmitButton>
            </Form>
          ) : (
            <Typography color="text.secondary">
              {gender === "" ? "Not Provided" : gender.charAt(0).toUpperCase() + gender.slice(1)}
            </Typography>
          )}
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="s" />
    </>
  );
}

export default UpdateGenderForm;
