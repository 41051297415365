import { Box, Popper, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { FormikValues, useFormikContext } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import {
  addressSelector,
  getAddressList,
} from "../../../../services/address/address-slice.services";
import { useAppDispatch, useAppSelector } from "../../../../services/store";
import { AddressResource } from "../../../address/type/type";

function CustomPopper(props: any) {
  return <Popper {...props} placement="bottom" />;
}

interface Props {
  name: string;
  disabled: boolean;
}

interface OptionType {
  id: number | string;
  option: string;
}

export default function AddressAutoComplete({ disabled, name }: Props) {
  const dispatch = useAppDispatch();
  const { getAddressListObj } = useAppSelector(addressSelector);
  const snackBar = useSnackBar();
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext<FormikValues>();
  const showError = touched[name] && typeof errors[name] === "string";
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [planKeyword, setPlanKeyword] = useState("");
  const [loadingMoreResults, setLoadingMoreResults] = useState(false);

  const listOnChange = (value: OptionType | null) => {
    setFieldValue(name, value);
    const deliveryDetail = getAddressListObj.data.items.find((item) => item.id === value?.id);
    setFieldValue(
      "deliveryFee",
      deliveryDetail ? parseFloat(deliveryDetail.polygon.total_chargeable.replaceAll(",", "")) : 0,
    );
    setFieldValue(
      "deliveryAddressLatLong",
      deliveryDetail ? `${deliveryDetail.location.lat}${deliveryDetail.location.long}` : "",
    );
  };

  const loadMoreResults = () => {
    let newPage = page;
    if (newPage < totalPage) {
      setLoadingMoreResults(true);
      const newOptions = options;
      setOptions([...newOptions, { id: "loading", option: "loading" }]);
      newPage += 1;
      setPage(newPage);
      dispatch(getAddressList({ q: planKeyword, page: newPage }))
        .unwrap()
        .then((res) => {
          if (res.success) {
            setLoadingMoreResults(false);
            setOptions([
              ...newOptions,
              res.data.items.map((item: AddressResource) => ({
                id: item.id,
                option: `${item.identifier} | RM ${item.polygon.total_chargeable} | ${item.location.address}`,
              })),
            ]);
          }
        })
        .catch((err) => {
          snackBar.createSnackBar({
            message: `Failed to get class auto complete suggestion! ${err.message}`,
            type: "error",
            open: true,
          });
        });
    }
  };

  const onSearchKeywordChange = (keyword: string) => {
    setPage(1);
    setOptions([]);
    dispatch(getAddressList({ q: keyword, page: 1 }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPage(res.data.pagination.totalPages);
          setOptions(
            res.data.items.map((item: AddressResource) => ({
              id: item.id,
              option: `${item.identifier} | RM ${item.polygon.total_chargeable} | ${item.location.address}`,
            })),
          );
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: `Failed to get auto complete suggestion! ${err.message}`,
          type: "error",
          open: true,
        });
      });
  };

  useEffect(() => {
    onSearchKeywordChange("");
  }, []);

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <Autocomplete
        filterOptions={(x) => x}
        loading={options.length === 0}
        loadingText={getAddressListObj.status === "succeeded" ? "No options" : "Loading..."}
        ListboxProps={{
          role: "list-box",
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              !loadingMoreResults &&
              listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight
            ) {
              loadMoreResults();
            }
          },
        }}
        PopperComponent={CustomPopper}
        disabled={disabled}
        onChange={(e, value) => listOnChange(value)}
        options={options}
        getOptionDisabled={(option) => option.option === "loading"}
        value={values[name]}
        getOptionLabel={(option: OptionType) => option.option}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <li key={option.id} {...props}>
            {option.option === "loading" ? (
              <Typography color="text.secondary">Loading...</Typography>
            ) : (
              <Typography>{option.option}</Typography>
            )}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <DebounceInput
            {...params}
            size="small"
            debounceTimeout={500}
            error={showError}
            onBlur={() => setFieldTouched(name)}
            helperText={showError && errors[name]}
            element={TextField}
            variant="outlined"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPlanKeyword(e.target.value);
              onSearchKeywordChange(e.target.value);
            }}
          />
        )}
      />
    </Box>
  );
}

AddressAutoComplete.defaultProps = {
  disabled: false,
};
