import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import YiJiaLogo from "../../../assets/images/YiJia_logo";
import ResendButton from "../../../components/button/resend-button.component";
import routes from "../../../components/navigation/routes";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import {
  emailVerification,
  resendVerificationCode,
} from "../../../services/auth/auth-slice.service";
import { useAppDispatch } from "../../../services/store";

function RegisterVerificationCodeScreen() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const snackBar = useSnackBar();

  const handleVerifyCode = (value: string) => {
    dispatch(emailVerification({ verificationCode: value }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          navigate(routes.HOME);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleSubmitResendCodes = () => {
    const values = {
      type: location.state.isUpdateEmail ? "updateEmail" : "register",
      email: location.state.email,
    };

    dispatch(resendVerificationCode(values))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  useEffect(() => {
    if (location.state.resendCode) {
      handleSubmitResendCodes();
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "bg.primary",
        minHeight: "80vh",
        opacity: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 0 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <YiJiaLogo />
              </Grid>
              <Spacer size="m" />
              <Box>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: theme.shape.borderSizes[1],
                    flex: 1,
                    maxWidth: 500,
                    minWidth: isMobile ? null : 450,
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: 2,
                    overflow: "hidden",
                  }}
                >
                  <TopTitleBox title="Email Verification" requireCloseButton={false} />

                  <PaddedView multiples={isMobile ? 2 : 4}>
                    <Grid item xs={12}>
                      <Typography fontWeight="bold">Enter verification code</Typography>
                    </Grid>
                    <Spacer />
                    <Grid item xs={12}>
                      <Typography>
                        A verification code has been sent to {location.state.email}
                      </Typography>
                    </Grid>
                    <Spacer size="l" />
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                      <ReactCodeInput
                        fieldWidth={50}
                        onComplete={(value) => handleVerifyCode(value)}
                      />
                    </Grid>
                    <Spacer size="s" />
                    <Grid item xs={12} textAlign="center">
                      <ResendButton handleSubmit={handleSubmitResendCodes} />
                    </Grid>
                    <Spacer size="xl" />
                  </PaddedView>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default RegisterVerificationCodeScreen;
