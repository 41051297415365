import { ButtonBase, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { useLocation } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import ItemCarousel from "../../../../components/utils/item-carousel.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import { orderSelector } from "../../../../services/order/order-slice.service";
import { useAppSelector } from "../../../../services/store";
import { PreorderItemFormValues } from "../type/type";

interface Props {
  resetPreorderForm: () => void;
  getFoodByDateFromOrder: (date: any) => void;
}

const PreorderMealDateCarouselSection = ({ resetPreorderForm, getFoodByDateFromOrder }: Props) => {
  const location = useLocation();
  const { preorderCreatedObj, preorderUpdatingObj } = useAppSelector(orderSelector);
  const isUpdateOrder = location.pathname === routes.PREORDER_ITEM_EDIT;
  const orderObject = isUpdateOrder ? preorderUpdatingObj : preorderCreatedObj;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const { setFieldValue, values, setErrors } = useFormikContext<PreorderItemFormValues>();

  const handleOnClick = (index: number) => {
    setErrors({});
    if (values.currentMealIndex !== index) {
      if (orderObject && orderObject.items.length < index) {
        resetPreorderForm();
      } else if (orderObject && orderObject.items[index - 1]) {
        const createdItem = orderObject?.items[index - 1];
        setFieldValue("type", createdItem.type);
        setFieldValue("date", dayjs(createdItem.date_time.slice(0, 10)));
        setFieldValue("remark", createdItem.remark ? createdItem.remark : "");
        setFieldValue("pickUpTime", createdItem.date_time.slice(-8));
        setFieldValue(
          "meat",
          createdItem.foods.meat.map((item) => item.id),
        );
        setFieldValue(
          "vege",
          createdItem.foods.vege.map((item) => item.id),
        );
        setFieldValue(
          "side",
          createdItem.foods.side.map((item) => item.id),
        );
        setFieldValue("carb", createdItem.foods.carb);
        setFieldValue("addon", createdItem.foods.addon);
        setFieldValue(
          "address_id",
          createdItem.delivery
            ? { id: createdItem.delivery.address.id, option: createdItem.delivery.address.location }
            : null,
        );
        setFieldValue(
          "deliveryFee",
          createdItem.delivery
            ? parseFloat(createdItem.delivery.polygon.fee.replaceAll(",", ""))
            : 0,
        );
        setFieldValue(
          "deliveryAddressLatLong",
          createdItem.delivery
            ? `${createdItem.delivery.address.lat}${createdItem.delivery.address.long}`
            : "",
        );
        setFieldValue("currentMealEditable", createdItem.editable);
        getFoodByDateFromOrder(dayjs(createdItem.date_time.slice(0, 10)));
      }
      setFieldValue("currentMealIndex", index);
    }
  };

  const renderMealDateBox = () => {
    const mealBox = [];

    if (orderObject) {
      for (let i = 1; i <= orderObject.total_meals; i++) {
        mealBox.push(
          <ButtonBase
            key={i}
            onClick={() => handleOnClick(i)}
            disabled={
              i > orderObject.items.length + 1 ||
              (orderObject.items[i - 1] && orderObject.items[i - 1].status === "completed")
            }
            disableTouchRipple
            sx={{
              width: "100%",
              textAlign: "center",
              border: "2px solid",
              borderColor: "primary.main",
              borderRadius: theme.shape.borderSizes[1],
              backgroundColor: values.currentMealIndex === i ? "primary.main" : "",
              opacity:
                i > orderObject.items.length + 1 ||
                (orderObject.items[i - 1] && orderObject.items[i - 1].status === "completed")
                  ? 0.5
                  : 1,
            }}
          >
            <PaddedView multiples={1}>
              <Typography
                fontWeight="bold"
                color={values.currentMealIndex === i ? "text.white" : "text.primary"}
              >
                Meal {i}
              </Typography>
              {orderObject.items.length >= i ? (
                <Typography color={values.currentMealIndex === i ? "text.white" : "text.primary"}>
                  {dayjs(orderObject.items[i - 1].date_time).format("DD-MMM-YYYY")}
                </Typography>
              ) : (
                <Typography color={values.currentMealIndex === i ? "text.white" : "text.primary"}>
                  {values.currentMealIndex === i ? "Select a date" : "Decide Later"}
                </Typography>
              )}
            </PaddedView>
          </ButtonBase>,
        );
      }
    }

    return mealBox;
  };

  return (
    <Grid container display="flex" alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <ItemCarousel
          initialSlide={values.currentMealIndex - 1}
          slidesToShow={isIpad ? (isMobile ? 2 : 5) : 6}
          slidesToScroll={isMobile ? 2 : 3}
          showArrowOnMobileView
        >
          {renderMealDateBox()}
        </ItemCarousel>
      </Grid>
    </Grid>
  );
};

export default PreorderMealDateCarouselSection;
