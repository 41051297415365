import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import YiJiaLogo from "../../../../assets/images/YiJia_logo";
import BackgroundButton from "../../../../components/button/background-button.component";
import routes from "../../../../components/navigation/routes";
import { isLogin } from "../../../utils";
import AvatarMenu from "./avatar-with-menu.component";

const AppBarContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  width: "100%",
  paddingTop: "10px",
}));

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  color: "white",
}));

interface Props {
  handleLogout: () => void;
}

function PCViewAppBar({ handleLogout }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <AppBarContainer
      sx={{
        padding: "6px",
        maxWidth: theme.dimensions.PCMaxWidth,
      }}
    >
      <Grid container display="flex" alignItems="center" textAlign="center">
        <Grid item xs={2}>
          <YiJiaLogo white={true} />
        </Grid>
        <Grid item xs={2}>
          <StyledLink to={routes.ABOUT_US}>
            <Typography fontWeight="bold">About Us</Typography>
          </StyledLink>
        </Grid>
        <Grid item xs={2}>
          <StyledLink
            to={
              isLogin() ? routes.HOME + "#menu-of-the-week" : routes.LANDING + "#menu-of-the-week"
            }
            onClick={() => document.getElementById("menu-of-the-week")?.scrollIntoView()}
          >
            <Typography fontWeight="bold">Menu</Typography>
          </StyledLink>
        </Grid>
        <Grid item xs={2}>
          <StyledLink to={routes.CATERING}>
            <Typography fontWeight="bold">Catering Services</Typography>
          </StyledLink>
        </Grid>
        <Grid item xs={3} paddingX="50px">
          <BackgroundButton white onClick={() => navigate(routes.HOME)}>
            Order Now
          </BackgroundButton>
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="flex-end">
          <AvatarMenu handleLogout={handleLogout} />
        </Grid>
      </Grid>
    </AppBarContainer>
  );
}

export default PCViewAppBar;
