export default Object.freeze({
  LOGIN: "/login",
  SIGN_UP: "/sign_up",
  FORGOT_PASSWORD: "/forgot_password",
  EMAIL_VERIFICATION_CODE: "/email_verification_code",
  FORGOT_PASSWORD_VERIFICATION_CODE: "/forgot_password_verification_code",
  DELIVERY_POLICY: "/delivery_policy",
  PRIVACY_POLICY: "/privacy_policy",
  TERMS: "/terms",
  FAQ: "/faq",

  PREORDER_MEAL_PLAN: "/preorder_meal_plan",
  PREORDER_ITEM: "/preorder_item",
  PREORDER_MEAL_SUMMARY: "/preorder_meal_summary",
  PREORDER_ITEM_EDIT: "/preorder_item/edit",
  PREORDER_MEAL_EDIT_SUMMARY: "/preorder_meal_edit_summary",
  SAME_DAY_ORDER: "/same_day_order",
  SAME_DAY_ORDER_SUMMARY: "/same_day_order_summary",
  SAME_DAY_ORDER_EDIT: "/same_day_order/edit",
  SAME_DAY_ORDER_EDIT_SUMMARY: "/same_day_order_edit_summary",

  LANDING: "/",
  HOME: "/home",
  ABOUT_US: "/about_us",
  USER_PROFILE: "/user_profile",
  MY_ORDERS: "/my_orders",
  USER_ADDRESSES: "/my_addresses",
  CATERING: "/catering",

  BILL_CHECK: "/order_bill_check",
  BILL_FAILED: "/bill_failed",
  BILL_SUCCESS: "/bill_successful",
});
