import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import BorderButton from "../../../components/button/border-button.component";
import PaddedView from "../../../components/utils/padded-view.component";
import { AddressFormValues, AddressResource } from "../type/type";

interface Props {
  itemDetails: AddressResource;
  setSelectedAddress: React.Dispatch<React.SetStateAction<AddressResource | null>>;
  setShowAddressFormModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddressBox = ({
  itemDetails,
  setSelectedAddress,
  setShowAddressFormModal,
  setShowDeleteConfirmation,
}: Props) => {
  const theme = useTheme();
  const { setFieldValue, setErrors } = useFormikContext<AddressFormValues>();

  return (
    <Box sx={{ backgroundColor: "bg.primary", borderRadius: theme.shape.borderSizes[1] }}>
      <PaddedView multiples={3}>
        <Grid container spacing={1}>
          <Grid item container xs={12} justifyContent="space-between">
            <Grid item xs={8}>
              <Typography variant="h6" fontWeight="bold" textTransform="capitalize">
                {itemDetails.identifier}
              </Typography>
            </Grid>
            <Grid item container xs={4} justifyContent="flex-end" columnSpacing={1}>
              <Grid item>
                <BorderButton
                  error
                  disabled={!itemDetails.deletable}
                  onClick={() => {
                    setSelectedAddress(itemDetails);
                    setShowDeleteConfirmation(true);
                  }}
                >
                  Remove
                </BorderButton>
              </Grid>
              <Grid item>
                <BorderButton
                  onClick={() => {
                    setErrors({});
                    setFieldValue("id", itemDetails.id);
                    setFieldValue("identifier", itemDetails.identifier);
                    setFieldValue("remark", itemDetails.remark ? itemDetails.remark : "");
                    setFieldValue("pic", itemDetails.pic);
                    setFieldValue("pic_contact_no", itemDetails.pic_contact_no);
                    setFieldValue("unit_no", itemDetails.location.unit_no);
                    setFieldValue("location", itemDetails.location.address);
                    setFieldValue("location", itemDetails.location.address);
                    setFieldValue("location_lat", itemDetails.location.lat);
                    setFieldValue("location_long", itemDetails.location.long);
                    setFieldValue("postcode", itemDetails.location.postcode);
                    setFieldValue("city", itemDetails.location.city);
                    setFieldValue("state", itemDetails.location.state);
                    setSelectedAddress(itemDetails);
                    setShowAddressFormModal(true);
                  }}
                >
                  Edit
                </BorderButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container columnSpacing={1} xs={12}>
            <Grid item>
              <Typography
                fontWeight="bold"
                textTransform="capitalize"
                color={itemDetails.pic ? "text.primary" : "text.secondary"}
              >
                {itemDetails.pic ? itemDetails.pic : "No Person In Charge"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight="bold"> | </Typography>
            </Grid>
            <Grid item>
              <Typography
                fontWeight="bold"
                color={itemDetails.pic_contact_no ? "text.primary" : "text.secondary"}
              >
                {itemDetails.pic_contact_no ? itemDetails.pic_contact_no : "No Contact No."}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold" color="text.secondary">
              Remark: {itemDetails.remark}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{itemDetails.location.address}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">
              Delivery Fee:{" "}
              <span style={{ color: theme.palette.primary.main }}>
                RM {itemDetails.polygon.total_chargeable}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </PaddedView>
    </Box>
  );
};

export default AddressBox;
