import { Box, Grid, styled, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import YiJiaLogo from "../../assets/images/YiJia_logo";
import routes from "../../components/navigation/routes";
import SessionExpiredBox from "../../components/notification/session-expired-box.component";
import { logout, resetUser } from "../../services/auth/auth-slice.service";
import { getProfile, resetProfile } from "../../services/profile/profile-slice.service";
import { useAppDispatch } from "../../services/store";
import { getExpireAt, isLogin } from "../utils";
import MobileBottomNavBar from "./components/mobile-view-app-bar-components/mobile-bottom-nav-bar.component";
import MobileViewAppBar from "./components/mobile-view-app-bar-components/mobile-drawer-app-bar.component";
import PCViewAppBar from "./components/pc-view-app-bar-components/pc-view-appbar.component";
import Footer from "./footer.layout";

const AppBarContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  width: "100%",
  paddingLeft: 0,
  paddingRight: 0,
}));

const MaxWidthBox = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  backgroundColor: "primary",
  minHeight: "59vh",
}));

const OverflowBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "30px",
});

const ContentBox = styled(Box)(() => ({
  backgroundColor: "primary",
}));

const PCViewCenterBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
}));

function CustomizedAppBar() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showSessionExpired, setShowSessionExpired] = useState(false);

  const handleLogout = () => {
    setIsLoading(true);
    dispatch(logout({})).then(({ meta }) => {
      setIsLoading(false);
      dispatch(resetProfile());
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        navigate(routes.LOGIN);
      }
    });
  };

  const renderSessionExpired = () => {
    if (dayjs(getExpireAt()).isBefore(dayjs())) {
      setShowSessionExpired(true);
    }
  };

  useEffect(() => {
    if (isLogin()) {
      dispatch(getProfile())
        .unwrap()
        .catch((err) => {
          if (err.message.includes("Unauthenticated")) {
            setShowSessionExpired(true);
          }
        });
      renderSessionExpired();
    }
  }, []);

  const noPaddingRoutes =
    location.pathname === routes.HOME ||
    location.pathname === routes.ABOUT_US ||
    location.pathname === routes.EMAIL_VERIFICATION_CODE ||
    location.pathname === routes.FORGOT_PASSWORD ||
    location.pathname === routes.LANDING ||
    location.pathname === routes.LOGIN ||
    location.pathname === routes.SIGN_UP ||
    location.pathname === routes.CATERING;

  return (
    <>
      <SessionExpiredBox isShow={showSessionExpired} setIsShowAlert={setShowSessionExpired} />
      <AppBar position="sticky" sx={{ backgroundColor: "primary" }} elevation={0}>
        {isMobile ? (
          <Toolbar disableGutters={!isMobile}>
            <AppBarContainer>
              <Box sx={{ marginLeft: -1 }}>
                <YiJiaLogo white={true} />
              </Box>
              <MobileViewAppBar isLoading={isLoading} handleLogout={handleLogout} />
            </AppBarContainer>
          </Toolbar>
        ) : (
          <PCViewCenterBox>
            <PCViewAppBar handleLogout={handleLogout} />
          </PCViewCenterBox>
        )}
      </AppBar>
      {/* ====== Content Section ====== */}
      <ContentBox>
        <OverflowBox sx={{ overflowX: isMobile ? "hidden" : null }}>
          <MaxWidthBox
            sx={{
              padding: noPaddingRoutes ? null : isMobile ? "16px" : "10px",
              paddingTop: noPaddingRoutes ? null : "30px",
              maxWidth: noPaddingRoutes ? null : theme.dimensions.PCMaxWidth,
            }}
          >
            <Outlet />
          </MaxWidthBox>
        </OverflowBox>
        {([routes.HOME, routes.LANDING] as string[]).includes(location.pathname) && (
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "primary.main",
              padding: "15px",
            }}
          >
            <Typography
              variant={isMobile ? "h6" : "h5"}
              color="text.white"
              fontWeight="bold"
              textTransform="capitalize"
              textAlign="center"
            >
              {location.pathname === routes.HOME && "Free Delivery Within 3km From Yijia"}
            </Typography>
          </Grid>
        )}

        <Footer />
      </ContentBox>
      {isMobile && <MobileBottomNavBar />}
    </>
  );
}

export default CustomizedAppBar;
