import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Box, Grid, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import FormCheckBox from "../../../../components/forms/form-checkbox.component";
import FormPhoneTextField from "../../../../components/forms/form-phone-text-field.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormTextField from "../../../../components/forms/form-text-field.component";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import theme from "../../../../infrastructure/theme";
import LabelLink from "../link-with-label.component";

interface Props {
  isLoading: boolean;
}

function SignUpForm({ isLoading }: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.shape.borderSizes[2],
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: "primary.main",
            paddingTop: "1px",
            paddingBottom: "12px",
            borderTopLeftRadius: theme.shape.borderSizes[2],
            borderTopRightRadius: theme.shape.borderSizes[2],
          }}
        >
          <TopTitleBox title="Sign Up" requireCloseButton={false} isWhiteTitle />
        </Box>

        <PaddedView multiples={isMobile ? 3 : 4}>
          <FormTextField
            name="firstName"
            label="First Name"
            showIcon={true}
            icon={<PersonOutlineOutlinedIcon />}
          />
          <Spacer size="m" />
          <FormTextField
            name="lastName"
            label="Last Name"
            showIcon={true}
            icon={<PersonOutlineOutlinedIcon />}
          />
          <Spacer size="m" />
          <FormTextField name="email" label="Email" showIcon={true} icon={<MailOutlineIcon />} />
          <Spacer size="m" />
          <FormPhoneTextField label="Phone number" name="phoneNumber" />

          <Spacer size="m" />
          <FormTextField
            name="password"
            type="password"
            label="Password"
            showIcon={true}
            icon={<LockOutlinedIcon />}
          />
          <Spacer size="m" />
          <FormTextField
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            showIcon={true}
            icon={<LockOutlinedIcon />}
          />
          <Spacer size="m" />

          <Grid container sx={{ paddingTop: 1.5 }}>
            <FormCheckBox
              name="optForNewsletter"
              label="Subscribe to receive special offers, promotion and other information from Yi Jia. I understand I can unsubscribe at any time."
            />
          </Grid>

          <Spacer size="l" />

          <Grid item container justifyContent="center">
            <Grid item textAlign="center" width="60%">
              <FormSubmitButton isLoading={isLoading}>Sign Up</FormSubmitButton>
            </Grid>
          </Grid>
          <Spacer size="s" />
          <LabelLink label="Already have an account? " link="Log In" pushTo={routes.LOGIN} />
        </PaddedView>
      </Box>
    </Box>
  );
}

SignUpForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default SignUpForm;
