import { Box, useMediaQuery, useTheme } from "@mui/material";

import TopTitleBox from "../../../../components/utils/top-title-box.component";
import { ResetPasswordFormValues } from "../../type/type";
import VerificationForm from "./verification-code-new-password-form.component";

interface Props {
  userEmail: string;
  handleSubmit: (values: ResetPasswordFormValues) => void;
  handleSubmitResendCodes: () => void;
  isLoading: boolean;
}

function CreateNewPassword({ userEmail, handleSubmit, handleSubmitResendCodes, isLoading }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: theme.shape.borderSizes[1],
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
          paddingTop: "10px",
        }}
      >
        <TopTitleBox title="Create New Password" requireCloseButton={false} />
        <VerificationForm
          isLoading={isLoading}
          userEmail={userEmail}
          handleSubmit={handleSubmit}
          handleSubmitResendCodes={handleSubmitResendCodes}
        />
      </Box>
    </Box>
  );
}

export default CreateNewPassword;
