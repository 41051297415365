import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import FormAddressAutoComplete from "../../../components/forms/form-address-auto-complete.component";
import FormPhoneTextField from "../../../components/forms/form-phone-text-field.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTextField from "../../../components/forms/form-text-field.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import theme from "../../../infrastructure/theme";
import { addressSelector } from "../../../services/address/address-slice.services";
import { useAppSelector } from "../../../services/store";
import { AddressFormValues } from "../type/type";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
  height: "620px",
});

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  edit: boolean;
}

const AddressFormModal = ({ showModal, setShowModal, edit }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { createAddressObj, updateAddressObj } = useAppSelector(addressSelector);
  const { setErrors } = useFormikContext<AddressFormValues>();

  const handleCancel = () => {
    setErrors({});
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "350px" : "650px", paddingX: isMobile ? "10px" : "50px" }}>
        <Grid container display="flex" height="100%" alignContent="space-between">
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <TopTitleBox
                title={edit ? "Edit Address" : "New Address"}
                requireCloseButton={true}
                setShowModal={setShowModal}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField label="Address Name" name="identifier" />
            </Grid>
            <Grid item xs={12}>
              <FormTextField label="Person In Charge" name="pic" />
            </Grid>
            <Grid item xs={12}>
              <FormPhoneTextField label="Contact No" name="pic_contact_no" />
            </Grid>
            <Grid item xs={12}>
              <FormTextField label="Unit No" name="unit_no" />
            </Grid>
            <Grid item xs={12}>
              <FormAddressAutoComplete name="location" label="Address" />
            </Grid>
            <Grid item xs={12}>
              <FormSelectFieldText
                name="remark"
                label="Remark"
                selections={[
                  { type: "Call to pickup", value: "Call to pickup" },
                  { type: "Drop at tiang & ring bell", value: "Drop at tiang & ring bell" },
                  { type: "Drop at lobby & send photo", value: "Drop at lobby & send photo" },
                  { type: "Drop at tiang & send photo", value: "Drop at tiang & send photo" },
                  { type: "Pass to reception counter", value: "Pass to reception counter" },
                  {
                    type: "Drop at guard house & take photo",
                    value: "Drop at guard house & take photo",
                  },
                  { type: "Send to unit", value: "Send to unit" },
                  {
                    type: "Call when reaching in 5 minutes",
                    value: "Call when reaching in 5 minutes",
                  },
                  { type: "Exchange container", value: "Exchange container" },
                  {
                    type: "Open Gate & Put a chair inside",
                    value: "Open Gate & Put a chair inside",
                  },
                  {
                    type: "Put at shoerack and send photo",
                    value: "Put at shoerack and send photo",
                  },
                  { type: "Hang at gate and send photo", value: "Hang at gate and send photo" },
                  {
                    type: "Drop at lobby and call customer",
                    value: "Drop at lobby and call customer",
                  },
                  { type: "Send to unit & Ring Bell", value: "Send to unit & Ring Bell" },
                ]}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormSubmitButton
              isLoading={
                createAddressObj.status === "pending" || updateAddressObj.status === "pending"
              }
            >
              Submit
            </FormSubmitButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
};

export default AddressFormModal;
