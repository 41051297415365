import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import FriedNoodle from "../../../assets/images/fried-noodle-with-pork-soy-sauce-vegetable.png";
import CustomSkeleton from "../../../components/utils/custom-skeleton.component";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import { foodSetSelector, getFoodSets } from "../../../services/food-set/food-set-slice.services";
import { useAppDispatch, useAppSelector } from "../../../services/store";
import { FoodSetObjectType } from "../../order/preorder/type/type";
import PackageItem from "./package-item.component";

const MealPlanDetailsSection = () => {
  const { getFoodSetsObj } = useAppSelector(foodSetSelector);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(getFoodSets());
  }, []);

  return (
    <Grid item xs={12}>
      {getFoodSetsObj.status === "succeeded" ? (
        <ItemCarousel
          slidesToShow={isMobile ? 1 : isTablet ? 2 : 3}
          slidesToScroll={1}
          showArrowOnMobileView
        >
          {getFoodSetsObj.data.map((item: FoodSetObjectType) => (
            <PackageItem key={item.id} item={item} image={FriedNoodle} />
          ))}
        </ItemCarousel>
      ) : (
        <CustomSkeleton height={isMobile ? 372 : 582} />
      )}
    </Grid>
  );
};

export default MealPlanDetailsSection;
