import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useLocation } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import PickUpAddressSection from "../../components/pickup-address-section.component";
import { PreorderItemFormValues } from "../type/type";
import AddressAutoComplete from "./address-auto-complete.component";

const SelectAddressSection = () => {
  const { values } = useFormikContext<PreorderItemFormValues>();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const editable =
    location.pathname === routes.PREORDER_ITEM_EDIT
      ? (values as PreorderItemFormValues).currentMealEditable
      : true;

  return (
    <>
      {values.type === "delivery" ? (
        <Grid container display="flex" alignItems="center" justifyContent="center">
          <Grid item xs={isMobile ? 12 : 8}>
            <Typography fontWeight="bold" color="text.secondary">
              Delivery Address:
            </Typography>
            <AddressAutoComplete name="address_id" disabled={!editable} />
          </Grid>
        </Grid>
      ) : (
        <PickUpAddressSection />
      )}
    </>
  );
};

export default SelectAddressSection;
