import { Grid, useMediaQuery } from "@mui/material";
import * as React from "react";
import BackgroundButton from "../../../components/button/background-button.component";
import theme from "../../../infrastructure/theme";

interface Props {
  image1: string;
  image2: string;
  content: React.ReactNode;
  buttonText: string;
  onClick: () => void;
}

const OrderCateringBox = ({ image1, image2, content, buttonText, onClick }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      rowSpacing={4}
      sx={{
        padding: "10px",
      }}
    >
      <Grid
        item
        width="100%"
        height="35vh"
        sx={{
          position: "relative",
          backgroundColor: theme.palette.secondary.main,
          borderRadius: "100%",
        }}
      >
        <img
          src={image1}
          alt={image1}
          width={isMobile ? "200px" : "300px"}
          style={{ position: "absolute", left: 0, top: 0 }}
        />
        <img
          src={image2}
          alt={image2}
          width={isMobile ? "200px" : "300px"}
          style={{ position: "absolute", right: 0, bottom: 0 }}
        />
      </Grid>
      <Grid item textAlign="center">
        {content}
      </Grid>
      <Grid item>
        <BackgroundButton onClick={onClick}>{buttonText}</BackgroundButton>
      </Grid>
    </Grid>
  );
};

export default OrderCateringBox;
