import FactCheckIcon from "@mui/icons-material/FactCheck";
import PersonIcon from "@mui/icons-material/Person";
import { Grid, styled, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import routes from "../navigation/routes";
import HorizontalDivider from "../utils/horizontal-divider.component";
import Spacer from "../utils/spacer.component";

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
}));

function SideMenuItem() {
  const location = useLocation();

  return (
    <Grid container spacing={1} padding="20px">
      <Grid item xs={12}>
        <Grid container item alignItems="center">
          <PersonIcon
            sx={{
              color: ([routes.USER_ADDRESSES, routes.USER_PROFILE] as string[]).includes(
                location.pathname,
              )
                ? "primary.main"
                : "#000",
            }}
          />
          <Spacer position="left" size="s" />
          <Typography
            variant="h6"
            color={
              ([routes.USER_ADDRESSES, routes.USER_PROFILE] as string[]).includes(location.pathname)
                ? "primary"
                : "text.primary"
            }
          >
            My Account
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StyledLink to={routes.USER_PROFILE}>
          <Grid container item alignItems="center">
            <Spacer position="left" size="xl" />
            <Typography
              variant="h6"
              color={location.pathname === routes.USER_PROFILE ? "primary" : "text.primary"}
            >
              Profile
            </Typography>
          </Grid>
        </StyledLink>
      </Grid>
      <Grid item xs={12}>
        <StyledLink to={routes.USER_ADDRESSES}>
          <Grid container item alignItems="center">
            <Spacer position="left" size="xl" />
            <Typography
              variant="h6"
              color={location.pathname === routes.USER_ADDRESSES ? "primary" : "text.primary"}
            >
              Addresses
            </Typography>
          </Grid>
        </StyledLink>
      </Grid>

      <Grid item xs={12}>
        <HorizontalDivider spacerSize="s" />
      </Grid>
      <Grid item xs={12}>
        <StyledLink to={routes.MY_ORDERS}>
          <Grid container item alignItems="center">
            <FactCheckIcon
              sx={{ color: location.pathname === routes.MY_ORDERS ? "primary.main" : "#000" }}
            />
            <Spacer position="left" size="s" />
            <Typography
              variant="h6"
              color={location.pathname === routes.MY_ORDERS ? "primary" : "text.primary"}
            >
              My Orders
            </Typography>
          </Grid>
        </StyledLink>
      </Grid>
    </Grid>
  );
}

export default SideMenuItem;
