import { Grid, List, ListItem, styled, Typography } from "@mui/material";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../components/utils/padded-view.component";
import theme from "../../../infrastructure/theme";

const AnswerText = styled(Typography)(() => ({
  fontSize: "18px",
  fontWeight: "bold",
}));

const QuestionListItem = styled(ListItem)({
  display: "list-item",
  fontSize: "18px",
  fontWeight: "bold",
  color: theme.palette.primary.dark,
});

const AnswerListItem = styled(ListItem)({
  fontSize: "18px",
  fontWeight: "bold",
});

function FAQScreen() {
  return (
    <PaddedView multiples={2}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h4" fontWeight="bold" color="primary.main">
            FAQ
          </Typography>
        </Grid>

        <HorizontalDivider spacerSize="l" />
        <List sx={{ listStyleType: "number", pl: 5 }}>
          <Grid item container spacing={4}>
            <Grid item xs={12}>
              <QuestionListItem>
                How do I ensure my food is reserved on the delivery day?
              </QuestionListItem>
              <AnswerListItem>
                To secure your portion, please place all orders in advance. This allows us to
                prepare and ensure your food is ready for delivery.
              </AnswerListItem>
            </Grid>

            <Grid item xs={12}>
              <QuestionListItem>
                Can I change my delivery address or cancel my order?
              </QuestionListItem>
              <AnswerListItem>
                Yes, you can. However, all changes or cancellations must be made before 9:30 AM on
                the delivery date.
              </AnswerListItem>
            </Grid>

            <Grid item xs={12}>
              <QuestionListItem>
                What if I need to urgently cancel my order after the deadline?
              </QuestionListItem>
              <AnswerListItem>
                <AnswerText>
                  For urgent cancellations, please contact us directly on WhatsApp at{" "}
                  <a href="https://wa.me/60199867798" target="_blank" rel="noopener noreferrer">
                    +60199867798
                  </a>
                  .
                </AnswerText>
              </AnswerListItem>
            </Grid>

            <Grid item xs={12}>
              <QuestionListItem>How is the delivery fee determined?</QuestionListItem>
              <AnswerListItem>
                Delivery fees are calculated based on your distance and zone. Please check our
                website to find out the delivery fee for your address.
              </AnswerListItem>
            </Grid>

            <Grid item xs={12}>
              <QuestionListItem>When are the delivery times?</QuestionListItem>
              <AnswerListItem>
                Our delivery times are as follows: Lunch from 11 AM to 1 PM and Dinner from 5 PM to
                7 PM.
              </AnswerListItem>
            </Grid>

            <Grid item xs={12}>
              <QuestionListItem>When will my order be dispatched?</QuestionListItem>
              <AnswerListItem>
                Orders are dispatched once payment is received and all order details, including the
                correct address and contact number, are confirmed.
              </AnswerListItem>
            </Grid>

            <Grid item xs={12}>
              <QuestionListItem>
                Can I cancel my order after it has been dispatched?
              </QuestionListItem>
              <AnswerListItem>
                No, once an order is dispatched, we cannot accept cancellations.
              </AnswerListItem>
            </Grid>
            <Grid item xs={12}>
              <QuestionListItem>What happens if my delivery is late?</QuestionListItem>
              <AnswerListItem>
                We strive to deliver within the selected timeframe, but delays may occur due to
                traffic or weather conditions.
              </AnswerListItem>
            </Grid>
            <Grid item xs={12}>
              <QuestionListItem>How can I track my delivery?</QuestionListItem>
              <AnswerListItem>
                <AnswerText>
                  To track your delivery, please contact us on WhatsApp at{" "}
                  <a href="https://wa.me/60199867798" target="_blank" rel="noopener noreferrer">
                    +60199867798
                  </a>
                  .
                </AnswerText>
              </AnswerListItem>
            </Grid>
            <Grid item xs={12}>
              <QuestionListItem>What if I don’t receive my invoice/receipt?</QuestionListItem>
              <AnswerListItem>
                <AnswerText>
                  If you haven&apos;t received it in your registered email, contact us at{" "}
                  <a href="mailto:yijiacanteen@gmail.com" target="_top" rel="noopener noreferrer">
                    yijiacanteen@gmail.com
                  </a>{" "}
                  or WhatsApp us at{" "}
                  <a href="https://wa.me/60199867798" target="_blank" rel="noopener noreferrer">
                    +60199867798
                  </a>{" "}
                  with the title “Request for Invoice/Receipt.”
                </AnswerText>
              </AnswerListItem>
            </Grid>
          </Grid>
        </List>

        <HorizontalDivider spacerSize="l" />
      </Grid>
    </PaddedView>
  );
}

export default FAQScreen;
