import { Avatar, Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { profileSelector } from "../../services/profile/profile-slice.service";
import { useAppSelector } from "../../services/store";
import routes from "../navigation/routes";
import CustomSkeleton from "../utils/custom-skeleton.component";
import Spacer from "../utils/spacer.component";

function SideMenuAvatar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { profileObj } = useAppSelector(profileSelector);

  const pushToVerifyCode = () => {
    navigate(routes.EMAIL_VERIFICATION_CODE, {
      state: { email: profileObj.data?.email, resendCode: true },
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: "20px",
        paddingX: "10px",
        height: "100%",
        borderTopLeftRadius: theme.shape.borderSizes[1],
        borderTopRightRadius: theme.shape.borderSizes[1],
        backgroundColor: isMobile ? undefined : "bg.primary",
      }}
    >
      <Grid item sx={{ width: "35%" }}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Avatar
            sx={{
              width: 75,
              height: 75,
            }}
          />
        </Box>
      </Grid>
      <Grid item sx={{ width: "65%" }}>
        {profileObj.status === "succeeded" ? (
          <>
            <Typography variant="h5">Hi, {profileObj.data?.firstName}</Typography>
            {profileObj.data?.emailVerified ? (
              <Typography color="text.secondary"> &#10004; Account Verified</Typography>
            ) : (
              <Button
                variant="text"
                onClick={pushToVerifyCode}
                disabled={false}
                sx={{ textTransform: "none", padding: "0px" }}
              >
                <Typography fontWeight="bold" variant="body2">
                  Verify Your Account {">"}
                </Typography>
              </Button>
            )}
          </>
        ) : (
          <>
            <CustomSkeleton />
            <Spacer />
            <CustomSkeleton />
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default SideMenuAvatar;
