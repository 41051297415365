import StarsIcon from "@mui/icons-material/Stars";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import CustomSkeleton from "../../../components/utils/custom-skeleton.component";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getHighlightDishes,
  highlightDishSelector,
} from "../../../services/highlight-dish/highlight-dish-slice.services";
import { useAppDispatch, useAppSelector } from "../../../services/store";

interface HighlightFood {
  id: number;
  image_path: string;
  identifier: string;
  type: string;
  status: string;
  price: string;
  createdAt: string;
  deletable: boolean;
}

const HighlightDishSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const { getHighlightDishesObj } = useAppSelector(highlightDishSelector);

  useEffect(() => {
    dispatch(getHighlightDishes());
  }, []);

  const renderItems = () =>
    getHighlightDishesObj.data.map((dish: HighlightFood) => (
      <Grid item key={dish.id} xs={12} sm={4}>
        <Box
          sx={{
            backgroundColor: "#FF8746",
            padding: "10px",
            height: "100%",
            borderRadius: theme.shape.borderSizes[1],
          }}
        >
          <Box>
            <img
              style={{
                width: "100%",
                height: "250px",
                objectFit: "cover",
                borderRadius: theme.shape.borderSizes[1],
              }}
              src={
                dish.image_path
                  ? dish.image_path
                  : "https://yijiacanteen-production.sgp1.cdn.digitaloceanspaces.com/default/yijia-logo.jpeg"
              }
            />
          </Box>
          <Spacer />
          <Grid
            container
            sx={{
              justifyContent: "center",
            }}
          >
            <Typography
              variant={isMobile ? "body1" : "h6"}
              sx={{ height: isMobile ? "48px" : undefined }}
              fontWeight="bold"
              color="text.white"
            >
              {dish.identifier}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    ));

  return (
    <Grid container>
      <Grid item xs={12}>
        <PaddedView multiples={isMobile ? 0 : 2}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              sx={{
                backgroundColor: "#fff",
                border: "2px solid",
                borderRadius: theme.shape.borderSizes[1],
                borderColor: "primary.dark",
                boxShadow: "3px 5px" + theme.palette.primary.main,
                padding: "15px",
              }}
            >
              <Grid item container alignItems="center">
                <Typography
                  variant={isTablet ? "body1" : "h5"}
                  fontWeight="bold"
                  textTransform="capitalize"
                  textAlign="center"
                >
                  Highlight Dishes Of The Week
                </Typography>
                <Spacer position="left" />
                <StarsIcon color="primary" fontSize={isTablet ? "small" : "large"} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Spacer size="xl" />
            </Grid>

            {isMobile ? (
              <Grid item xs={12}>
                <ItemCarousel
                  slidesToShow={isTablet ? (isMobile ? 1.2 : 2) : 3}
                  slidesToScroll={isMobile || isTablet ? 1 : 2}
                >
                  {getHighlightDishesObj.status === "succeeded" ? (
                    renderItems()
                  ) : (
                    <>
                      <Grid item xs={12} sm={4}>
                        <CustomSkeleton height={348} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CustomSkeleton height={348} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CustomSkeleton height={348} />
                      </Grid>
                    </>
                  )}
                </ItemCarousel>
              </Grid>
            ) : (
              <Grid item container spacing={2}>
                {getHighlightDishesObj.status === "succeeded" ? (
                  renderItems()
                ) : (
                  <>
                    <Grid item xs={12} sm={4}>
                      <CustomSkeleton height={348} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomSkeleton height={348} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomSkeleton height={348} />
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </PaddedView>
      </Grid>
    </Grid>
  );
};

export default HighlightDishSection;
