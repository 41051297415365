import React from "react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "./YiJiaLogo.png";
import logoWhite from "./YiJiaLogoWhite.png";
import routes from "../../components/navigation/routes";
import { isLogin } from "../../infrastructure/utils";

interface Props {
  white?: boolean;
}

const YiJiaLogo = ({ white }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "transparent",
            boxShadow: "none",
          },
        },
      }}
    >
      <Link to={isLogin() ? routes.HOME : routes.LANDING}>
        <Button disableRipple>
          <img width={isMobile ? 75 : 120} src={white ? logoWhite : logo} alt="logo" />
        </Button>
      </Link>
    </Box>
  );
};

export default YiJiaLogo;
