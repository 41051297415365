import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import SideMenuAvatar from "../../../components/profile-side-menu/side-menu-avatar.component";
import SideMenu from "../../../components/profile-side-menu/side-menu.component";
import { getProfile } from "../../../services/profile/profile-slice.service";
import { useAppDispatch } from "../../../services/store";
import UserDetails from "../components/user-details.layout";

const UserProfileScreen = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <Grid container display="flex">
      {!isMobile && (
        <Grid item xs={3.5}>
          <SideMenu />
        </Grid>
      )}
      <Grid item container xs={isMobile ? 12 : 8.5} spacing={2} display="flex">
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            My Profile
          </Typography>
        </Grid>
        {isMobile && (
          <Grid item xs={12}>
            <SideMenuAvatar />
          </Grid>
        )}
        <Grid item xs={12}>
          <UserDetails />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserProfileScreen;
