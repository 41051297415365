import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import RestrictedRoute from "./restricted.route";

import CustomizedAppBar from "../../infrastructure/layout/appbar.layout";
import PrivateRoute from "./private.route";
import routes from "./routes";
import HomeScreen from "../../features/dashboard/screens/home.screen";
import SameDayOrderScreen from "../../features/order/same-day/screens/same-day-order.screen";
import SameDayOrderSummaryScreen from "../../features/order/same-day/screens/same-day-order-summary.screen";
import BillCheckScreen from "../../features/payment/screens/bill-check.screen";
import BillFailedScreen from "../../features/payment/screens/bill-failed.screen";
import BillSuccessfulScreen from "../../features/payment/screens/bill-successful.screen";
import PreorderMealScreen from "../../features/order/preorder/screens/preorder-meal.screen";
import PreorderItemScreen from "../../features/order/preorder/screens/preorder-item.screen";
import PreorderMealSummaryScreen from "../../features/order/preorder/screens/preorder-meal-summary.screen";
import AboutUsScreen from "../../features/about-us/screens/about-us.screen";
import LandingScreen from "../../features/landing/screens/landing.screen";
import MyOrderScreen from "../../features/purchases/screens/my-order.screen";
import UserProfileScreen from "../../features/profile/screens/user-profile.screen";
import UserAddressesScreen from "../../features/address/screens/user-addresses.screen";
import LoginScreen from "../../features/auth/screens/login.screen";
import SignUpScreen from "../../features/auth/screens/sign-up.screen";
import PublicRoute from "./public.route";
import CateringScreen from "../../features/catering/screens/catering.screen";
import RegisterVerificationCodeScreen from "../../features/auth/screens/register-verification-code.screen";
import ForgotPassword from "../../features/auth/screens/forgot-password.screen";
import DeliveryPolicyScreen from "../../features/delivery-policy/screens/delivery-policy.screen";
import FAQScreen from "../../features/faq/screens/faq.screen";
import PrivacyPolicyScreen from "../../features/privacy-policy/screens/privacy-policy.screen";
import TermsScreen from "../../features/terms/screens/terms.screen";

const index = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<CustomizedAppBar />}>
          <Route element={<PrivateRoute />}>
            <Route
              path={routes.EMAIL_VERIFICATION_CODE}
              element={<RegisterVerificationCodeScreen />}
            />

            <Route path={routes.HOME} element={<HomeScreen />} />
            <Route path={routes.SAME_DAY_ORDER} element={<SameDayOrderScreen />} />
            <Route path={routes.SAME_DAY_ORDER_SUMMARY} element={<SameDayOrderSummaryScreen />} />
            <Route path={routes.SAME_DAY_ORDER_EDIT} element={<SameDayOrderScreen />} />
            <Route
              path={routes.SAME_DAY_ORDER_EDIT_SUMMARY}
              element={<SameDayOrderSummaryScreen />}
            />
            <Route path={routes.BILL_CHECK} element={<BillCheckScreen />} />
            <Route path={routes.BILL_FAILED} element={<BillFailedScreen />} />
            <Route path={routes.BILL_SUCCESS} element={<BillSuccessfulScreen />} />

            <Route path={routes.PREORDER_MEAL_PLAN} element={<PreorderMealScreen />} />
            <Route path={routes.PREORDER_ITEM} element={<PreorderItemScreen />} />
            <Route path={routes.PREORDER_MEAL_SUMMARY} element={<PreorderMealSummaryScreen />} />
            <Route path={routes.PREORDER_ITEM_EDIT} element={<PreorderItemScreen />} />
            <Route
              path={routes.PREORDER_MEAL_EDIT_SUMMARY}
              element={<PreorderMealSummaryScreen />}
            />

            <Route path={routes.USER_PROFILE} element={<UserProfileScreen />} />
            <Route path={routes.MY_ORDERS} element={<MyOrderScreen />} />
            <Route path={routes.USER_ADDRESSES} element={<UserAddressesScreen />} />
          </Route>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<LandingScreen />} />
            <Route path={routes.ABOUT_US} element={<AboutUsScreen />} />
            <Route path={routes.CATERING} element={<CateringScreen />} />
            <Route path={routes.DELIVERY_POLICY} element={<DeliveryPolicyScreen />} />
            <Route path={routes.PRIVACY_POLICY} element={<PrivacyPolicyScreen />} />
            <Route path={routes.FAQ} element={<FAQScreen />} />
            <Route path={routes.TERMS} element={<TermsScreen />} />
          </Route>

          <Route element={<RestrictedRoute />}>
            <Route path={routes.LOGIN} element={<LoginScreen />} />
            <Route path={routes.SIGN_UP} element={<SignUpScreen />} />
            <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default index;
