import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddressResource } from "../../features/address/type/type";
import { getAPI } from "../../infrastructure/utils";
import { delReq, getReq, postReq, putReq } from "../api.services";
import { idleObject, PaginatedCollection } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getAddressList = createAsyncThunk("address/index", async (payload: any) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/addresses?${
      payload.page ? "page=".concat(payload.page) : "page=1"
    }`,
  );
  return response;
});

export const getAddress = createAsyncThunk("address/{id}", async (payload: any) => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/addresses/${payload}`);
  return response;
});

export const checkDeliveryRate = createAsyncThunk(
  "address/check_delivery_rates",
  async (payload: any) => {
    const response = await postReq(`${getAPI()}/addresses/check_delivery_rates`, payload);
    return response;
  },
);

export const createAddress = createAsyncThunk("address/create", async (payload: any) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/addresses`, payload);
  return response;
});

export const updateAddress = createAsyncThunk("address/{id}/update", async (payload: any) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/addresses/${payload.id}`,
    payload,
  );
  return response;
});

export const deleteAddress = createAsyncThunk("address/{id}/delete", async (payload: any) => {
  const response = await delReq(`${process.env.REACT_APP_API_PRIVATE_V1}/addresses/${payload}`);
  return response;
});

interface initialStateType {
  getAddressListObj: RequestObject<PaginatedCollection<AddressResource>>;
  getAddressObj: RequestObject;
  checkDeliveryRateObj: RequestObject;
  createAddressObj: RequestObject;
  updateAddressObj: RequestObject;
  deleteAddressObj: RequestObject;
}

const initialState: initialStateType = {
  getAddressListObj: idleObject(),
  getAddressObj: idleObject(),
  checkDeliveryRateObj: idleObject(),
  createAddressObj: idleObject(),
  updateAddressObj: idleObject(),
  deleteAddressObj: idleObject(),
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddressList.pending, (state) => {
        state.getAddressListObj.status = "pending";
      })
      .addCase(getAddressList.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getAddressListObj.status = "succeeded";
        state.getAddressListObj.data = data;
        state.getAddressListObj.successMessage = message;
      })
      .addCase(getAddressList.rejected, (state, action) => {
        const { message } = action.error;

        state.getAddressListObj.status = "failed";
        state.getAddressListObj.errorMessage = message;
      })
      .addCase(getAddress.pending, (state) => {
        state.getAddressObj.status = "pending";
      })
      .addCase(getAddress.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getAddressObj.status = "succeeded";
        state.getAddressObj.data = data;
        state.getAddressObj.successMessage = message;
      })
      .addCase(getAddress.rejected, (state, action) => {
        const { message } = action.error;

        state.getAddressObj.status = "failed";
        state.getAddressObj.errorMessage = message;
      })
      .addCase(checkDeliveryRate.pending, (state) => {
        state.checkDeliveryRateObj.status = "pending";
      })
      .addCase(checkDeliveryRate.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.checkDeliveryRateObj.status = "succeeded";
        state.checkDeliveryRateObj.data = data;
        state.checkDeliveryRateObj.successMessage = message;
      })
      .addCase(checkDeliveryRate.rejected, (state, action) => {
        const { message } = action.error;

        state.checkDeliveryRateObj.status = "failed";
        state.checkDeliveryRateObj.errorMessage = message;
      })
      .addCase(createAddress.pending, (state) => {
        state.createAddressObj.status = "pending";
      })
      .addCase(createAddress.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createAddressObj.status = "succeeded";
        state.createAddressObj.data = data;
        state.createAddressObj.successMessage = message;
      })
      .addCase(createAddress.rejected, (state, action) => {
        const { message } = action.error;

        state.createAddressObj.status = "failed";
        state.createAddressObj.errorMessage = message;
      })
      .addCase(updateAddress.pending, (state) => {
        state.updateAddressObj.status = "pending";
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateAddressObj.status = "succeeded";
        state.updateAddressObj.data = data;
        state.updateAddressObj.successMessage = message;
      })
      .addCase(updateAddress.rejected, (state, action) => {
        const { message } = action.error;

        state.updateAddressObj.status = "failed";
        state.updateAddressObj.errorMessage = message;
      })
      .addCase(deleteAddress.pending, (state) => {
        state.deleteAddressObj.status = "pending";
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.deleteAddressObj.status = "succeeded";
        state.deleteAddressObj.data = data;
        state.deleteAddressObj.successMessage = message;
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        const { message } = action.error;

        state.deleteAddressObj.status = "failed";
        state.deleteAddressObj.errorMessage = message;
      });
  },
});

export default addressSlice.reducer;

// state
export const addressSelector = (state: RootState) => state.address;
