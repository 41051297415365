import { Grid, Typography, useMediaQuery } from "@mui/material";
import * as Yup from "yup";
import FormPhoneTextField from "../../../components/forms/form-phone-text-field.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTextFieldMultiline from "../../../components/forms/form-text-field-multiline.component";
import FormTextField from "../../../components/forms/form-text-field.component";
import Form from "../../../components/forms/form.component";
import PaddedView from "../../../components/utils/padded-view.component";
import theme from "../../../infrastructure/theme";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  contactNo: Yup.number().required().label("Conact No."),
  email: Yup.string().required().email().label("Email"),
  message: Yup.string().required().label("Message"),
});

interface Props {
  handleSubmit: () => void;
  isLoading: boolean;
}

const ContactUs = ({ handleSubmit, isLoading }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      item
      container
      flexDirection="column"
      sx={{
        backgroundColor: "secondary.main",
        boxShadow: "2px 2px 10px grey",
        borderRadius: theme.shape.borderSizes[1],
        padding: isMobile ? "10px" : "20px",
        zIndex: 99,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight="bold" color="primary.main">
          Contact us
        </Typography>
      </Grid>
      <Form
        initialValues={{
          firstName: "",
          lastName: "",
          contactNo: "",
          email: "",
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid container spacing={2} display="flex">
            <Grid item xs={isMobile ? 12 : isMobile ? 12 : 6}>
              <FormTextField label="First Name" name="firstName" isTransparent={false} />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormTextField label="Last Name" name="lastName" isTransparent={false} />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormPhoneTextField label="Contact No." name="contactNo" isTransparent={false} />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormTextField label="Email" name="email" isTransparent={false} />
            </Grid>
            <Grid item xs={12}>
              <FormTextFieldMultiline
                label="Message"
                name="message"
                minRows={4}
                isTransparent={false}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3} marginLeft="auto">
              <FormSubmitButton isLoading={isLoading}>
                <Typography>SEND</Typography>
              </FormSubmitButton>
            </Grid>
          </Grid>
        </PaddedView>
      </Form>
    </Grid>
  );
};

export default ContactUs;
