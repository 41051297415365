import { Box, useMediaQuery, useTheme } from "@mui/material";
import CustomSkeleton from "../../../components/utils/custom-skeleton.component";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import Spacer from "../../../components/utils/spacer.component";

const FoodItemCarouselLoader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const foodLoader = () => (
    <Box>
      <CustomSkeleton width={250} />
      <Spacer />
      <CustomSkeleton height={250} />
    </Box>
  );

  return (
    <ItemCarousel
      slidesToShow={isIpad ? (isMobile ? 1 : 2) : 3}
      slidesToScroll={isMobile || isIpad ? 1 : 2}
    >
      {foodLoader()}
      {foodLoader()}
      {foodLoader()}
    </ItemCarousel>
  );
};

export default FoodItemCarouselLoader;
