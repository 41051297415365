import { Box, Grid, styled, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Laptop from "../../../assets/images/laptop.png";
import BackgroundButton from "../../../components/button/background-button.component";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";
import theme from "../../../infrastructure/theme";
import HighlightDishSection from "../../dashboard/components/highlight-dish-section.component";
import BannerSection from "../components/banner-section.component";
import CheckDeliveryRateSection from "../components/check-delivery-rate-section.component";
import ExploreOrderCateringSection from "../components/explore-order-catering-section.component";
import MealPlanDetailsSection from "../components/meal-plan-details-section.component";
import OurValuesSection from "../components/our-values-section.component";
import PopularDishSection from "../components/popular-dish-section.component";

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const NumberBox = styled(Box)(({ theme }) => ({
  height: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  aspectRatio: "1/1",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "100%",
  textAlign: "center",
}));

const LandingScreen = () => {
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash === "#menu-of-the-week") {
      document.getElementById("menu-of-the-week")?.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <MainContainer>
      <BannerSection />

      <Box
        sx={{
          maxWidth: isTablet ? "100%" : theme.dimensions.PCMaxWidth,
          padding: "16px",
        }}
      >
        <Grid
          container
          sx={{
            marginTop: "50px",
            padding: "40px",
            backgroundColor: "secondary.main",
            borderRadius: theme.shape.borderSizes[1],
          }}
        >
          <Grid item>
            <Typography textAlign="justify">
              At Yi Jia, we believe that great food brings people together. Our passion for culinary
              excellence drives everything we do, from crafting delicious meals to providing
              exceptional service. With a focus on creating memorable dining experiences, we invite
              you to embark on a journey of flavor and enjoyment with us. Whether you’re hosting a
              special event, planning a family gathering, or simply looking for a convenient and
              delicious meal solution, we’re here to exceed your expectations and leave you craving
              more. Welcome to Yi Jia, where every bite tells a story of quality, care, and culinary
              expertise.
            </Typography>
          </Grid>
        </Grid>

        <Grid item paddingTop="50px" justifyContent="center">
          <Grid item xs={12} paddingY="50px">
            <Typography
              variant="h4"
              fontWeight="bold"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Our Packages
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MealPlanDetailsSection />
          </Grid>
        </Grid>

        <OurValuesSection />

        <section id="menu-of-the-week">
          <Grid item xs={12} marginTop="50px">
            <HighlightDishSection />
          </Grid>
        </section>

        <Grid item xs={12} marginTop="20px">
          <CheckDeliveryRateSection />
        </Grid>

        <PopularDishSection />

        {/* <HassleFreeSection /> */}

        <Grid container paddingTop="50px" paddingX="20px" display="flex" justifyContent="center">
          <Grid item xs={12} paddingY="50px">
            <Typography
              variant="h4"
              fontWeight="bold"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              How It Works?
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          padding: isTablet ? "20px" : "80px",
          width: "100%",
          backgroundColor: theme.palette.secondary.main,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={isTablet ? 12 : 6}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <NumberBox>
              <Typography variant="h4" fontWeight="bold" color="common.white">
                1
              </Typography>
            </NumberBox>
            <Spacer position="left" size="l" />
            <Typography variant={isTablet ? "body1" : "h6"} textAlign="justify">
              Click on the “ORDER NOW” button to be redirected to our login page. Sign up if you do
              not have an account yet.
            </Typography>
          </Box>
          <Spacer size="l" />
          <Box display="flex" flexDirection="row" alignItems="center">
            <NumberBox>
              <Typography variant="h4" fontWeight="bold" color="common.white">
                2
              </Typography>
            </NumberBox>
            <Spacer position="left" size="l" />
            <Typography variant={isTablet ? "body1" : "h6"} textAlign="justify">
              Select your desired meal plan or you’d like to order for the day itself. You may
              customise your meal plan based on the portion size, days of delivery, and food
              selections.
            </Typography>
          </Box>
          <Spacer size="l" />

          <Box display="flex" flexDirection="row" alignItems="center">
            <NumberBox>
              <Typography variant="h4" fontWeight="bold" color="common.white">
                3
              </Typography>
            </NumberBox>
            <Spacer position="left" size="l" />
            <Typography variant={isTablet ? "body1" : "h6"} textAlign="justify">
              Select your delivery details and complete the payment process to secure your order.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={isTablet ? 12 : 6} justifyContent="center" alignItems="center">
          <Box display="flex" justifyContent="center">
            <img src={Laptop} alt={Laptop} width="70%" />
          </Box>
          <Spacer />
          <Box width="100%" display="flex" justifyContent="center">
            <Box width="150px">
              <BackgroundButton
                onClick={() => {
                  navigate(routes.HOME);
                }}
              >
                Order Now
              </BackgroundButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          maxWidth: isTablet ? "100%" : theme.dimensions.PCMaxWidth,
          padding: "16px",
        }}
      >
        <ExploreOrderCateringSection />
      </Box>
    </MainContainer>
  );
};

export default LandingScreen;
