import { Grid, List, ListItem, styled, Typography } from "@mui/material";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";

const SectionText = styled(Typography)(() => ({
  fontSize: "18px",
  fontWeight: "bold",
}));

const StyledListItem = styled(ListItem)({
  display: "list-item",
});

function DeliveryPolicyScreen() {
  return (
    <PaddedView multiples={2}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h4" fontWeight="bold" color="primary.main">
            Delivery Policy
          </Typography>
        </Grid>

        <HorizontalDivider spacerSize="l" />
        <Grid item xs={12}>
          <SectionText>
            1. Order Placement: To guarantee your meal, please order in advance.
          </SectionText>
        </Grid>

        <Grid item xs={12}>
          <SectionText>
            2. Changes & Cancellations: Must be done before 9:30 AM on the delivery day.
          </SectionText>
        </Grid>

        <Grid item xs={12}>
          <SectionText>
            3. Urgent Cancellations: Contact us on WhatsApp at{" "}
            <a href="https://wa.me/60199867798" target="_blank" rel="noopener noreferrer">
              +60199867798
            </a>
            .
          </SectionText>
        </Grid>

        <Grid item xs={12}>
          <SectionText>
            4. Delivery Fee: Calculated based on distance and zoning, available on our website.
          </SectionText>
        </Grid>

        <Grid item xs={12}>
          <SectionText>5. Delivery Times: Lunch (11 AM - 1 PM), Dinner (5 PM - 7 PM).</SectionText>
        </Grid>
        <Grid item xs={12}>
          <SectionText>
            6. Order Dispatch: After payment is received and details verified.
          </SectionText>
        </Grid>
        <Grid item xs={12}>
          <SectionText>
            7. No Cancellations After Dispatch: Orders cannot be cancelled once dispatched.
          </SectionText>
        </Grid>
        <Grid item xs={12}>
          <SectionText>
            8. Delivery Within Timeframe: Deliveries aim to be on time; however, delays may occur.
          </SectionText>
        </Grid>
        <Grid item xs={12}>
          <SectionText>9. Tracking: Contact us on WhatsApp to track your delivery.</SectionText>
        </Grid>
        <Grid item xs={12}>
          <SectionText>
            10. Invoice/Receipt: Sent to your email post-payment. Contact us if not received.
          </SectionText>
        </Grid>

        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>Payment Method</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <List sx={{ listStyleType: "disc", pl: 5 }}>
              <StyledListItem>
                Payments are processed via Revenue Monster, a secure platform under Malaysian law.
              </StyledListItem>
              <StyledListItem>
                Payment methods may vary, and we reserve the right to add or remove options.
              </StyledListItem>
              <StyledListItem>
                Online payments may be handled by external providers, with card data stored for
                future orders upon customer consent.
              </StyledListItem>
            </List>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <SectionText>
              For any further questions, feel free to contact us at{" "}
              <a href="mailto:yijiacanteen@gmail.com" target="_top" rel="noopener noreferrer">
                yijiacanteen@gmail.com
              </a>{" "}
              or WhatsApp{" "}
              <a href="https://wa.me/60199867798" target="_blank" rel="noopener noreferrer">
                +60199867798
              </a>
              .
            </SectionText>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
      </Grid>
    </PaddedView>
  );
}

export default DeliveryPolicyScreen;
