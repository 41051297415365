import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileResource } from "../../features/profile/type/type";
import { getReq, putReq, patchReq } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getProfile = createAsyncThunk("users/profile", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users`);
  return response;
});

export const updateFullName = createAsyncThunk("user/update_full_name", async (payload: any) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/full_name`, payload);
  return response;
});

export const updateGender = createAsyncThunk("user/update_gender", async (payload: any) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/gender`, payload);
  return response;
});

export const updateDateOfBirth = createAsyncThunk(
  "user/update_date_of_birth",
  async (payload: any) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/users/date_of_birth`,
      payload,
    );
    return response;
  },
);

export const updatePhoneNumber = createAsyncThunk(
  "user/update_phone_number",
  async (payload: any) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/users/phone_number`,
      payload,
    );
    return response;
  },
);

export const updateEmail = createAsyncThunk("user/update_email", async (payload: any) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/email`, payload);
  return response;
});

export const updatePassword = createAsyncThunk("user/update_password", async (payload: any) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/password`, payload);
  return response;
});

export const toggleNewsletter = createAsyncThunk("user/newsletter", async () => {
  const response = await patchReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/newsletter`, {});
  return response;
});

interface initialStateType {
  profileObj: RequestObject<ProfileResource | null>;
  updateFullNameObj: RequestObject<ProfileResource>;
  updateGenderObj: RequestObject<ProfileResource>;
  updateDateOfBirthObj: RequestObject<ProfileResource>;
  updatePhoneNumberObj: RequestObject<ProfileResource>;
  updateEmailObj: RequestObject<ProfileResource>;
  updatePasswordObj: RequestObject<ProfileResource>;
  toggleNewsletterObj: RequestObject<ProfileResource>;
}

const initialState: initialStateType = {
  profileObj: idleObject(),
  updateFullNameObj: idleObject(),
  updateGenderObj: idleObject(),
  updateDateOfBirthObj: idleObject(),
  updatePhoneNumberObj: idleObject(),
  updateEmailObj: idleObject(),
  updatePasswordObj: idleObject(),
  toggleNewsletterObj: idleObject(),
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfile: (state) => {
      state.profileObj.data = null;
      state.profileObj.status = "idle";
      state.profileObj.successMessage = null;
      state.profileObj.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.profileObj.status = "pending";
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.profileObj.status = "succeeded";
        state.profileObj.data = data;
        state.profileObj.successMessage = message;
      })
      .addCase(getProfile.rejected, (state, action) => {
        const { message } = action.error;

        state.profileObj.status = "failed";
        state.profileObj.errorMessage = message;
      })
      .addCase(updateFullName.pending, (state) => {
        state.updateFullNameObj.status = "pending";
      })
      .addCase(updateFullName.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateFullNameObj.status = "succeeded";
        state.updateFullNameObj.data = data;
        state.profileObj.data = data;
        state.updateFullNameObj.successMessage = message;
      })
      .addCase(updateFullName.rejected, (state, action) => {
        const { message } = action.error;

        state.updateFullNameObj.status = "failed";
        state.updateFullNameObj.errorMessage = message;
      })
      .addCase(updateGender.pending, (state) => {
        state.updateGenderObj.status = "pending";
      })
      .addCase(updateGender.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateGenderObj.status = "succeeded";
        state.updateGenderObj.data = data;
        state.profileObj.data = data;
        state.updateGenderObj.successMessage = message;
      })
      .addCase(updateGender.rejected, (state, action) => {
        const { message } = action.error;

        state.updateGenderObj.status = "failed";
        state.updateGenderObj.errorMessage = message;
      })
      .addCase(updateDateOfBirth.pending, (state) => {
        state.updateDateOfBirthObj.status = "pending";
      })
      .addCase(updateDateOfBirth.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateDateOfBirthObj.status = "succeeded";
        state.updateDateOfBirthObj.data = data;
        state.profileObj.data = data;
        state.updateDateOfBirthObj.successMessage = message;
      })
      .addCase(updateDateOfBirth.rejected, (state, action) => {
        const { message } = action.error;

        state.updateDateOfBirthObj.status = "failed";
        state.updateDateOfBirthObj.errorMessage = message;
      })
      .addCase(updatePhoneNumber.pending, (state) => {
        state.updatePhoneNumberObj.status = "pending";
      })
      .addCase(updatePhoneNumber.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updatePhoneNumberObj.status = "succeeded";
        state.updatePhoneNumberObj.data = data;
        state.profileObj.data = data;
        state.updatePhoneNumberObj.successMessage = message;
      })
      .addCase(updatePhoneNumber.rejected, (state, action) => {
        const { message } = action.error;

        state.updatePhoneNumberObj.status = "failed";
        state.updatePhoneNumberObj.errorMessage = message;
      })
      .addCase(updateEmail.pending, (state) => {
        state.updateEmailObj.status = "pending";
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateEmailObj.status = "succeeded";
        state.updateEmailObj.data = data;
        state.profileObj.data = data;
        state.updateEmailObj.successMessage = message;
      })
      .addCase(updateEmail.rejected, (state, action) => {
        const { message } = action.error;

        state.updateEmailObj.status = "failed";
        state.updateEmailObj.errorMessage = message;
      })
      .addCase(updatePassword.pending, (state) => {
        state.updatePasswordObj.status = "pending";
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updatePasswordObj.status = "succeeded";
        state.updatePasswordObj.data = data;
        state.profileObj.data = data;
        state.updatePasswordObj.successMessage = message;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        const { message } = action.error;

        state.updatePasswordObj.status = "failed";
        state.updatePasswordObj.errorMessage = message;
      })
      .addCase(toggleNewsletter.pending, (state) => {
        state.toggleNewsletterObj.status = "pending";
      })
      .addCase(toggleNewsletter.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.toggleNewsletterObj.status = "succeeded";
        state.toggleNewsletterObj.data = data;
        state.profileObj.data = data;
        state.toggleNewsletterObj.successMessage = message;
      })
      .addCase(toggleNewsletter.rejected, (state, action) => {
        const { message } = action.error;

        state.toggleNewsletterObj.status = "failed";
        state.toggleNewsletterObj.errorMessage = message;
      });
  },
});

export default profileSlice.reducer;

// state
export const profileSelector = (state: RootState) => state.profile;

export const { resetProfile } = profileSlice.actions;
