import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MealPrepBackground from "../../../assets/images/close-up-delicious-meal-preparation.png";
import routes from "../../../components/navigation/routes";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import { login } from "../../../services/auth/auth-slice.service";
import { getProfile } from "../../../services/profile/profile-slice.service";
import { useAppDispatch } from "../../../services/store";
import LoginForm from "../components/login/login-form.component";
import { LoginFormValues } from "../type/type";

function LoginScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (values: LoginFormValues) => {
    setIsLoading(true);
    if (values.isRemember) {
      localStorage.setItem("isRemember", "true");
      localStorage.setItem("userEmail", values.email);
    } else {
      localStorage.removeItem("isRemember");
      localStorage.removeItem("userEmail");
    }
    dispatch(login(values))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          dispatch(getProfile());
          navigate(routes.HOME);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "85vh",
        backgroundImage: `url(${MealPrepBackground})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        paddingX: isMobile ? "0px" : "100px",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <LoginForm isLoading={isLoading} handleSubmit={handleSubmit} />
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default LoginScreen;
