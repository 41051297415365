import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, styled, useMediaQuery } from "@mui/material";
import { PropsWithChildren } from "react";
import Slider, { CustomArrowProps, Settings } from "react-slick";
import theme from "../../infrastructure/theme";

const StyledArrowIconBox = styled(Box)(() => ({
  ".MuiSvgIcon-root": {
    fontSize: "30px",
    strokeWidth: 2,
  },
  ":hover": {
    color: "#000",
  },
  color: "#000",
  zIndex: 1,
  width: "20px",
  display: "flex",
  "&.slick-arrow::before": {
    display: "none",
  },
  "&.slick-arrow": {
    alignItems: "center",
    justifyContent: "center",
  },
  "&.slick-prev": {
    marginRight: "20px",
  },
}));

interface Props {
  slidesToShow?: number;
  slidesToScroll?: number;
  paddingAfterItem: string;
  showArrowOnMobileView?: boolean;
  arrowMarginTop?: string;
  initialSlide?: number;
}

export default function ItemCarousel({
  children,
  slidesToShow,
  slidesToScroll,
  paddingAfterItem,
  showArrowOnMobileView,
  arrowMarginTop,
  initialSlide,
}: PropsWithChildren<Props>) {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function PrevArrow(arrowProps: CustomArrowProps) {
    const { className, onClick, currentSlide } = arrowProps;
    return (
      <StyledArrowIconBox
        onClick={onClick}
        className={className}
        sx={{ marginTop: arrowMarginTop }}
      >
        <ArrowBackIosNewIcon color={currentSlide === 0 ? "disabled" : "primary"} />
      </StyledArrowIconBox>
    );
  }

  function NextArrow(arrowProps: CustomArrowProps & Settings) {
    const { className, onClick, currentSlide, slideCount, slidesToShow } = arrowProps;
    return (
      <StyledArrowIconBox
        onClick={onClick}
        className={`slick-disabled ${className}`}
        sx={{ marginTop: arrowMarginTop }}
      >
        <ArrowForwardIosIcon
          color={
            slideCount && slidesToShow && currentSlide === slideCount - slidesToShow
              ? "disabled"
              : "primary"
          }
        />
      </StyledArrowIconBox>
    );
  }

  const settings = {
    infinite: false,
    dots: false,
    autoplay: false,
    slidesToShow,
    slidesToScroll,
    nextArrow: <NextArrow slidesToShow={slidesToShow} />,
    prevArrow: <PrevArrow />,
    arrows: isMobile ? (showArrowOnMobileView ? showArrowOnMobileView : false) : true,
  };

  return (
    <Box
      sx={{
        paddingX: isMobile ? (showArrowOnMobileView ? "15px" : "0px") : "25px",
        "& .slick-slide > div": { paddingX: paddingAfterItem },
      }}
    >
      <Slider {...settings} initialSlide={initialSlide}>
        {children}
      </Slider>
    </Box>
  );
}

ItemCarousel.defaultProps = {
  paddingAfterItem: "5px",
  slidesToShow: 3,
  slidesToScroll: 2,
};
