import { Avatar, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import { isLogin } from "../../../utils";

interface Props {
  handleLogout: () => void;
}

const AvatarMenu = ({ handleLogout }: Props) => {
  const navigate = useNavigate();
  const [avatarMenu, setAvatarMenu] = useState<(EventTarget & Element) | null>(null);
  const handleOpenUserMenu = (event: SyntheticEvent) => {
    setAvatarMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAvatarMenu(null);
  };

  const guestMenuItems = () => (
    <div>
      <MenuItem
        key={1}
        onClick={() => {
          handleCloseUserMenu();
          navigate(routes.LOGIN);
        }}
      >
        <Typography textAlign="center">Login</Typography>
      </MenuItem>
      <MenuItem
        key={2}
        onClick={() => {
          handleCloseUserMenu();
          navigate(routes.SIGN_UP);
        }}
      >
        <Typography textAlign="center">Sign Up</Typography>
      </MenuItem>
    </div>
  );

  const loggedInMenuItems = () => (
    <div>
      <MenuItem
        key={1}
        onClick={() => {
          handleCloseUserMenu();
          navigate(routes.USER_PROFILE);
        }}
      >
        <Typography textAlign="center">Account</Typography>
      </MenuItem>
      <MenuItem
        key={2}
        onClick={() => {
          handleCloseUserMenu();
          navigate(routes.MY_ORDERS);
        }}
      >
        <Typography textAlign="center">My Orders</Typography>
      </MenuItem>
      <MenuItem
        key={3}
        onClick={() => {
          handleCloseUserMenu();
          handleLogout();
        }}
      >
        <Typography textAlign="center">Sign Out</Typography>
      </MenuItem>
    </div>
  );

  return (
    <>
      <IconButton onClick={handleOpenUserMenu}>
        <Avatar
          sx={{
            backgroundColor: "white",
            width: "35px",
            height: "35px",
          }}
        />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={avatarMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              width: "160px",
            },
          },
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(avatarMenu)}
        onClose={handleCloseUserMenu}
      >
        {isLogin() ? loggedInMenuItems() : guestMenuItems()}
      </Menu>
    </>
  );
};

export default AvatarMenu;
