import { FormHelperText } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { FormikValues, useFormikContext } from "formik";

interface Props {
  name: string;
  disabled?: boolean;
  disablePast?: boolean;
  onChangeAction?: (date: any) => void;
  shouldDisableToday?: boolean;
  shouldDisableWeekends?: boolean;
}

export default function FormDatePicker({
  name,
  disabled,
  disablePast,
  onChangeAction,
  shouldDisableToday,
  shouldDisableWeekends,
}: Props) {
  const { values, setFieldValue, errors, touched } = useFormikContext<FormikValues>();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (date: any) => {
    setFieldValue(name, date);
    if (onChangeAction) {
      onChangeAction(date);
    }
  };

  const disableDays = (date: Dayjs) => {
    const day = date.day();

    if (shouldDisableWeekends && shouldDisableToday) {
      return day === 0 || day === 6 || date.isSame(dayjs(), "date");
    } else if (shouldDisableWeekends && !shouldDisableToday) {
      return day === 0 || day === 6;
    } else if (!shouldDisableWeekends && shouldDisableToday) {
      return date.isSame(dayjs(), "date");
    }

    return false;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        shouldDisableDate={shouldDisableWeekends || shouldDisableToday ? disableDays : undefined}
        disablePast={disablePast}
        format="DD/MM/YYYY"
        disabled={disabled}
        value={values[name]}
        onChange={(newValue) => handleChange(newValue)}
        slotProps={{
          field: { clearable: true, onClear: () => setFieldValue(name, null) },
          textField: { size: "small", fullWidth: true, error: showError },
        }}
      />

      {showError && (
        <FormHelperText error sx={{ marginX: "14px" }}>
          {String(errors[name])}
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
}
FormDatePicker.defaultProps = {
  disabled: false,
  disablePast: true,
};
