import { Box, Grid, styled, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import Chefs from "../../../assets/images/chefs_thumbs_up.png";
import CateringDinner from "../../../assets/images/scooping-food-catering-dinner-time.png";
import SelfServiceBackground from "../../../assets/images/self-service-restaurante-food.png";
import BackgroundButton from "../../../components/button/background-button.component";
import Spacer from "../../../components/utils/spacer.component";
import theme from "../../../infrastructure/theme";
import ContactUs from "../components/contact-us.component";

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const MaxWidthBox = styled(Box)(({ theme }) => ({
  maxWidth: theme.dimensions.PCMaxWidth,
  padding: "10px",
}));

const PackageMainText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.primary.main,
  padding: "30px 0",
  textAlign: "center",
}));

const PackageGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: "30px 0",
  textAlign: "center",
}));

const CateringScreen = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <Box
        sx={{
          width: "100%",
          height: "55vh",
          backgroundImage: `url(${SelfServiceBackground})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          paddingX: isMobile ? "10px" : "100px",
        }}
      />

      <Box
        sx={{
          padding: isMobile ? "20px" : "100px",
          width: "100%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid
          container
          rowSpacing={0}
          sx={{
            padding: "20px",
            backgroundColor: theme.palette.bg.primary,
            borderRadius: theme.shape.borderSizes[1],
            maxWidth: theme.dimensions.PCMaxWidth,
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              fontWeight="bold"
              color="primary.main"
              textAlign="center"
              padding="30px"
            >
              Yi Jia Canteen Catering
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              textAlign="justify"
              bgcolor={theme.palette.common.white}
              padding="30px"
              borderRadius={theme.shape.borderSizes[1]}
            >
              Transform your events with Yi Jia Canteen Catering Services, where culinary excellence
              meets seamless hospitality. Our dedicated team takes pride in curating a culinary
              experience that transcends the ordinary, offering a diverse menu tailored to suit the
              unique preferences of your guests. From corporate gatherings to intimate celebrations,
              we bring the warmth of home-cooked goodness to your events. With a commitment to using
              only the finest, locally sourced ingredients, our chefs craft dishes that not only
              tantalize the taste buds but also leave a lasting impression. Allow us to handle the
              details while you savor the moments— Yi Jia Canteen Catering Services, where every
              event is a feast of flavours and hospitality.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <MaxWidthBox>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: theme.shape.borderSizes[1],
          }}
        >
          <Grid item xs={isMobile ? 12 : 4}>
            <img
              src={CateringDinner}
              alt={CateringDinner}
              width="100%"
              height="100%"
              style={{
                borderTopLeftRadius: theme.shape.borderSizes[1],
                borderTopRightRadius: isMobile ? theme.shape.borderSizes[1] : undefined,
                borderBottomLeftRadius: isMobile ? undefined : theme.shape.borderSizes[1],
              }}
            />
          </Grid>
          <Grid container item xs={isMobile ? 12 : 8} sx={{ padding: "30px" }}>
            <Grid item>
              <Typography variant="h4" fontWeight="bold" color="text.white">
                Our Services
              </Typography>
              {isMobile && <Spacer size="m" />}
            </Grid>
            <Grid item>
              <Typography variant="h6" color="text.white">
                Wedding Catering, Corporate Catering, Event Catering, Buffet Catering, Boxed Lunch
                Catering and more.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={isMobile ? 2 : 6} paddingTop="100px">
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" color="primary.main">
              Catering Packages
            </Typography>
          </Grid>
          <Grid item container>
            <Grid
              item
              container
              xs={12}
              border={`1px solid ${theme.palette.text.secondary}`}
              borderRadius={theme.shape.borderSizes[1]}
              padding={isMobile ? "10px" : "30px"}
              rowSpacing={1}
            >
              <Grid item xs={4} sm={3}>
                <PackageMainText variant="h6">Catering Type</PackageMainText>
              </Grid>
              <Grid item xs={4} sm={3}>
                <PackageMainText variant="h6">(RM)</PackageMainText>
              </Grid>
              <Grid item xs={4} sm={3}>
                <PackageMainText variant="h6">Min. Pax</PackageMainText>
              </Grid>
              <Grid item xs={4} sm={3} />

              <Grid item container xs={12} sm={9} bgcolor="bg.secondary">
                <Grid item xs={4}>
                  <PackageGreyText variant="h6">Box Lunch</PackageGreyText>
                </Grid>
                <Grid item xs={4}>
                  <PackageGreyText variant="h6" fontStyle="italic">
                    From XX.XX
                  </PackageGreyText>
                </Grid>
                <Grid item xs={4}>
                  <PackageGreyText variant="h6">From 10 Pax</PackageGreyText>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                display="flex"
                alignItems="center"
                paddingX={isMobile ? "10px" : "30px"}
              >
                <BackgroundButton onClick={() => {}}>DOWNLOAD</BackgroundButton>
              </Grid>
              <Grid item xs={12}>
                <Spacer />
              </Grid>
              <Grid item container xs={12} sm={9} bgcolor="bg.secondary">
                <Grid item xs={4}>
                  <PackageGreyText variant="h6">Buffet Catering</PackageGreyText>
                </Grid>
                <Grid item xs={4}>
                  <PackageGreyText variant="h6" fontStyle="italic">
                    From XX.XX
                  </PackageGreyText>
                </Grid>
                <Grid item xs={4}>
                  <PackageGreyText variant="h6">From 30 Pax</PackageGreyText>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                display="flex"
                alignItems="center"
                paddingX={isMobile ? "10px" : "30px"}
              >
                <BackgroundButton onClick={() => {}}>DOWNLOAD</BackgroundButton>
              </Grid>
              <Grid item xs={12}>
                <Spacer />
              </Grid>
              <Grid item container xs={12} sm={9} bgcolor="bg.secondary">
                <Grid item xs={4}>
                  <PackageGreyText variant="h6">Private Cooking</PackageGreyText>
                </Grid>
                <Grid item xs={4}>
                  <PackageGreyText variant="h6" fontStyle="italic">
                    From XX.XX
                  </PackageGreyText>
                </Grid>
                <Grid item xs={4}>
                  <PackageGreyText variant="h6" fontStyle="italic">
                    Quotation Basis
                  </PackageGreyText>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                display="flex"
                alignItems="center"
                paddingX={isMobile ? "10px" : "30px"}
              >
                <BackgroundButton onClick={() => {}}>DOWNLOAD</BackgroundButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container paddingTop="100px" position="relative">
          {!isMobile && (
            <>
              <Grid item xs={4}>
                <img src={Chefs} alt={Chefs} width="100%" height="100%" style={{ zIndex: 99 }} />
              </Grid>
              <Grid item xs={0.3} />
            </>
          )}

          <Grid item xs={isMobile ? 12 : 7.5} alignSelf="flex-end" marginBottom="50px">
            <ContactUs
              handleSubmit={() => {
                setIsLoading(true);
              }}
              isLoading={isLoading}
            />
          </Grid>
          {!isMobile && (
            <Box
              sx={{
                position: "absolute",
                height: "300px",
                width: "100%",
                backgroundColor: "primary.main",
                bottom: 0,
                left: 0,
                zIndex: -99,
                borderRadius: "50% / 100%",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
          )}
        </Grid>
      </MaxWidthBox>
    </MainContainer>
  );
};

export default CateringScreen;
