import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";
import { orderSelector } from "../../../services/order/order-slice.service";
import { useAppSelector } from "../../../services/store";
import { PreorderItemFormValues } from "../preorder/type/type";
import { SameDayFormValues } from "../same-day/type/type";

const pickUpTime = [
  { type: "11:30 AM - 12:00 PM (Cut Off Time: 9:30 AM)", value: "11:30:00" },
  { type: "12:00 PM - 1:00 PM (Cut Off Time: 9:30 AM)", value: "12:00:00" },
  { type: "5:30 PM - 6:30 PM (Cut Off Time: 2:00 PM)", value: "17:30:00" },
  { type: "6:30 PM - 7:30 PM (Cut Off Time: 2:00 PM)", value: "18:30:00" },
];

const deliveryTime = [
  { type: "11:00 AM - 12:00 PM (Cut Off Time: 9:30 AM)", value: "11:00:00" },
  { type: "12:00 PM - 1:00 PM (Cut Off Time: 9:30 AM)", value: "12:00:00" },
  { type: "5:00 PM - 6:00 PM (Cut Off Time: 2:00 PM)", value: "17:00:00" },
  { type: "6:00 PM - 7:00 PM (Cut Off Time: 2:00 PM)", value: "18:00:00" },
];

interface Props {
  getFoodByDateFromOrder?: (date: any) => void;
}

const SelectPickUpTimeSection = ({ getFoodByDateFromOrder }: Props) => {
  const location = useLocation();
  const { values, setFieldValue } = useFormikContext<SameDayFormValues | PreorderItemFormValues>();
  const { preorderCreatedObj, preorderUpdatingObj } = useAppSelector(orderSelector);
  const isUpdateOrder = location.pathname === routes.PREORDER_ITEM_EDIT;
  const orderObject = isUpdateOrder ? preorderUpdatingObj : preorderCreatedObj;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const editable =
    location.pathname === routes.PREORDER_ITEM_EDIT
      ? (values as PreorderItemFormValues).currentMealEditable
      : true;
  const [timeList, setTimeList] = useState(values.type === "pickup" ? pickUpTime : deliveryTime);
  const [filterPickUpTime, setFilterPickUpTime] =
    useState<{ type: string; value: string }[]>(timeList);
  const lunchCufOff = dayjs().set("hour", 9).set("minute", 30);
  const dinnerCufOff = dayjs().set("hour", 14).set("minute", 0);

  useEffect(() => {
    setFilterPickUpTime(timeList);
    if (dayjs(values.date).isSame(dayjs(), "date")) {
      if (dayjs().isAfter(dinnerCufOff)) {
        setFilterPickUpTime([]);
      } else if (dayjs().isAfter(lunchCufOff)) {
        setFilterPickUpTime(timeList.slice(2));
      }
    }
  }, [values.date]);

  useEffect(() => {
    const updatedTimeList = values.type === "pickup" ? pickUpTime : deliveryTime;
    setTimeList(updatedTimeList);

    setFilterPickUpTime(updatedTimeList);
    if (dayjs(values.date).isSame(dayjs(), "date")) {
      if (dayjs().isAfter(dinnerCufOff)) {
        setFilterPickUpTime([]);
      } else if (dayjs().isAfter(lunchCufOff)) {
        setFilterPickUpTime(updatedTimeList.slice(2));
      }
    }
  }, [values.type]);

  const onDateChange = (date: any) => {
    const preorderValues = values as PreorderItemFormValues;

    if (orderObject && orderObject.type === "preorder") {
      if (orderObject.items[preorderValues.currentMealIndex - 1]) {
        const createdItem = orderObject.items[preorderValues.currentMealIndex - 1];
        if (dayjs(date).isSame(dayjs(createdItem.date_time.slice(0, 10)))) {
          setFieldValue("type", createdItem.type);
          setFieldValue("date", dayjs(createdItem.date_time.slice(0, 10)));
          setFieldValue("pickUpTime", createdItem.date_time.slice(-8));
          setFieldValue(
            "meat",
            createdItem.foods.meat.map((item) => item.id),
          );
          setFieldValue(
            "vege",
            createdItem.foods.vege.map((item) => item.id),
          );
          setFieldValue(
            "side",
            createdItem.foods.side.map((item) => item.id),
          );
          setFieldValue("carb", createdItem.foods.carb);
          setFieldValue("addon", createdItem.foods.addon);
          setFieldValue(
            "address_id",
            createdItem.delivery
              ? {
                  id: createdItem.delivery.address.id,
                  option: createdItem.delivery.address.location,
                }
              : null,
          );
          setFieldValue(
            "deliveryFee",
            createdItem.delivery
              ? parseFloat(createdItem.delivery.polygon.fee.replaceAll(",", ""))
              : 0,
          );
          setFieldValue(
            "deliveryAddressLatLong",
            createdItem.delivery
              ? `${createdItem.delivery.address.lat}${createdItem.delivery.address.long}`
              : "",
          );
          setFieldValue("currentMealEditable", createdItem.editable);
        } else {
          setFieldValue("meat", []);
          setFieldValue("vege", []);
          setFieldValue("side", []);
          setFieldValue("carb", []);
          setFieldValue("addon", []);
        }
      }
    }
    if (getFoodByDateFromOrder) {
      getFoodByDateFromOrder(date);
    }
  };

  return (
    <Grid container display="flex" alignItems="center" justifyContent="center" spacing={1}>
      {location.pathname === routes.PREORDER_ITEM ||
      location.pathname === routes.PREORDER_ITEM_EDIT ? (
        <Grid item xs={isMobile ? 12 : 4}>
          <Typography fontWeight="bold" color="text.secondary">
            Select a date
          </Typography>
          <Spacer />
          <FormDatePicker
            name="date"
            disabled={!editable}
            onChangeAction={onDateChange}
            shouldDisableToday={dayjs().isAfter(dinnerCufOff)}
            shouldDisableWeekends
          />
        </Grid>
      ) : (
        <Grid item xs={isMobile ? 12 : 4}>
          <Typography
            textTransform="uppercase"
            variant="h6"
            fontWeight="bold"
            color="text.secondary"
            textAlign={isMobile ? "center" : undefined}
          >
            Menu of the Day
          </Typography>
          <Spacer />
          <Typography
            textTransform="uppercase"
            fontWeight="bold"
            textAlign={isMobile ? "center" : undefined}
          >
            {format(new Date(), "dd MMM yyyy")}
          </Typography>
        </Grid>
      )}

      <Grid item xs={isMobile ? 12 : 4}>
        <Typography fontWeight="bold" color="text.secondary">
          Select Your {values.type === "pickup" ? "Pick Up" : "Delivery"} Time
        </Typography>
        <Spacer />
        <FormSelectFieldText
          name="pickUpTime"
          selections={editable ? filterPickUpTime : timeList}
          disabled={!editable}
        />
      </Grid>
    </Grid>
  );
};

export default SelectPickUpTimeSection;
