import { Skeleton } from "@mui/material";

interface Props {
  variant?: "text" | "rectangular" | "rounded" | "circular";
  width?: string | number;
  height?: string | number;
}

function CustomSkeleton({ variant, width, height }: Props) {
  return <Skeleton variant={variant} width={width} height={height} />;
}

CustomSkeleton.defaultProps = {
  variant: "rounded",
  width: "100%",
  height: "24px",
};

export default CustomSkeleton;
