import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, postReq, putReq } from "../../../api.services";
import { idleObject } from "../../../idle-object";
import { RequestObject, RootState } from "../../../store";

export const createSameDayOrderItem = createAsyncThunk(
  "order/same_day/items",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/orders/same_day/items`,
      payload,
    );
    return response;
  },
);

export const updateSameDayOrderItem = createAsyncThunk(
  "orders/same_day/items/{id}/update",
  async (payload: any) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/orders/same_day/items/${payload.order_item_id}`,
      payload,
    );
    return response;
  },
);

export const deleteSameDayOrderItem = createAsyncThunk(
  "orders/same_day/items/{id}/delete",
  async (payload: any) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/orders/same_day/items/${payload}`,
    );
    return response;
  },
);

interface initialStateType {
  createSameDayOrderItemObj: RequestObject;
  updateSameDayOrderItemObj: RequestObject;
  deleteSameDayOrderItemObj: RequestObject;
}

const initialState: initialStateType = {
  createSameDayOrderItemObj: idleObject(),
  updateSameDayOrderItemObj: idleObject(),
  deleteSameDayOrderItemObj: idleObject(),
};

const sameDayOrderItemSlice = createSlice({
  name: "sameDayOrderItem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSameDayOrderItem.pending, (state) => {
        state.createSameDayOrderItemObj.status = "pending";
      })
      .addCase(createSameDayOrderItem.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createSameDayOrderItemObj.status = "succeeded";
        state.createSameDayOrderItemObj.data = data;
        state.createSameDayOrderItemObj.successMessage = message;
      })
      .addCase(createSameDayOrderItem.rejected, (state, action) => {
        const { message } = action.error;

        state.createSameDayOrderItemObj.status = "failed";
        state.createSameDayOrderItemObj.errorMessage = message;
      })
      .addCase(updateSameDayOrderItem.pending, (state) => {
        state.updateSameDayOrderItemObj.status = "pending";
      })
      .addCase(updateSameDayOrderItem.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateSameDayOrderItemObj.status = "succeeded";
        state.updateSameDayOrderItemObj.data = data;
        state.updateSameDayOrderItemObj.successMessage = message;
      })
      .addCase(updateSameDayOrderItem.rejected, (state, action) => {
        const { message } = action.error;

        state.updateSameDayOrderItemObj.status = "failed";
        state.updateSameDayOrderItemObj.errorMessage = message;
      })
      .addCase(deleteSameDayOrderItem.pending, (state) => {
        state.deleteSameDayOrderItemObj.status = "pending";
      })
      .addCase(deleteSameDayOrderItem.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.deleteSameDayOrderItemObj.status = "succeeded";
        state.deleteSameDayOrderItemObj.data = data;
        state.deleteSameDayOrderItemObj.successMessage = message;
      })
      .addCase(deleteSameDayOrderItem.rejected, (state, action) => {
        const { message } = action.error;

        state.deleteSameDayOrderItemObj.status = "failed";
        state.deleteSameDayOrderItemObj.errorMessage = message;
      });
  },
});

export default sameDayOrderItemSlice.reducer;

// state
export const sameDayOrderItemSelector = (state: RootState) => state.sameDayOrderItem;
