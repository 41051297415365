import { Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BackgroundButton from "../../../../components/button/background-button.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import routes from "../../../../components/navigation/routes";
import Loading from "../../../../components/notification/backdrop-loading.component";
import ConfirmationModal from "../../../../components/notification/confirmation-modal.component";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import Spacer from "../../../../components/utils/spacer.component";
import {
  createOrder,
  orderSelector,
  setSameDayOrderCreated,
  setSameDayOrderUpdating,
} from "../../../../services/order/order-slice.service";
import {
  createSameDayOrderItem,
  updateSameDayOrderItem,
} from "../../../../services/order/same-day/order-item/same-day-order-item.slice";
import { useAppDispatch, useAppSelector } from "../../../../services/store";
import DeliveryOptionSection from "../../components/delivery-option-section.component";
import PickUpAddressSection from "../../components/pickup-address-section.component";
import RemarkSection from "../../components/remark-section.component";
import SelectFoodSection from "../../components/select-food-section.component";
import SelectPickUpTimeSection from "../../components/select-pick-up-time-section.component";
import StageProgressBar from "../../components/stage-progress-bar.component";
import { OrderFoods } from "../../type/type";
import { SameDayFormValues } from "../type/type";

const validationSchema = Yup.object().shape({
  pickUpTime: Yup.string().required().label("Pick Up Time"),
});

const SameDayOrderScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { sameDayOrderCreatedObj, sameDayOrderUpdatingObj } = useAppSelector(orderSelector);
  const isUpdateOrder = location.pathname === routes.SAME_DAY_ORDER_EDIT;
  const orderObject = isUpdateOrder ? sameDayOrderUpdatingObj : sameDayOrderCreatedObj;
  const [foods, setFoods] = useState<OrderFoods | undefined | null>(null);
  const [orderId, setOrderId] = useState<number | null | undefined>(orderObject?.id);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState<boolean>(false);
  const [showUpdateAmountDifference, setShowUpdateAmountDifference] = useState<boolean>(false);
  const snackBar = useSnackBar();
  const formRef = useRef<FormikProps<SameDayFormValues>>(null);
  const [updateAmountDifference, setUpdateAmountDifference] = useState(0);
  const [updateNewTotal, setUpdateNewTotal] = useState(0);

  const initialValues: SameDayFormValues = {
    type: "pickup",
    date: new Date(),
    pickUpTime: orderObject && orderObject.item ? orderObject.item.date_time.slice(-8) : "",
    remark: orderObject && orderObject.item ? orderObject.item.remark : "",
    meat: orderObject && orderObject.item ? orderObject.item.foods.meat : [],
    vege: orderObject && orderObject.item ? orderObject.item.foods.vege : [],
    side: orderObject && orderObject.item ? orderObject.item.foods.side : [],
    carb: orderObject && orderObject.item ? orderObject.item.foods.carb : [],
    addon: orderObject && orderObject.item ? orderObject.item.foods.addon : [],
  };

  const submitSameDayOrderItem = (values: SameDayFormValues) => {
    setIsLoading(true);
    setShowUpdateAmountDifference(false);
    setShowUpdateConfirmation(false);
    const payload = {
      order_id: orderId,
      order_item_id: orderObject && orderObject.item ? orderObject.item?.id : null,
      date_time: format(new Date(), "yyyy-MM-dd ") + values.pickUpTime,
      remark: values.remark,
      meat_food_ids: values.meat,
      vege_food_ids: values.vege,
      side_food_ids: values.side,
      carb_food_ids: values.carb,
      addon_food_ids: values.addon,
    };

    if (payload.order_item_id) {
      dispatch(updateSameDayOrderItem(payload))
        .unwrap()
        .then((res) => {
          if (res.success) {
            setIsLoading(false);
            if (isUpdateOrder) {
              dispatch(setSameDayOrderUpdating(res.data));
              navigate(routes.SAME_DAY_ORDER_EDIT_SUMMARY);
            } else {
              dispatch(setSameDayOrderCreated(res.data));
              navigate(routes.SAME_DAY_ORDER_SUMMARY);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          snackBar.createSnackBar({
            message: err.message,
            type: "error",
            open: true,
          });
        });
    } else {
      dispatch(createSameDayOrderItem(payload))
        .unwrap()
        .then((res) => {
          if (res.success) {
            setIsLoading(false);
            dispatch(setSameDayOrderCreated(res.data));
            navigate(routes.SAME_DAY_ORDER_SUMMARY, { state: { ...res.data } });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          snackBar.createSnackBar({
            message: err.message,
            type: "error",
            open: true,
          });
        });
    }
  };

  useEffect(() => {
    if (!orderId && !isUpdateOrder) {
      dispatch(createOrder({ type: "same_day" })).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          formRef.current?.resetForm();
          setFoods(payload.data.foods);
          setOrderId(payload.data.id);
          dispatch(setSameDayOrderCreated(payload.data));
        }
      });
    } else if (!orderId && isUpdateOrder) {
      navigate(routes.HOME);
    } else {
      setFoods(orderObject?.foods);
    }
  }, []);

  const calculateOutstandingOrExcessTotal = () => {
    if (isUpdateOrder && sameDayOrderUpdatingObj) {
      const paidTotal = parseFloat(
        sameDayOrderUpdatingObj.payment_summary.paid.total_amount.replaceAll(",", ""),
      );

      if (formRef && formRef.current && paidTotal > 0) {
        let meatTotal = 0;
        meatTotal = formRef.current?.values.meat.reduce((accumulator, currentValue) => {
          const itemInFood = foods?.meat.find((food) => food.id === currentValue.id);
          if (foods && itemInFood && itemInFood.price) {
            return (
              accumulator +
              currentValue.no_of_pax * parseFloat(itemInFood.price.replaceAll(",", ""))
            );
          }

          return accumulator;
        }, meatTotal);

        let vegeTotal = 0;
        vegeTotal = formRef.current?.values.vege.reduce((accumulator, currentValue) => {
          const itemInFood = foods?.vege.find((food) => food.id === currentValue.id);
          if (foods && itemInFood && itemInFood.price) {
            return (
              accumulator +
              currentValue.no_of_pax * parseFloat(itemInFood.price.replaceAll(",", ""))
            );
          }

          return accumulator;
        }, vegeTotal);

        let sideTotal = 0;
        sideTotal = formRef.current?.values.side.reduce((accumulator, currentValue) => {
          const itemInFood = foods?.side.find((food) => food.id === currentValue.id);
          if (foods && itemInFood && itemInFood.price) {
            return (
              accumulator +
              currentValue.no_of_pax * parseFloat(itemInFood.price.replaceAll(",", ""))
            );
          }

          return accumulator;
        }, sideTotal);

        let carbTotal = 0;
        carbTotal = formRef.current?.values.carb.reduce((accumulator, currentValue) => {
          const itemInFood = foods?.carb.find((food) => food.id === currentValue.id);
          if (foods && itemInFood && itemInFood.price) {
            return (
              accumulator +
              currentValue.no_of_pax * parseFloat(itemInFood.price.replaceAll(",", ""))
            );
          }

          return accumulator;
        }, carbTotal);

        let addonTotal = 0;
        addonTotal = formRef.current?.values.addon.reduce((accumulator, currentValue) => {
          const itemInFood = foods?.addon.find((food) => food.id === currentValue.id);
          if (foods && itemInFood && itemInFood.price) {
            return (
              accumulator +
              currentValue.no_of_pax * parseFloat(itemInFood.price.replaceAll(",", ""))
            );
          }

          return accumulator;
        }, addonTotal);

        const newTotal = meatTotal + vegeTotal + sideTotal + carbTotal + addonTotal;
        const difference = newTotal - paidTotal;

        setUpdateNewTotal(newTotal);
        setUpdateAmountDifference(difference);
        if (difference !== 0) {
          setShowUpdateAmountDifference(true);
        } else {
          setShowUpdateConfirmation(true);
        }
      }

      return 0;
    }
  };

  return (
    <Grid container display="flex" flexDirection="column" rowSpacing={4}>
      <Loading isLoading={isLoading} />
      <ConfirmationModal
        width={500}
        showModal={showUpdateConfirmation}
        setShowModal={setShowUpdateConfirmation}
        onConfirm={() => formRef.current?.submitForm()}
        title="Update Confirmation"
        messageSection={
          <Grid item>
            <Grid container item justifyContent="space-between">
              <Typography>Total Paid:</Typography>
              <Typography>
                RM {sameDayOrderUpdatingObj?.payment_summary.paid.total_amount}
              </Typography>
            </Grid>
            <Grid container item justifyContent="space-between">
              <Typography>Order Price after update:</Typography>
              <Typography>
                RM {sameDayOrderUpdatingObj?.payment_summary.paid.total_amount}
              </Typography>
            </Grid>

            <Spacer size="l" />
            <Typography>By confirming, you changes would be update directly.</Typography>
          </Grid>
        }
      />
      <ConfirmationModal
        width={500}
        showModal={showUpdateAmountDifference}
        setShowModal={setShowUpdateAmountDifference}
        onConfirm={() => formRef.current?.submitForm()}
        title="Update Confirmation"
        messageSection={
          <Grid item>
            <Grid container item justifyContent="space-between">
              <Typography>Total Paid:</Typography>
              <Typography>
                RM {sameDayOrderUpdatingObj?.payment_summary.paid.total_amount}
              </Typography>
            </Grid>
            <Grid container item justifyContent="space-between">
              <Typography>Order Price after update:</Typography>
              <Typography>RM {updateNewTotal.toFixed(2)}</Typography>
            </Grid>
            <Grid container item justifyContent="space-between">
              <Typography color="error">
                {updateAmountDifference > 0 ? "Outstanding" : "Excess"} Total:
              </Typography>
              <Typography color="error">
                RM {Math.abs(updateAmountDifference).toFixed(2)}
              </Typography>
            </Grid>

            <Spacer size="l" />
            <Typography textAlign="justify">
              By confirming, you changes would be update directly.
            </Typography>
            <Spacer />

            <Typography textAlign="justify" color="error" fontWeight="bold">
              *
              {updateAmountDifference > 0
                ? "Please ensure that any outstanding balance is paid to confirm the processing of your order. Thank you!"
                : "Any additional EXCESS payments cannot be refunded once processed."}
            </Typography>
          </Grid>
        }
      />
      <Grid item xs={12}>
        <StageProgressBar />
      </Grid>
      <Form
        initialValues={initialValues}
        onSubmit={submitSameDayOrderItem}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        <Grid item xs={12} marginTop="50px">
          <DeliveryOptionSection />
        </Grid>
        <Grid item xs={12}>
          <SelectPickUpTimeSection />
        </Grid>
        <Grid item xs={12}>
          <RemarkSection />
        </Grid>
        <Grid item xs={12}>
          <PickUpAddressSection />
        </Grid>
        <Grid item xs={12}>
          <SelectFoodSection foods={foods} />
        </Grid>
        <Grid item container justifyContent="center">
          {isUpdateOrder && orderObject?.payment_summary.paid.total_amount !== "0.00" ? (
            <Grid item xs={10} sm={4}>
              <BackgroundButton isLoading={isLoading} onClick={calculateOutstandingOrExcessTotal}>
                View Order Summary
              </BackgroundButton>
            </Grid>
          ) : (
            <Grid item xs={10} sm={4}>
              <FormSubmitButton isLoading={isLoading}>View Order Summary</FormSubmitButton>
            </Grid>
          )}
        </Grid>
      </Form>
    </Grid>
  );
};

export default SameDayOrderScreen;
