import { FormControlLabel, FormGroup } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import { FormikValues, useFormikContext } from "formik";
import { ChangeEvent } from "react";

interface Props {
  name: string;
  disabled?: boolean;
  handleChange?: (checked: boolean) => void;
  checked?: boolean;
}

declare module "@mui/material" {
  interface CheckboxPropsColorOverrides {
    white: true;
  }
}

function FormFoodCheckBox({ name, disabled, handleChange, checked }: Props) {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.checked);
  };

  return (
    <FormGroup sx={{ justifyContent: "center", display: "flex" }}>
      <FormControlLabel
        disabled={disabled}
        sx={{ justifyContent: "center", display: "flex", margin: 0 }}
        label=""
        name={name}
        control={
          <CheckBox
            color="white"
            style={{
              color: disabled ? "" : "white",
            }}
            checked={checked}
            onChange={(e) => (handleChange ? handleChange(e.target.checked) : handleOnChange(e))}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 35 } }}
          />
        }
      />
    </FormGroup>
  );
}

FormFoodCheckBox.defaultProps = {
  disabled: false,
};

export default FormFoodCheckBox;
