import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button, Divider, Grid, Link, styled, Typography, useMediaQuery } from "@mui/material";
import YiJiaLogo from "../../assets/images/YiJiaLogo.png";
import routes from "../../components/navigation/routes";
import theme from "../theme";

const StyledTitle = styled(Typography)({
  color: theme.palette.text.secondary,
  fontWeight: "bold",
});

const StyledText = styled(Typography)({
  color: theme.palette.text.secondary,
  textAlign: "start",
  "&:hover": {
    textDecoration: "underline",
  },
});

const MainContainer = styled(Box)(() => ({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "100%",
  marginTop: "auto",
}));

const BottomBanner = styled(Box)(() => ({
  background: theme.palette.primary.main,
  width: "100%",
  height: "5vh",
}));

const StyledLink = styled(Link)`
  text-decoration: none;
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

const StyledListBox = styled(Box)({
  paddingTop: "10px",
  display: "flex",
  flexDirection: "column",
  "& .MuiButton-root": {
    paddingLeft: 0,
    textTransform: "none",
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

function Footer() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <MainContainer paddingY="30px">
        <Box sx={{ maxWidth: theme.dimensions.PCMaxWidth, width: "100%" }}>
          <Grid
            container
            spacing={2}
            paddingX={theme.dimensions.ScreenPaddingX}
            justifyContent="center"
          >
            <Grid item xs={isMobile ? 6 : 3}>
              <StyledTitle variant="h5">About Us</StyledTitle>
              <StyledListBox>
                <StyledLink>
                  <Button disableRipple>
                    <StyledText>Our Story</StyledText>
                  </Button>
                </StyledLink>
              </StyledListBox>
            </Grid>
            <Grid item xs={isMobile ? 6 : 2}>
              <StyledTitle variant="h5">Others</StyledTitle>
              <StyledListBox>
                <StyledLink href={routes.DELIVERY_POLICY}>
                  <Button disableRipple>
                    <StyledText>Delivery Policy</StyledText>
                  </Button>
                </StyledLink>
                <StyledLink href={routes.PRIVACY_POLICY}>
                  <Button disableRipple>
                    <StyledText>Privacy Policy</StyledText>
                  </Button>
                </StyledLink>
                <StyledLink href={routes.FAQ}>
                  <Button disableRipple>
                    <StyledText>FAQ</StyledText>
                  </Button>
                </StyledLink>
                <StyledLink href={routes.TERMS}>
                  <Button disableRipple>
                    <StyledText>Terms</StyledText>
                  </Button>
                </StyledLink>
              </StyledListBox>
            </Grid>

            {!isMobile && (
              <Grid item xs={0.1} sx={{ marginRight: "30px" }}>
                <Divider orientation="vertical" />
              </Grid>
            )}

            <Grid item xs={isMobile ? 12 : 3}>
              <StyledTitle variant="h5">Address</StyledTitle>
              <Grid container item alignItems="center" paddingTop="10px" columnSpacing={2}>
                <Grid item xs={2}>
                  <LocationOnIcon sx={{ color: theme.palette.primary.main }} fontSize="large" />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="text.secondary">
                    77-G, Jalan Bandar 1, Pusat Bandar Puchong, 47100 Puchong, Selangor
                  </Typography>
                </Grid>
              </Grid>
              <StyledTitle variant="h5" paddingTop="10px">
                Contact
              </StyledTitle>
              <Grid container item alignItems="center" paddingTop="10px" columnSpacing={2}>
                <Grid item xs={2}>
                  <PhoneIcon sx={{ color: theme.palette.primary.main }} fontSize="large" />
                </Grid>
                <Grid item xs={10}>
                  <Link
                    href="tel:6019-9867798"
                    underline="hover"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +6019-986 7798
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={isMobile ? 8 : 3} alignSelf="center">
              <StyledLink href={routes.HOME}>
                <Box width="100%">
                  <img src={YiJiaLogo} alt={YiJiaLogo} width="100%" />
                </Box>
              </StyledLink>
            </Grid>

            <Grid item xs={12}>
              <Typography
                textAlign="center"
                color="text.secondary"
                fontSize="12px"
                paddingTop="30px"
              >
                Copyright@{new Date().getFullYear()} YIJIA FOOD ENTERPRISE (003118012-P)
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </MainContainer>
      <BottomBanner />
    </>
  );
}

export default Footer;
