import { Grid, Typography, useMediaQuery } from "@mui/material";
import ValuesBox from "./values-box.component";
import FlexibleMealPlan from "../../../assets/images/flexible-meal-plan.png";
import Menu from "../../../assets/images/menu.png";
import Vegetables from "../../../assets/images/vegetables.png";
import Wok from "../../../assets/images/wok.png";
import theme from "../../../infrastructure/theme";
import ItemCarousel from "../../../components/utils/item-carousel.component";

function OurValuesSection() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container spacing={isTablet ? 2 : 4} paddingTop="50px">
      <Grid item xs={12}>
        <Typography
          variant="h4"
          fontWeight="bold"
          color="text.secondary"
          sx={{ textAlign: "center" }}
        >
          Our Values
        </Typography>
      </Grid>
      {isMobile ? (
        <Grid item xs={12}>
          <ItemCarousel slidesToShow={1.5} slidesToScroll={1}>
            <ValuesBox
              image={Vegetables}
              content={
                <Typography color="text.secondary" display="inline">
                  <span style={{ fontWeight: "bold" }}>Fresh Ingredients</span> From Local Farms.
                </Typography>
              }
            />
            <ValuesBox
              image={Wok}
              content={
                <Typography color="text.secondary" display="inline">
                  Cook Fresh, <span style={{ fontWeight: "bold" }}>DAILY</span>.
                </Typography>
              }
            />
            <ValuesBox
              image={Menu}
              content={
                <Typography color="text.secondary" display="inline">
                  <span style={{ fontWeight: "bold" }}>Weekly</span> Rotating Menu
                </Typography>
              }
            />
            <ValuesBox
              image={FlexibleMealPlan}
              content={
                <Typography color="text.secondary" display="inline">
                  <span style={{ fontWeight: "bold" }}>Flexible</span> Meal Plan & Selection
                </Typography>
              }
            />
          </ItemCarousel>
        </Grid>
      ) : (
        <>
          <Grid item xs={isMobile ? 6 : 3}>
            <ValuesBox
              image={Vegetables}
              content={
                <Typography color="text.secondary" display="inline">
                  <span style={{ fontWeight: "bold" }}>Fresh Ingredients</span> From Local Farms.
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <ValuesBox
              image={Wok}
              content={
                <Typography color="text.secondary" display="inline">
                  Cook Fresh, <span style={{ fontWeight: "bold" }}>DAILY</span>.
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <ValuesBox
              image={Menu}
              content={
                <Typography color="text.secondary" display="inline">
                  <span style={{ fontWeight: "bold" }}>Weekly</span> Rotating Menu
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <ValuesBox
              image={FlexibleMealPlan}
              content={
                <Typography color="text.secondary" display="inline">
                  <span style={{ fontWeight: "bold" }}>Flexible</span> Meal Plan & Selection
                </Typography>
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default OurValuesSection;
