import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ReactComponent as OrderPendingLogo } from "../../../assets/images/order-pending.svg";
import { ReactComponent as OrderPaymentLogo } from "../../../assets/images/order-payment.svg";
import { Box, Grid, Modal, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackgroundButton from "../../../components/button/background-button.component";
import BorderButton from "../../../components/button/border-button.component";
import routes from "../../../components/navigation/routes";
import ConfirmationModal from "../../../components/notification/confirmation-modal.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import theme from "../../../infrastructure/theme";
import {
  orderSelector,
  setPreorderUpdating,
  setSameDayOrderUpdating,
} from "../../../services/order/order-slice.service";
import { useAppDispatch, useAppSelector } from "../../../services/store";
import { PreorderResource, SameDayOrderResource } from "../../order/type/type";
import PreorderDetail from "./preorder-detail.component";
import SameDayOrderDetail from "./same-day-order-detail.component";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  outline: "none",
  maxHeight: "98%",
  maxWidth: "1300px",
});

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  itemDetails: PreorderResource | SameDayOrderResource | null;
  payPendingOrder: (order_id: number) => void;
}

export default function OrderDetailModal({
  showModal,
  setShowModal,
  itemDetails,
  payPendingOrder,
}: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { payOrderObj } = useAppSelector(orderSelector);
  const [showSameDayUpdateWarning, setShowSameDayUpdateWarning] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
  };

  const onUpdate = () => {
    if (itemDetails?.type === "preorder") {
      dispatch(setPreorderUpdating(itemDetails));
      navigate(routes.PREORDER_ITEM_EDIT + `?orderId=${itemDetails?.id}`);
    } else {
      dispatch(setSameDayOrderUpdating(itemDetails));
      navigate(routes.SAME_DAY_ORDER_EDIT + `?orderId=${itemDetails?.id}`);
    }
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "100%" : "90%" }}>
        <PaddedView multiples={1.5}>
          {itemDetails && (
            <Grid container>
              <ConfirmationModal
                width={550}
                showModal={showSameDayUpdateWarning}
                setShowModal={setShowSameDayUpdateWarning}
                onConfirm={onUpdate}
                title="Update Order"
                messageSection={
                  <Grid item>
                    <Typography textAlign="justify">
                      Updating a paid order may generate outstanding amount. Please clear any
                      outstanding payment directly after the update to confirm your order.
                    </Typography>
                    <Spacer size="l" />
                    <Typography color="error" fontWeight="bold">
                      *Orders must be confirmed and paid to be processed. If you have any questions
                      or concerns, feel free to get in touch with us—we&apos;re here to assist you!
                    </Typography>
                  </Grid>
                }
                buttonText="I Understand"
              />
              <Grid item xs={12}>
                <TopTitleBox
                  title="Order Details"
                  requireCloseButton={true}
                  setShowModal={setShowModal}
                />
                <Spacer size="m" />
              </Grid>
              {itemDetails.status === "completed" && (
                <Grid
                  item
                  container
                  columnSpacing={1}
                  display="flex"
                  alignItems="center"
                  xs={12}
                  sx={{
                    backgroundColor: "success.main",
                    padding: "10px",
                    paddingX: "20px",
                    borderRadius: theme.shape.borderSizes[1],
                  }}
                >
                  <Grid item>
                    <Typography variant="h6" fontWeight="bold" color="text.white">
                      Order Completed
                    </Typography>
                  </Grid>
                  <Grid item display="flex" alignItems="center">
                    <CheckCircleOutlineIcon sx={{ color: "#fff", fontSize: "35px" }} />
                  </Grid>
                </Grid>
              )}
              {itemDetails.status === "ongoing" && !itemDetails.payment_summary.require_payment && (
                <Grid
                  item
                  container
                  columnSpacing={1}
                  display="flex"
                  alignItems="center"
                  xs={12}
                  sx={{
                    backgroundColor: "primary.main",
                    padding: "10px",
                    paddingX: "20px",
                    borderRadius: theme.shape.borderSizes[1],
                  }}
                >
                  <Grid item>
                    <Typography variant="h6" fontWeight="bold" color="text.white">
                      Order Ongoing
                    </Typography>
                  </Grid>
                  <Grid item display="flex" alignItems="center">
                    <OrderPendingLogo width={35} height={35} />
                  </Grid>
                </Grid>
              )}
              {itemDetails.status === "expired" && (
                <Grid
                  item
                  container
                  columnSpacing={1}
                  display="flex"
                  alignItems="center"
                  xs={12}
                  sx={{
                    backgroundColor: "error.main",
                    padding: "10px",
                    paddingX: "20px",
                    borderRadius: theme.shape.borderSizes[1],
                  }}
                >
                  <Grid item>
                    <Typography variant="h6" fontWeight="bold" color="text.white">
                      Order Expired
                    </Typography>
                  </Grid>
                  <Grid item display="flex" alignItems="center">
                    <OrderPendingLogo width={35} height={35} />
                  </Grid>
                </Grid>
              )}
              {itemDetails.payment_summary.require_payment && (
                <Grid
                  item
                  container
                  columnSpacing={1}
                  display="flex"
                  alignItems="center"
                  xs={12}
                  sx={{
                    backgroundColor: "error.main",
                    padding: "10px",
                    paddingX: "20px",
                    borderRadius: theme.shape.borderSizes[1],
                  }}
                >
                  <Grid item>
                    <Typography variant="h6" fontWeight="bold" color="text.white">
                      Pending Payment
                    </Typography>
                  </Grid>
                  <Grid item display="flex" alignItems="center">
                    <OrderPaymentLogo width={30} height={30} />
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} sx={{ height: "420px", overflowY: "auto" }}>
                <PaddedView multiples={1.5}>
                  <Grid container spacing={1}>
                    {itemDetails.type === "same_day" ? (
                      <SameDayOrderDetail itemDetails={itemDetails} showAllInformation />
                    ) : (
                      <PreorderDetail itemDetails={itemDetails} showAllInformation />
                    )}
                  </Grid>
                </PaddedView>
              </Grid>

              <Grid container item spacing={2} marginTop="1px">
                <Grid item xs={6}>
                  {itemDetails.type === "preorder" ? (
                    <BorderButton
                      disabled={
                        itemDetails.status === "completed" || itemDetails.status === "expired"
                      }
                      onClick={onUpdate}
                    >
                      Edit Meal
                    </BorderButton>
                  ) : (
                    <BorderButton
                      disabled={!itemDetails.item?.editable}
                      onClick={() => {
                        if (itemDetails.payment_summary.paid.total_amount !== "0.00") {
                          setShowSameDayUpdateWarning(true);
                        } else {
                          onUpdate();
                        }
                      }}
                    >
                      Update
                    </BorderButton>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <BackgroundButton
                    disabled={!itemDetails.payment_summary.require_payment}
                    isLoading={payOrderObj.status === "pending"}
                    onClick={() => payPendingOrder(itemDetails.id)}
                  >
                    Pay
                  </BackgroundButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </PaddedView>
      </ModalBox>
    </Modal>
  );
}

OrderDetailModal.defaultProps = {
  title: "",
  buttonText: "Confirm",
};
