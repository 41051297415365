import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, postReq, putReq } from "../../../api.services";
import { idleObject } from "../../../idle-object";
import { RequestObject, RootState } from "../../../store";

export const createPreorderItem = createAsyncThunk(
  "order/pre_orders/items",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/orders/pre_orders/items`,
      payload,
    );
    return response;
  },
);

export const updatePreorderItem = createAsyncThunk(
  "orders/pre_orders/items/{id}/update",
  async (payload: any) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/orders/pre_orders/items/${payload.order_item_id}`,
      payload,
    );
    return response;
  },
);

export const deletePreorderItem = createAsyncThunk(
  "orders/pre_orders/items/{id}/delete",
  async (payload: any) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/orders/pre_orders/items/${payload}`,
    );
    return response;
  },
);

interface initialStateType {
  createPreorderItemObj: RequestObject;
  updatePreorderItemObj: RequestObject;
  deletePreorderItemObj: RequestObject;
}

const initialState: initialStateType = {
  createPreorderItemObj: idleObject(),
  updatePreorderItemObj: idleObject(),
  deletePreorderItemObj: idleObject(),
};

const preorderItemSlice = createSlice({
  name: "preorderItem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPreorderItem.pending, (state) => {
        state.createPreorderItemObj.status = "pending";
      })
      .addCase(createPreorderItem.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createPreorderItemObj.status = "succeeded";
        state.createPreorderItemObj.data = data;
        state.createPreorderItemObj.successMessage = message;
      })
      .addCase(createPreorderItem.rejected, (state, action) => {
        const { message } = action.error;

        state.createPreorderItemObj.status = "failed";
        state.createPreorderItemObj.errorMessage = message;
      })
      .addCase(updatePreorderItem.pending, (state) => {
        state.updatePreorderItemObj.status = "pending";
      })
      .addCase(updatePreorderItem.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updatePreorderItemObj.status = "succeeded";
        state.updatePreorderItemObj.data = data;
        state.updatePreorderItemObj.successMessage = message;
      })
      .addCase(updatePreorderItem.rejected, (state, action) => {
        const { message } = action.error;

        state.updatePreorderItemObj.status = "failed";
        state.updatePreorderItemObj.errorMessage = message;
      })
      .addCase(deletePreorderItem.pending, (state) => {
        state.deletePreorderItemObj.status = "pending";
      })
      .addCase(deletePreorderItem.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.deletePreorderItemObj.status = "succeeded";
        state.deletePreorderItemObj.data = data;
        state.deletePreorderItemObj.successMessage = message;
      })
      .addCase(deletePreorderItem.rejected, (state, action) => {
        const { message } = action.error;

        state.deletePreorderItemObj.status = "failed";
        state.deletePreorderItemObj.errorMessage = message;
      });
  },
});

export default preorderItemSlice.reducer;

// state
export const preorderItemSelector = (state: RootState) => state.preorderItem;
