import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BackgroundButton from "../../../../components/button/background-button.component";
import BorderButton from "../../../../components/button/border-button.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import routes from "../../../../components/navigation/routes";
import Loading from "../../../../components/notification/backdrop-loading.component";
import ConfirmationModal from "../../../../components/notification/confirmation-modal.component";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import Spacer from "../../../../components/utils/spacer.component";
import {
  getOrder,
  orderSelector,
  resetPreorderCreated,
  setPreorderCreated,
  setPreorderUpdating,
} from "../../../../services/order/order-slice.service";
import {
  createPreorderItem,
  deletePreorderItem,
  updatePreorderItem,
} from "../../../../services/order/preorder/order-item/preorder-item.slice";
import { useAppDispatch, useAppSelector } from "../../../../services/store";
import DeliveryOptionSection from "../../components/delivery-option-section.component";
import RemarkSection from "../../components/remark-section.component";
import SelectFoodSection from "../../components/select-food-section.component";
import SelectPickUpTimeSection from "../../components/select-pick-up-time-section.component";
import StageProgressBar from "../../components/stage-progress-bar.component";
import { OrderFoods, PreorderResource } from "../../type/type";
import PreorderMealDateCarouselSection from "../components/preorder-meal-date-carousel-section.component";
import SelectAddressSection from "../components/select-address-section.component";
import { PreorderItemFormValues } from "../type/type";

const PreorderItemScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const snackBar = useSnackBar();
  const location = useLocation();
  const formRef = useRef<FormikProps<PreorderItemFormValues>>(null);
  const { preorderCreatedObj, preorderUpdatingObj } = useAppSelector(orderSelector);
  const isUpdateOrder = location.pathname === routes.PREORDER_ITEM_EDIT;
  const orderObject = isUpdateOrder ? preorderUpdatingObj : preorderCreatedObj;
  const [showUpdateAmountDifference, setShowUpdateAmountDifference] = useState<boolean>(false);
  const [showDeleteItemConfirmation, setShowDeleteItemConfirmation] = useState<boolean>(false);
  const [updateAmountDifference, setUpdateAmountDifference] = useState(0);
  const [updateNewTotal, setUpdateNewTotal] = useState(0);
  const [totalCarb, setTotalCarb] = useState(0);
  const [totalAddon, setTotalAddon] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [dateFoods, setDateFoods] = useState<OrderFoods | null>(
    orderObject
      ? Object.assign(
          {},
          ...orderObject.food_set.foods.map((item) => ({
            [item.type]: item.items,
          })),
        )
      : null,
  );

  const incompleteMealIndex = orderObject
    ? orderObject.items.map((item) => item.confirmed).indexOf(false) < 0
      ? orderObject.items.length
      : orderObject.items.map((item) => item.confirmed).indexOf(false)
    : 0;

  const rules = orderObject
    ? Object.assign(
        {
          total_vege_side_combination: orderObject.food_set.total_vege_side_combination,
        },
        ...orderObject.food_set.foods.map(
          (item: {
            type: "meat" | "vege" | "side" | "carb" | "addon";
            min: number;
            max: number;
          }) => ({
            [item.type]: {
              min: item.min,
              max: item.max,
            },
          }),
        ),
      )
    : null;

  const validationSchema = orderObject
    ? Yup.object().shape({
        date: Yup.date().required().label("Date"),
        type: Yup.string().required().label("Type"),
        pickUpTime: Yup.string()
          .required()
          .label("Pick Up Time")
          .when("type", {
            is: "delivery",
            then: (rule) => rule.label("Delivery Time"),
          }),
        address_id: Yup.object()
          .shape({
            id: Yup.number().required(),
            option: Yup.string().required(),
          })
          .nullable()
          .test("address id", `Address is required for delivery`, (value, context) => {
            if (context.parent.type === "delivery" && !value) {
              return false;
            }
            return true;
          }),
        meat: Yup.array()
          .of(Yup.number())
          .min(rules ? rules.meat.min : undefined)
          .max(rules ? rules.meat.max : undefined),
        vege: Yup.array()
          .of(Yup.number())
          .test("vege selections", `Select maximum ${rules.vege.max} vege`, (value, context) => {
            if (rules.vege.min === 0) {
              if (
                value?.length + context.parent.side.length !==
                rules.total_vege_side_combination
              ) {
                return false;
              }
            } else {
              if (value?.length !== rules.vege.max) {
                return false;
              }
            }

            return true;
          }),
        side: Yup.array()
          .of(Yup.number())
          .test("side selections", `Select maximum ${rules.side.max} side`, (value, context) => {
            if (rules.side.min === 0) {
              if (
                value?.length + context.parent.vege.length !==
                rules.total_vege_side_combination
              ) {
                return false;
              }
            } else {
              if (value?.length !== rules.side.max) {
                return false;
              }
            }

            return true;
          }),
        carb: Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.number(),
              no_of_pax: Yup.number(),
            }),
          )
          .min(rules && rules.carb.min !== 0 ? rules.carb.min : 0)
          .max(rules && rules.carb.max !== 0 ? rules.carb.max : 99),
        addon: Yup.array().of(
          Yup.object().shape({
            id: Yup.number(),
            no_of_pax: Yup.number(),
          }),
        ),
      })
    : null;

  const initialValues: PreorderItemFormValues = {
    currentMealIndex: incompleteMealIndex + 1,
    currentMealEditable:
      orderObject && orderObject.items.length >= 1 && orderObject.items[incompleteMealIndex]
        ? orderObject.items[incompleteMealIndex].editable
        : true,
    type:
      orderObject && orderObject.items.length >= 1 && orderObject.items[incompleteMealIndex]
        ? orderObject.items[incompleteMealIndex].type
        : "delivery",
    date:
      orderObject && orderObject.items.length >= 1 && orderObject.items[incompleteMealIndex]
        ? dayjs(orderObject.items[incompleteMealIndex].date_time.slice(0, 10))
        : null,
    pickUpTime:
      orderObject && orderObject.items.length >= 1 && orderObject.items[incompleteMealIndex]
        ? orderObject.items[incompleteMealIndex].date_time.slice(-8)
        : "",
    remark:
      orderObject &&
      orderObject.items.length >= 1 &&
      orderObject.items[incompleteMealIndex] &&
      orderObject.items[incompleteMealIndex].remark
        ? orderObject.items[incompleteMealIndex].remark
        : "",
    meat:
      orderObject && orderObject.items.length >= 1 && orderObject.items[incompleteMealIndex]
        ? orderObject.items[incompleteMealIndex].foods.meat.map((item) => item.id)
        : [],
    vege:
      orderObject && orderObject.items.length >= 1 && orderObject.items[incompleteMealIndex]
        ? orderObject.items[incompleteMealIndex].foods.vege.map((item) => item.id)
        : [],
    side:
      orderObject && orderObject.items.length >= 1 && orderObject.items[incompleteMealIndex]
        ? orderObject.items[incompleteMealIndex].foods.side.map((item) => item.id)
        : [],
    carb:
      orderObject && orderObject.items.length >= 1 && orderObject.items[incompleteMealIndex]
        ? orderObject.items[incompleteMealIndex].foods.carb
        : [],
    addon:
      orderObject && orderObject.items.length >= 1 && orderObject.items[incompleteMealIndex]
        ? orderObject.items[incompleteMealIndex].foods.addon
        : [],
    address_id:
      orderObject &&
      orderObject.items.length >= 1 &&
      orderObject.items[incompleteMealIndex] &&
      orderObject.items[incompleteMealIndex].delivery
        ? {
            id: orderObject.items[incompleteMealIndex].delivery.address.id,
            option: orderObject.items[incompleteMealIndex].delivery.address.location,
          }
        : null,
    deliveryFee:
      orderObject &&
      orderObject.items.length >= 1 &&
      orderObject.items[incompleteMealIndex] &&
      orderObject.items[incompleteMealIndex].delivery
        ? parseFloat(
            orderObject.items[incompleteMealIndex].delivery?.polygon.fee.replaceAll(",", ""),
          )
        : 0,
    deliveryAddressLatLong:
      orderObject &&
      orderObject.items.length >= 1 &&
      orderObject.items[incompleteMealIndex] &&
      orderObject.items[incompleteMealIndex].delivery
        ? `${orderObject.items[incompleteMealIndex].delivery.address.lat}${orderObject.items[incompleteMealIndex].delivery.address.long}`
        : "",
  };

  const getFoodByDateFromOrder = (date: any) => {
    setDateFoods(null);
    if (date) {
      dispatch(
        getOrder({
          id: orderObject?.id,
          calendarDate: dayjs(date).format("YYYY-MM-DD"),
        }),
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          const payloadData: PreorderResource = payload.data;
          if (isUpdateOrder) {
            setPreorderUpdating(payloadData);
          } else {
            setPreorderCreated(payloadData);
          }
          setDateFoods(
            Object.assign(
              {},
              ...payloadData.food_set.foods.map((item) => ({
                [item.type]: item.items,
              })),
            ),
          );
        }
      });
    }
  };

  useEffect(() => {
    if (!orderObject) {
      navigate(routes.PREORDER_MEAL_PLAN);
    } else {
      if (orderObject.items.length >= 1) {
        getFoodByDateFromOrder(orderObject.items[0].date_time);
      }
    }
  }, []);

  const resetPreorderForm = () => {
    formRef?.current?.setFieldValue("type", "delivery");
    formRef?.current?.setFieldValue("date", null);
    formRef?.current?.setFieldValue("remark", "");
    formRef?.current?.setFieldValue("address_id", null);
    formRef?.current?.setFieldValue("pickUpTime", "");
    formRef?.current?.setFieldValue("meat", []);
    formRef?.current?.setFieldValue("vege", []);
    formRef?.current?.setFieldValue("side", []);
    formRef?.current?.setFieldValue("carb", []);
    formRef?.current?.setFieldValue("addon", []);
    formRef?.current?.setFieldValue("currentMealEditable", true);
    formRef?.current?.setFieldValue("deliveryFee", 0);
    formRef?.current?.setFieldValue("deliveryAddressLatLong", "");
  };

  const submitPreorderItem = (values: PreorderItemFormValues) => {
    setIsLoading(true);
    setShowUpdateAmountDifference(false);
    const payload = {
      order_id: orderObject?.id,
      order_item_id: orderObject?.items[values.currentMealIndex - 1]?.id,
      date_time: dayjs(values.date).format("YYYY-MM-DD ") + values.pickUpTime,
      type: values.type,
      remark: values.remark,
      address_id: values.type === "delivery" ? values.address_id?.id : null,
      meat_food_ids: values.meat,
      vege_food_ids: values.vege,
      side_food_ids: values.side,
      carb_food_ids: values.carb,
      addon_food_ids: values.addon,
    };

    if (payload.order_item_id) {
      dispatch(updatePreorderItem(payload))
        .unwrap()
        .then((res) => {
          if (res.success) {
            setIsLoading(false);
            if (isUpdateOrder) {
              dispatch(setPreorderUpdating(res.data));
            } else {
              dispatch(setPreorderCreated(res.data));
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          snackBar.createSnackBar({
            message: err.message,
            type: "error",
            open: true,
          });
        });
    } else {
      dispatch(createPreorderItem(payload))
        .unwrap()
        .then((res) => {
          if (res.success) {
            setIsLoading(false);
            if (isUpdateOrder) {
              dispatch(setPreorderUpdating(res.data));
            } else {
              dispatch(setPreorderCreated(res.data));
            }
            resetPreorderForm();
            formRef.current?.setFieldValue("currentMealIndex", values.currentMealIndex + 1);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          snackBar.createSnackBar({
            message: err.message,
            type: "error",
            open: true,
          });
        });
    }
  };

  const resetSetSelection = () => {
    dispatch(resetPreorderCreated());
    navigate(routes.PREORDER_MEAL_PLAN);
  };

  const deleteCurrentOrderItem = () => {
    setShowDeleteItemConfirmation(false);
    setIsLoading(true);
    if (orderObject && formRef && formRef.current) {
      dispatch(
        deletePreorderItem(orderObject.items[formRef.current.values.currentMealIndex - 1]?.id),
      )
        .unwrap()
        .then((res) => {
          if (res.success) {
            setIsLoading(false);
            if (isUpdateOrder) {
              dispatch(setPreorderUpdating(res.data));
            } else {
              dispatch(setPreorderCreated(res.data));
            }
            if (formRef && formRef.current) {
              const responseData: PreorderResource = res.data;
              const latestIncompleteMealIndex = responseData
                ? responseData.items.map((item) => item.confirmed).indexOf(false) < 0
                  ? responseData.items.length
                  : responseData.items.map((item) => item.confirmed).indexOf(false)
                : 0;
              if (
                responseData.items.length === 0 ||
                !responseData.items[latestIncompleteMealIndex]
              ) {
                resetPreorderForm();
              } else {
                const createdItem = responseData.items[latestIncompleteMealIndex];
                formRef.current.setFieldValue("type", createdItem.type);
                formRef.current.setFieldValue("date", dayjs(createdItem.date_time.slice(0, 10)));
                formRef.current.setFieldValue("pickUpTime", createdItem.date_time.slice(-8));
                formRef.current.setFieldValue(
                  "remark",
                  createdItem.remark ? createdItem.remark : "",
                );
                formRef.current.setFieldValue(
                  "meat",
                  createdItem.foods.meat.map((item) => item.id),
                );
                formRef.current.setFieldValue(
                  "vege",
                  createdItem.foods.vege.map((item) => item.id),
                );
                formRef.current.setFieldValue(
                  "side",
                  createdItem.foods.side.map((item) => item.id),
                );
                formRef.current.setFieldValue("carb", createdItem.foods.carb);
                formRef.current.setFieldValue("addon", createdItem.foods.addon);
                formRef.current.setFieldValue(
                  "address_id",
                  createdItem.delivery
                    ? {
                        id: createdItem.delivery.address.id,
                        option: createdItem.delivery.address.location,
                      }
                    : null,
                );
                formRef.current.setFieldValue(
                  "deliveryFee",
                  createdItem.delivery
                    ? parseFloat(createdItem.delivery.polygon.fee.replaceAll(",", ""))
                    : 0,
                );
                formRef.current.setFieldValue(
                  "deliveryAddressLatLong",
                  createdItem.delivery
                    ? `${createdItem.delivery.address.lat}${createdItem.delivery.address.long}`
                    : "",
                );

                formRef.current.setFieldValue("currentMealEditable", createdItem.editable);
                getFoodByDateFromOrder(dayjs(createdItem.date_time.slice(0, 10)));
              }

              formRef.current.setFieldValue("currentMealIndex", latestIncompleteMealIndex + 1);
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          snackBar.createSnackBar({
            message: err.message,
            type: "error",
            open: true,
          });
        });
    }
  };

  const calculateOutstandingOrExcessTotal = () => {
    if (isUpdateOrder && preorderUpdatingObj) {
      const paidTotal = parseFloat(
        preorderUpdatingObj.payment_summary.paid.total_amount.replaceAll(",", ""),
      );

      if (formRef && formRef.current && paidTotal > 0) {
        let originalCarbTotal = 0;
        let updatedCarbTotal = 0;
        let originalAddonTotal = 0;
        let updatedAddonTotal = 0;
        let originalDeliveryFee = 0;
        let originalAddressLatLong = "";

        const currentMealItem =
          preorderUpdatingObj.items[formRef.current.values.currentMealIndex - 1];
        if (currentMealItem) {
          originalCarbTotal = currentMealItem.foods.carb.reduce((accumulator, currentValue) => {
            const itemInFood = dateFoods?.carb.find((food) => food.id === currentValue.id);
            if (dateFoods && itemInFood && itemInFood.price && currentValue.no_of_pax) {
              return (
                accumulator +
                currentValue.no_of_pax * parseFloat(itemInFood.price.replaceAll(",", ""))
              );
            }

            return accumulator;
          }, originalCarbTotal);

          originalAddonTotal = currentMealItem.foods.addon.reduce((accumulator, currentValue) => {
            const itemInFood = dateFoods?.addon.find((food) => food.id === currentValue.id);
            if (dateFoods && itemInFood && itemInFood.price && currentValue.no_of_pax) {
              return (
                accumulator +
                currentValue.no_of_pax * parseFloat(itemInFood.price.replaceAll(",", ""))
              );
            }

            return accumulator;
          }, originalAddonTotal);

          originalDeliveryFee = currentMealItem.delivery
            ? parseFloat(currentMealItem.delivery.polygon.fee.replaceAll(",", ""))
            : 0;

          const original_charge_before = preorderUpdatingObj.items.some(
            (item) =>
              item.id !== currentMealItem.id &&
              item.date_time === currentMealItem.date_time &&
              `${item.delivery?.address.lat}${item.delivery?.address.long}` ===
                `${currentMealItem.delivery?.address.lat}${currentMealItem.delivery?.address.long}`,
          );

          if (original_charge_before) {
            originalDeliveryFee = 0;
          }

          originalAddressLatLong = `${currentMealItem.delivery?.address.lat}${currentMealItem.delivery?.address.long}`;
        }

        updatedCarbTotal = formRef.current?.values.carb.reduce((accumulator, currentValue) => {
          const itemInFood = dateFoods?.carb.find((food) => food.id === currentValue.id);
          if (dateFoods && itemInFood && itemInFood.price && currentValue.no_of_pax) {
            return (
              accumulator +
              currentValue.no_of_pax * parseFloat(itemInFood.price.replaceAll(",", ""))
            );
          }

          return accumulator;
        }, updatedCarbTotal);

        updatedAddonTotal = formRef.current?.values.addon.reduce((accumulator, currentValue) => {
          const itemInFood = dateFoods?.addon.find((food) => food.id === currentValue.id);
          if (dateFoods && itemInFood && itemInFood.price && currentValue.no_of_pax) {
            return (
              accumulator +
              currentValue.no_of_pax * parseFloat(itemInFood.price.replaceAll(",", ""))
            );
          }

          return accumulator;
        }, updatedAddonTotal);

        let deliveryTotal = preorderUpdatingObj.payment_summary.delivery
          ? parseFloat(preorderUpdatingObj.payment_summary.delivery.replaceAll(",", ""))
          : 0;

        const addressChanged =
          currentMealItem &&
          originalAddressLatLong !== formRef?.current?.values.deliveryAddressLatLong;

        const charge_before = preorderUpdatingObj.items.some(
          (item) =>
            item.date_time ===
              dayjs(formRef?.current?.values.date).format("YYYY-MM-DD ") +
                formRef?.current?.values.pickUpTime &&
            `${item.delivery?.address.lat}${item.delivery?.address.long}` ===
              formRef?.current?.values.deliveryAddressLatLong,
        );

        let chargeable_after_update = 0;

        if (!charge_before) {
          if (addressChanged) {
            chargeable_after_update = formRef.current.values.deliveryFee - originalDeliveryFee;
          } else {
            chargeable_after_update = formRef.current.values.deliveryFee;
          }
        } else {
          if (addressChanged) {
            chargeable_after_update = 0 - originalDeliveryFee;
          }
        }

        deliveryTotal = deliveryTotal + chargeable_after_update;

        const newCarbTotal =
          parseFloat(preorderUpdatingObj.payment_summary.carb.replaceAll(",", "")) +
          (updatedCarbTotal - originalCarbTotal);
        const newAddonTotal =
          parseFloat(preorderUpdatingObj.payment_summary.addons.replaceAll(",", "")) +
          (updatedAddonTotal - originalAddonTotal);

        const newTotal =
          parseFloat(preorderUpdatingObj.payment_summary.subtotal.replaceAll(",", "")) +
          deliveryTotal +
          newCarbTotal +
          newAddonTotal;
        const difference = newTotal - paidTotal;
        setDeliveryFee(deliveryTotal);
        setUpdateNewTotal(newTotal);
        setUpdateAmountDifference(difference);
        setTotalCarb(newCarbTotal);
        setTotalAddon(newAddonTotal);
        setShowUpdateAmountDifference(true);
      }

      return 0;
    }
  };

  return (
    orderObject && (
      <Grid container display="flex" flexDirection="column" rowSpacing={4}>
        <ConfirmationModal
          width={500}
          showModal={showUpdateAmountDifference}
          setShowModal={setShowUpdateAmountDifference}
          onConfirm={() => formRef.current?.submitForm()}
          title="Update Confirmation"
          messageSection={
            <Grid item>
              <Grid container item justifyContent="space-between">
                <Typography>Subtotal:</Typography>
                <Typography>RM {preorderUpdatingObj?.payment_summary.subtotal}</Typography>
              </Grid>
              <Grid container item justifyContent="space-between">
                <Typography>Total Carb:</Typography>
                <Typography>RM {totalCarb.toFixed(2)}</Typography>
              </Grid>
              <Grid container item justifyContent="space-between">
                <Typography>Total Add On:</Typography>
                <Typography>RM {totalAddon.toFixed(2)}</Typography>
              </Grid>
              <Grid container item justifyContent="space-between">
                <Typography>Total:</Typography>
                <Typography>RM {updateNewTotal.toFixed(2)}</Typography>
              </Grid>
              <Grid container item justifyContent="space-between">
                <Typography>Total Paid:</Typography>
                <Typography>RM {preorderUpdatingObj?.payment_summary.paid.total_amount}</Typography>
              </Grid>
              <Grid container item justifyContent="space-between">
                <Typography>Delivery Fee:</Typography>
                <Typography>RM {deliveryFee.toFixed(2)}</Typography>
              </Grid>
              <Grid container item justifyContent="space-between">
                <Typography color="error">
                  {updateAmountDifference > 0 ? "Outstanding" : "Excess"} Total:
                </Typography>
                <Typography color="error">
                  RM {Math.abs(updateAmountDifference).toFixed(2)}
                </Typography>
              </Grid>
              <Spacer size="l" />
              <Typography textAlign="justify">
                By confirming, you changes would be update directly.
              </Typography>
              <Spacer />

              {updateAmountDifference !== 0 && (
                <Typography textAlign="justify" color="error" fontWeight="bold">
                  *
                  {updateAmountDifference > 0
                    ? "Please ensure that any outstanding balance is paid to confirm the processing of your order. Thank you!"
                    : "Any additional EXCESS payments cannot be refunded once processed."}
                </Typography>
              )}
            </Grid>
          }
        />
        <ConfirmationModal
          showModal={showModal}
          setShowModal={setShowModal}
          onConfirm={resetSetSelection}
          title="Select Another Set"
          message="By confirming, you would lose all your current selection."
        />
        <ConfirmationModal
          showModal={showDeleteItemConfirmation}
          setShowModal={setShowDeleteItemConfirmation}
          onConfirm={deleteCurrentOrderItem}
          title="Delete Current Item?"
          message="Are you sure to delete current order item?"
        />
        <Loading isLoading={isLoading} />
        <Grid item xs={12} marginBottom="50px">
          <StageProgressBar />
        </Grid>
        {!isUpdateOrder && (
          <Grid item>
            <Grid item sx={{ cursor: "pointer" }} onClick={() => setShowModal(true)}>
              <Typography fontWeight="bold" color="error">
                {"<"} Select Another Set
              </Typography>
            </Grid>
          </Grid>
        )}

        <Form
          initialValues={initialValues}
          onSubmit={submitPreorderItem}
          validationSchema={validationSchema}
          innerRef={formRef}
          setIsFormValid={setIsFormValid}
        >
          <Grid item xs={12}>
            <DeliveryOptionSection />
          </Grid>
          <Grid item xs={12}>
            <PreorderMealDateCarouselSection
              resetPreorderForm={resetPreorderForm}
              getFoodByDateFromOrder={getFoodByDateFromOrder}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectPickUpTimeSection getFoodByDateFromOrder={getFoodByDateFromOrder} />
          </Grid>
          <Grid item xs={12}>
            <RemarkSection />
          </Grid>
          <Grid item xs={12}>
            <SelectAddressSection />
          </Grid>
          <Grid item xs={12}>
            <SelectFoodSection foods={dateFoods} rules={rules} />
          </Grid>
          <Grid item container justifyContent="center" spacing={2}>
            {orderObject &&
              formRef &&
              formRef.current &&
              orderObject.items[formRef.current.values.currentMealIndex - 1] &&
              !orderObject.items[formRef.current.values.currentMealIndex - 1].confirmed && (
                <Grid item xs={isMobile ? 12 : 4}>
                  <BorderButton
                    error
                    isLoading={isLoading}
                    onClick={() => setShowDeleteItemConfirmation(true)}
                  >
                    Delete Current Order Item
                  </BorderButton>
                </Grid>
              )}

            {isUpdateOrder && orderObject?.payment_summary.paid.total_amount !== "0.00" ? (
              <Grid item xs={isMobile ? 12 : 4}>
                <BorderButton
                  isLoading={isLoading}
                  onClick={calculateOutstandingOrExcessTotal}
                  disabled={!formRef?.current?.values.currentMealEditable || !isFormValid}
                >
                  Confirm
                </BorderButton>
              </Grid>
            ) : (
              <Grid item xs={isMobile ? 12 : 4}>
                <FormSubmitButton
                  variant="outlined"
                  isLoading={isLoading}
                  disabled={!formRef?.current?.values.currentMealEditable || !isFormValid}
                >
                  Confirm
                </FormSubmitButton>
              </Grid>
            )}

            <Grid item xs={isMobile ? 12 : 4}>
              <BackgroundButton
                onClick={() =>
                  isUpdateOrder
                    ? navigate(routes.PREORDER_MEAL_EDIT_SUMMARY)
                    : navigate(routes.PREORDER_MEAL_SUMMARY)
                }
              >
                Decide Later & Pay
              </BackgroundButton>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    )
  );
};

export default PreorderItemScreen;
