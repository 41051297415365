import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useLocation } from "react-router-dom";
import FormTextField from "../../../components/forms/form-text-field.component";
import routes from "../../../components/navigation/routes";
import { PreorderItemFormValues } from "../preorder/type/type";
import { SameDayFormValues } from "../same-day/type/type";

const RemarkSection = () => {
  const theme = useTheme();
  const { values } = useFormikContext<SameDayFormValues | PreorderItemFormValues>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const editable =
    location.pathname === routes.PREORDER_ITEM_EDIT
      ? (values as PreorderItemFormValues).currentMealEditable
      : true;

  return (
    <Grid container display="flex" alignItems="center" justifyContent="center">
      <Grid item xs={isMobile ? 12 : 8}>
        <FormTextField label="Remark" name="remark" disabled={!editable} />
      </Grid>
    </Grid>
  );
};

export default RemarkSection;
