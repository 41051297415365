import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

interface Props {
  handleSubmit: () => void;
}

function ResendButton({ handleSubmit }: Props) {
  const [countDown, setCountDown] = useState(60);

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;
    let timer: ReturnType<typeof setTimeout>;
    if (countDown > 0) {
      timer = setTimeout(() => {
        interval = setInterval(() => {
          setCountDown((prev) => {
            if (prev === 1) clearInterval(interval);
            return prev - 1;
          });
        });
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [countDown]);

  return (
    <Box>
      <Button
        disabled={countDown > 0}
        variant="text"
        onClick={() => {
          setCountDown(60);
          handleSubmit();
        }}
      >
        <Typography textAlign="center">
          {countDown > 0 ? `Resend Code in ${countDown}` : "Resend Code"}
        </Typography>
      </Button>
    </Box>
  );
}

ResendButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default ResendButton;
