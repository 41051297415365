import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import BackgroundButton from "../../../components/button/background-button.component";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "50px",
});

const StyledTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  textAlign: "center",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: "center",
}));

export default function BillSuccessfulScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledContainer>
      <Spacer size="xl" />
      <CheckCircleRoundedIcon color="success" sx={{ fontSize: "150px" }} />
      <Spacer size="m" />
      <StyledTitle variant={isMobile ? "h4" : "h3"}>Payment Successful!</StyledTitle>
      <Spacer size="m" />
      <GreyText>
        You will receive an order confirmation email shortly. Please proceed to view your purchases.
      </GreyText>
      <Spacer size="xxxl" />
      <Grid container justifyContent="center">
        <Grid item xs={4}>
          <BackgroundButton onClick={() => navigate(routes.HOME)}>Back to Home</BackgroundButton>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
