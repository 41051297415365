import InfoIcon from "@mui/icons-material/Info";
import {
  Grid,
  List,
  ListItem,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import Spacer from "../../../components/utils/spacer.component";
import { SameDayOrderResource } from "../../order/type/type";

interface Props {
  itemDetails: SameDayOrderResource;
  showAllInformation: boolean;
}

const SpaceBetweenGrid = styled(Grid)({
  justifyContent: "space-between",
});

const BoldText = styled(Typography)({
  fontWeight: "bold",
});

const SameDayOrderDetail = ({ itemDetails, showAllInformation }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getStatusColor = (status: string) => {
    if (status === "ongoing") {
      return "warning.main";
    }
    if (status === "completed") {
      return "success.main";
    }

    if (status === "pending_payment") {
      return "error";
    }

    return "text.primary";
  };

  const renderFoodSelections = (type: "meat" | "vege" | "side" | "carb" | "addon") =>
    itemDetails.item && itemDetails.item.foods[type] && itemDetails.item.foods[type].length > 0 ? (
      <Grid item xs={12}>
        <Typography fontWeight="bold" textTransform="capitalize">
          {type}:
        </Typography>
        <List sx={{ listStyleType: "decimal", pl: 5 }}>
          {itemDetails.item.foods[type].map((item) => (
            <ListItem
              sx={{ display: "list-item", textAlign: "justify" }}
              key={item.id + item.identifier}
            >
              {isMobile ? (
                <Grid item>
                  <Typography>{item.identifier}</Typography>
                  <Typography>
                    ({item.no_of_pax} x RM {item.price})
                  </Typography>
                  {item.no_of_pax && item.price && (
                    <Typography color="primary.dark" fontWeight="bold" textAlign="right">
                      RM {(item.no_of_pax * parseFloat(item.price)).toFixed(2)}
                    </Typography>
                  )}
                </Grid>
              ) : (
                <Grid container justifyContent="space-between">
                  <Typography>
                    {item.identifier} x {item.no_of_pax} (RM {item.price})
                  </Typography>
                  {item.no_of_pax && item.price && (
                    <Typography color="primary.dark" fontWeight="bold">
                      RM {(item.no_of_pax * parseFloat(item.price)).toFixed(2)}
                    </Typography>
                  )}
                </Grid>
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    ) : null;

  return (
    <>
      <SpaceBetweenGrid container item>
        <BoldText variant="h6">Order No: #{itemDetails.id}</BoldText>{" "}
        <BoldText variant="h6" textTransform="capitalize" color="info.dark">
          {itemDetails.type.replace("_", " ")}
        </BoldText>
      </SpaceBetweenGrid>
      <Grid item />
      {!showAllInformation && (
        <SpaceBetweenGrid container item>
          <Typography>Status</Typography>
          <BoldText textTransform="capitalize" color={getStatusColor(itemDetails.status)}>
            {itemDetails.status.replace("_", " ")}
          </BoldText>
        </SpaceBetweenGrid>
      )}

      <SpaceBetweenGrid container item>
        <Typography>Pick Up Time</Typography>
        <BoldText>{dayjs(itemDetails.item?.date_time).format("DD MMM YY, dddd, h:00 A")}</BoldText>
      </SpaceBetweenGrid>
      <SpaceBetweenGrid container item>
        <Typography>Pick Up Method</Typography>
        <BoldText>{itemDetails.item?.type === "pickup" ? "Self Pick Up" : "Delivery"}</BoldText>
      </SpaceBetweenGrid>
      <SpaceBetweenGrid container item>
        <Grid container item xs={8} alignItems="center">
          <Typography>Order Confirmed</Typography>
          <Spacer position="left" />
          <Tooltip
            slotProps={{
              tooltip: {
                sx: { padding: "10px" },
              },
            }}
            title="Order will be confirmed and processed if there are no due or outstanding payments."
            arrow
          >
            <InfoIcon color="primary" />
          </Tooltip>
        </Grid>

        <BoldText color={itemDetails.item?.confirmed ? "success.main" : "error"}>
          {itemDetails.item?.confirmed ? "Yes" : "No"}
        </BoldText>
      </SpaceBetweenGrid>
      {showAllInformation && (
        <>
          <HorizontalDivider spacerSize="s" />
          {itemDetails.item && itemDetails.item.remark && (
            <Grid item xs={12}>
              <Typography fontWeight="bold">Remark: {itemDetails.item.remark}</Typography>
              <Typography fontWeight="bold"></Typography>
            </Grid>
          )}

          <Grid item>
            <Typography fontWeight="bold">Food Selection:</Typography>
          </Grid>
          <Spacer />
          {renderFoodSelections("meat")}
          {renderFoodSelections("vege")}
          {renderFoodSelections("side")}
          {renderFoodSelections("carb")}
          {renderFoodSelections("addon")}
          <HorizontalDivider spacerSize="s" />
          <Grid item>
            <Typography fontWeight="bold">Payment Summary:</Typography>
          </Grid>
        </>
      )}

      <SpaceBetweenGrid container item>
        <Typography>Last Paid At</Typography>
        <BoldText>
          {dayjs(itemDetails.payment_summary.transactions.at(-1)?.paid_at).format(
            "DD MMM YY, dddd, h:mm A",
          )}
        </BoldText>
      </SpaceBetweenGrid>

      {showAllInformation && (
        <>
          <SpaceBetweenGrid container item>
            <Typography>Subtotal</Typography>
            <BoldText color="success.main">RM {itemDetails.payment_summary.subtotal}</BoldText>
          </SpaceBetweenGrid>
        </>
      )}
      <SpaceBetweenGrid container item>
        <Typography>Total</Typography>
        <BoldText color="success.main">RM {itemDetails.payment_summary.total}</BoldText>
      </SpaceBetweenGrid>
      <SpaceBetweenGrid container item>
        <Typography>Total Paid</Typography>
        <BoldText color="success.main">RM {itemDetails.payment_summary.paid.total_amount}</BoldText>
      </SpaceBetweenGrid>
      {itemDetails.payment_summary.outstanding_total !== "0.00" && (
        <SpaceBetweenGrid container item>
          <Grid container item xs={7} alignItems="center">
            <Typography color="error">Outstanding Total</Typography>
            <Spacer position="left" />
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: { padding: "10px" },
                },
              }}
              title="Please ensure that any outstanding balance is paid to confirm the processing of your order. Thank you!"
              arrow
            >
              <InfoIcon color="error" />
            </Tooltip>
          </Grid>
          <BoldText color="error">RM {itemDetails.payment_summary.outstanding_total}</BoldText>
        </SpaceBetweenGrid>
      )}
      {itemDetails.payment_summary.excess_total !== "0.00" && (
        <SpaceBetweenGrid container item>
          <Grid container item xs={6} alignItems="center">
            <Typography color="error">Excess Total</Typography>
            <Spacer position="left" />
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: { padding: "10px" },
                },
              }}
              title="Any additional EXCESS payments cannot be refunded once processed."
              arrow
            >
              <InfoIcon color="error" />
            </Tooltip>
          </Grid>{" "}
          <BoldText color="error">RM {itemDetails.payment_summary.excess_total}</BoldText>
        </SpaceBetweenGrid>
      )}
    </>
  );
};

SameDayOrderDetail.defaultProps = {
  showAllInformation: false,
};

export default SameDayOrderDetail;
