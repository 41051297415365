import { Grid } from "@mui/material";
import { FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import routes from "../../../../components/navigation/routes";
import Loading from "../../../../components/notification/backdrop-loading.component";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import {
  createOrder,
  orderSelector,
  setPreorderCreated,
} from "../../../../services/order/order-slice.service";
import { useAppDispatch, useAppSelector } from "../../../../services/store";
import MealPlanDetailsSection from "../../../landing/components/meal-plan-details-section.component";
import StageProgressBar from "../../components/stage-progress-bar.component";
import MealPlanForm from "../components/meal-plan-form.component";
import { PreorderMealPlanFormValues } from "../type/type";

const validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  food_set_id: Yup.number().required().label("Food Set"),
  no_of_pax: Yup.number().required().label("No of pax"),
});

const PreorderMealScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { preorderCreatedObj } = useAppSelector(orderSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const snackBar = useSnackBar();
  const formRef = useRef<FormikProps<PreorderMealPlanFormValues>>(null);

  const initialValues: PreorderMealPlanFormValues = {
    type: "preorder",
    food_set_id: "",
    no_of_pax: "",
  };

  const submitPreorderMealPlan = (values: PreorderMealPlanFormValues) => {
    setIsLoading(true);
    dispatch(createOrder(values))
      .unwrap()
      .then((res) => {
        if (res.success) {
          setIsLoading(false);
          dispatch(setPreorderCreated(res.data));
          navigate(routes.PREORDER_ITEM);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  useEffect(() => {
    if (preorderCreatedObj) {
      navigate(routes.PREORDER_ITEM);
    }
  }, []);

  return (
    <Grid container display="flex" flexDirection="column" rowSpacing={4}>
      <Loading isLoading={isLoading} />
      <Grid item xs={12}>
        <StageProgressBar />
      </Grid>
      <Form
        initialValues={initialValues}
        onSubmit={submitPreorderMealPlan}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        <Grid item xs={12} marginTop="50px">
          <MealPlanForm />
        </Grid>
        <Grid item xs={12}>
          <MealPlanDetailsSection />
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item xs={3}>
            <FormSubmitButton isLoading={isLoading}>Next</FormSubmitButton>
          </Grid>
        </Grid>
      </Form>
    </Grid>
  );
};

export default PreorderMealScreen;
