import { Box, Grid, styled, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import theme from "../../../infrastructure/theme";
import BannerSection from "../../landing/components/banner-section.component";
import CheckDeliveryRateSection from "../../landing/components/check-delivery-rate-section.component";
import MealPlanDetailsSection from "../../landing/components/meal-plan-details-section.component";
import HighlightDishSection from "../components/highlight-dish-section.component";
import OrderButtonSection from "../components/order-button-section.component";

const MaxWidthBox = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  backgroundColor: "primary",
  minHeight: "59vh",
}));

const HomeScreen = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (location.hash === "#menu-of-the-week") {
      document.getElementById("menu-of-the-week")?.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <BannerSection />

      <MaxWidthBox
        sx={{
          padding: isMobile ? "16px" : "10px",
          paddingTop: "30px",
          marginTop: "50px",
          maxWidth: theme.dimensions.PCMaxWidth,
        }}
      >
        <Grid item xs={12}>
          <OrderButtonSection />
        </Grid>
        <section id="menu-of-the-week">
          <Grid item xs={12} marginTop="50px">
            <HighlightDishSection />
          </Grid>
        </section>
        <Grid item xs={12} container display="flex" justifyContent="center">
          <Grid item xs={12} paddingY="50px">
            <Typography
              variant="h4"
              fontWeight="bold"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Our Packages
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MealPlanDetailsSection />
          </Grid>
        </Grid>
        <Grid item xs={12} marginTop="50px">
          <CheckDeliveryRateSection />
        </Grid>
      </MaxWidthBox>
    </Box>
  );
};

export default HomeScreen;
