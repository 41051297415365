import { Button, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PreorderButtonImage from "../../../assets/images/preorder_meal_plan.png";
import SameDayButtonImage from "../../../assets/images/same_day_delivery.png";
import routes from "../../../components/navigation/routes";

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isTablet",
})<{ isTablet: boolean }>(({ isTablet }) => ({
  textTransform: "none",
  color: "white",
  backgroundPosition: "top",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: isTablet ? "120px" : "150px",
  width: isTablet ? "100%" : "300px",
}));

const OrderButtonSection = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={isTablet ? 1 : 10}>
      <Grid item xs={isMobile ? 12 : undefined}>
        <StyledButton
          isTablet={isTablet}
          sx={{
            backgroundImage: `linear-gradient(rgba(241,127,32, 0.8), rgba(127,97,71, 0.8)), url(${PreorderButtonImage})`,
          }}
          onClick={() => navigate(routes.PREORDER_MEAL_PLAN)}
        >
          <Typography fontWeight="bold" variant="h5" width="150px">
            Preorder Meal Plan
          </Typography>
        </StyledButton>
      </Grid>
      <Grid item xs={isMobile ? 12 : undefined}>
        <StyledButton
          isTablet={isTablet}
          sx={{
            backgroundImage: `linear-gradient( rgba(163,124,93, 0.8),rgba(241,127,32, 0.8)), url(${SameDayButtonImage})`,
          }}
          onClick={() => navigate(routes.SAME_DAY_ORDER)}
        >
          <Typography fontWeight="bold" variant="h5" width="150px">
            Daily Ala Carte
          </Typography>
        </StyledButton>
      </Grid>
    </Grid>
  );
};

export default OrderButtonSection;
