import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, putReq } from "../../api.services";
import { idleObject } from "../../idle-object";
import { RequestObject, RootState } from "../../store";

export const getOrderBill = createAsyncThunk("orders/bills/{id}", async (payload: any) => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders/bills/${payload}`);
  return response;
});

export const cancelOrderBill = createAsyncThunk(
  "orders/bills/{id}/cancel",
  async (payload: any) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/orders/bills/${payload}/cancel`,
    );
    return response;
  },
);

interface initialStateType {
  getOrderBillObj: RequestObject;
  cancelOrderBillObj: RequestObject;
}

const initialState: initialStateType = {
  getOrderBillObj: idleObject(),
  cancelOrderBillObj: idleObject(),
};

const orderBillSlice = createSlice({
  name: "orderBill",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderBill.pending, (state) => {
        state.getOrderBillObj.status = "pending";
      })
      .addCase(getOrderBill.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getOrderBillObj.status = "succeeded";
        state.getOrderBillObj.data = data;
        state.getOrderBillObj.successMessage = message;
      })
      .addCase(getOrderBill.rejected, (state, action) => {
        const { message } = action.error;

        state.getOrderBillObj.status = "failed";
        state.getOrderBillObj.errorMessage = message;
      })
      .addCase(cancelOrderBill.pending, (state) => {
        state.cancelOrderBillObj.status = "pending";
      })
      .addCase(cancelOrderBill.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.cancelOrderBillObj.status = "succeeded";
        state.cancelOrderBillObj.data = data;
        state.cancelOrderBillObj.successMessage = message;
      })
      .addCase(cancelOrderBill.rejected, (state, action) => {
        const { message } = action.error;

        state.cancelOrderBillObj.status = "failed";
        state.cancelOrderBillObj.errorMessage = message;
      });
  },
});

export default orderBillSlice.reducer;

// state
export const orderBillSelector = (state: RootState) => state.orderBill;
