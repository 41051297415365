import { Box, Grid, styled, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import Chef1 from "../../../assets/images/chef-1.png";
import Chef2 from "../../../assets/images/chef-2.png";
import MealPrepBackground from "../../../assets/images/close-up-delicious-meal-preparation.png";
import FriedNoodle from "../../../assets/images/fried-noodle-with-pork-soy-sauce-vegetable.png";
import LocalOrganic from "../../../assets/images/local-organic-food.png";
import RestaurantFront from "../../../assets/images/restaurant_front.png";
import theme from "../../../infrastructure/theme";
import ExploreOrderCateringSection from "../../landing/components/explore-order-catering-section.component";
import OurValuesSection from "../../landing/components/our-values-section.component";
import VisionMissionBox from "../components/vision-mission-box.component";

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const MaxWidthBox = styled(Box)(({ theme }) => ({
  maxWidth: theme.dimensions.PCMaxWidth,
  padding: "16px",
}));

const AboutUsScreen = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <Box
        sx={{
          width: "100%",
          height: isMobile ? "40vh" : "55vh",
          backgroundImage: `linear-gradient(rgba(162,155,155, 0.2), rgba(0,0,0, 0.3)), url(${MealPrepBackground})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "10%",
          }}
        >
          <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold" color="text.white">
            Kitchen Comfort, Your Convenience:
          </Typography>
          <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold" color="text.white">
            Order, Collect, Enjoy
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          padding: isMobile ? "20px" : "100px",
          width: "100%",
          background: `linear-gradient(to bottom, white 50%, ${theme.palette.primary.main} 50%)`,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid
          container
          rowSpacing={0}
          sx={{
            padding: isMobile ? "20px" : "100px",
            backgroundColor: theme.palette.secondary.main,
            borderRadius: theme.shape.borderSizes[1],
            maxWidth: theme.dimensions.PCMaxWidth,
          }}
        >
          <Grid item xs={isMobile ? 12 : 6}>
            <Typography
              variant="h4"
              fontWeight="bold"
              color="primary.main"
              sx={{ padding: "30px" }}
            >
              About Us
            </Typography>
            <Typography variant="body1" color="text.secondary" textAlign="justify">
              At Yi Jia Canteen, our story is rooted in the warmth of family and the simplicity of
              home-cooked meals. What began as a humble family business, born out of the everyday
              challenge of finding time to prepare nourishing meals for our own children, has
              blossomed into a passionate endeavour to share the comfort and joy of home-cooked
              goodness with families like yours. We understand the demands of modern life and the
              importance of savouring moments around the dinner table. With a commitment to quality
              and a dash of love in every recipe, our objective is simple: to serve you wholesome,
              delicious meals that bring the heart of home to your own dining experience. Welcome to
              Yi Jia Canteen, where the essence of family, flavour, and a shared meal come together
              to create lasting memories.
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid
            item
            xs={isMobile ? 12 : 5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: isMobile ? "20px" : 0,
            }}
          >
            <Box
              sx={{
                borderRadius: theme.shape.borderSizes[1],
                backgroundImage: `url(${RestaurantFront})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: isMobile ? "90vw" : "100%",
                height: isMobile ? "30vh" : "350px",
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <MaxWidthBox>
        <Grid container spacing={isMobile ? 2 : 6} paddingTop="100px">
          <Grid item xs={isMobile ? 12 : 6}>
            <VisionMissionBox
              image={FriedNoodle}
              chef={Chef1}
              header="Our Vision"
              content="
              To be the heart of home-cooked warmth, connecting families through delicious, convenient, and quality meals 
              that embody the essence of a lovingly prepared dining experience."
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <VisionMissionBox
              image={LocalOrganic}
              chef={Chef2}
              header="Our Mission"
              content="
              Crafting and delivering wholesome dishes with care, Yi Jia Canteen strives to simplify busy lives by offering 
              a diverse menu of high-quality, locally sourced meals. We aim to make the joy of home-cooked goodness accessible to all, 
              promoting well-being and creating lasting moments around the table."
            />
          </Grid>
        </Grid>

        <OurValuesSection />

        <ExploreOrderCateringSection />
      </MaxWidthBox>
    </MainContainer>
  );
};

export default AboutUsScreen;
