import { Box, Grid, useTheme } from "@mui/material";
import BackgroundButton from "../../../components/button/background-button.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { orderSelector } from "../../../services/order/order-slice.service";
import { useAppSelector } from "../../../services/store";
import { PreorderResource, SameDayOrderResource } from "../../order/type/type";
import PreorderDetail from "./preorder-detail.component";
import SameDayOrderDetail from "./same-day-order-detail.component";

interface Props {
  itemDetails: PreorderResource | SameDayOrderResource;
  payPendingOrder: (order_id: number) => void;
}

const OrderDetailBox = ({ itemDetails, payPendingOrder }: Props) => {
  const theme = useTheme();
  const { payOrderObj } = useAppSelector(orderSelector);

  return (
    <Box
      sx={{
        backgroundColor: "bg.primary",
        borderRadius: theme.shape.borderSizes[1],
        cursor: "pointer",
      }}
    >
      <PaddedView multiples={3}>
        <Grid container spacing={1}>
          {itemDetails.type === "same_day" ? (
            <SameDayOrderDetail itemDetails={itemDetails} />
          ) : (
            <PreorderDetail itemDetails={itemDetails} />
          )}
        </Grid>
        {itemDetails.payment_summary.outstanding_total !== "0.00" && (
          <Grid item xs={12} container display="flex" justifyContent="flex-end">
            <Grid item xs={4}>
              <Spacer size="m" />
              <BackgroundButton
                isLoading={payOrderObj.status === "pending"}
                onClick={() => payPendingOrder(itemDetails.id)}
              >
                Pay
              </BackgroundButton>
            </Grid>
          </Grid>
        )}
      </PaddedView>
    </Box>
  );
};

export default OrderDetailBox;
