import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useLocation } from "react-router-dom";
import FormFoodCheckBox from "../../../components/forms/form-food-checkbox.component";
import FormQuantityInput from "../../../components/forms/form-quantity-input.component";
import routes from "../../../components/navigation/routes";
import { PreorderItemFormValues } from "../preorder/type/type";
import { FoodValues, SameDayFood, SameDayFormValues } from "../same-day/type/type";

interface Rule {
  min: number;
  max: number;
}

interface Props {
  itemDetails: SameDayFood;
  type: "meat" | "vege" | "side" | "carb" | "addon";
  rules?: {
    total_vege_side_combination: number;
    meat: Rule;
    vege: Rule;
    side: Rule;
    carb: Rule;
    addon: Rule;
  };
}

const FoodComponent = ({ type, itemDetails, rules }: Props) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setFieldValue, values } = useFormikContext<SameDayFormValues | PreorderItemFormValues>();
  const valueInFormAsObject = values[type] as FoodValues[];
  const valueInFormAsNumberArr = values[type] as number[];
  const currentItemValue = valueInFormAsObject.find((item) => item.id === itemDetails.id);
  const editable =
    location.pathname === routes.PREORDER_ITEM_EDIT
      ? (values as PreorderItemFormValues).currentMealEditable
      : true;

  const changeQuantity = (newQuantity: number | undefined) => {
    if (newQuantity && newQuantity > 0) {
      setFieldValue(type, [
        ...valueInFormAsObject.filter((item) => item.id !== itemDetails.id),
        { id: itemDetails.id, no_of_pax: newQuantity },
      ]);
    } else {
      setFieldValue(type, [...valueInFormAsObject.filter((item) => item.id !== itemDetails.id)]);
    }
  };

  const isSetItem = () => {
    if (
      ([routes.PREORDER_ITEM, routes.PREORDER_ITEM_EDIT] as string[]).includes(location.pathname)
    ) {
      if (["meat", "vege", "side"].includes(type)) {
        return true;
      }
      if (type === "carb") {
        if (rules?.carb.min !== 0) {
          return true;
        }
      }
    }
    return false;
  };

  const onCheckBoxChange = (checked: boolean) => {
    if (checked) {
      if (type === "carb") {
        setFieldValue(type, [...valueInFormAsObject, { id: itemDetails.id }]);
      } else {
        setFieldValue(type, [...valueInFormAsNumberArr, itemDetails.id]);
      }
    } else {
      if (type === "carb") {
        setFieldValue(
          type,
          valueInFormAsObject.filter((item) => item.id !== itemDetails.id),
        );
      } else {
        setFieldValue(
          type,
          valueInFormAsNumberArr.filter((item) => item !== itemDetails.id),
        );
      }
    }
  };

  const isChecked = () => {
    if (["meat", "vege", "side"].includes(type)) {
      return valueInFormAsNumberArr.includes(itemDetails.id);
    } else {
      return valueInFormAsObject.some((item) => item.id === itemDetails.id);
    }
  };

  const checkBoxShouldDisabled = () => {
    if (!isSetItem()) {
      return true;
    }
    if (!editable) {
      return true;
    }
    if (rules) {
      switch (type) {
        case "meat":
          if (
            !valueInFormAsNumberArr.includes(itemDetails.id) &&
            valueInFormAsNumberArr.length >= rules.meat.max
          ) {
            return true;
          }
          return false;
        case "vege":
          if (
            !valueInFormAsNumberArr.includes(itemDetails.id) &&
            (valueInFormAsNumberArr.length >= rules.vege.max ||
              (rules.vege.min === 0 &&
                valueInFormAsNumberArr.length + values.side.length ===
                  rules.total_vege_side_combination))
          ) {
            return true;
          }
          return false;
        case "side":
          if (
            !valueInFormAsNumberArr.includes(itemDetails.id) &&
            (valueInFormAsNumberArr.length >= rules.side.max ||
              (rules.side.min === 0 &&
                valueInFormAsNumberArr.length + values.vege.length ===
                  rules.total_vege_side_combination))
          ) {
            return true;
          }
          return false;
        case "carb":
          if (
            !valueInFormAsObject.some((item) => item.id === itemDetails.id) &&
            valueInFormAsObject.length >= rules.carb.max
          ) {
            return true;
          }
          return false;
        default:
          return false;
      }
    }
    return false;
  };

  return (
    <Box>
      <Typography sx={{ height: "50px" }}>{itemDetails.identifier}</Typography>
      <Box
        sx={{
          position: "relative",
          width: isMobile ? "100%" : "95%",
          aspectRatio: 2 / 1.7,
          // height: isMobile ? "200px" : "250px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          marginRight: "20px",
          cursor: !checkBoxShouldDisabled() ? "pointer" : undefined,
          borderRadius: theme.shape.borderSizes[1],
          backgroundImage: `url(${
            itemDetails.image_path
              ? itemDetails.image_path
              : "https://yijiacanteen-production.sgp1.cdn.digitaloceanspaces.com/default/yijia-logo.jpeg"
          })`,
        }}
        onClick={() => (checkBoxShouldDisabled() ? null : onCheckBoxChange(!isChecked()))}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            borderBottomLeftRadius: theme.shape.borderSizes[1],
            borderBottomRightRadius: theme.shape.borderSizes[1],
            height: "50px",
            width: "100%",
            backgroundColor: "primary.main",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="15px"
          >
            {isSetItem() ? (
              <Grid item xs={12}>
                <FormFoodCheckBox
                  name={type}
                  handleChange={onCheckBoxChange}
                  checked={isChecked()}
                  disabled={checkBoxShouldDisabled()}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={7}>
                  <FormQuantityInput
                    disabled={!editable}
                    changeQuantity={changeQuantity}
                    value={currentItemValue ? currentItemValue.no_of_pax : 0}
                  />
                </Grid>
                {itemDetails.price && (
                  <Grid item>
                    <Typography fontWeight="bold" color="text.white">
                      RM {itemDetails.price}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default FoodComponent;
