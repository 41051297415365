import { Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import BorderButton from "../../../components/button/border-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTextField from "../../../components/forms/form-text-field.component";
import Form from "../../../components/forms/form.component";
import routes from "../../../components/navigation/routes";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import Spacer from "../../../components/utils/spacer.component";
import { profileSelector } from "../../../services/profile/profile-slice.service";
import { useAppSelector } from "../../../services/store";

interface Props {
  email: string;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  handleSubmit: (values: any) => void;
  isEmailEdit: boolean;
  setIsEmailEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

function UpdateEmailForm({
  email,
  setIsEditing,
  isEditing,
  handleSubmit,
  isEmailEdit,
  setIsEmailEdit,
  isLoading,
}: Props) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
  });
  const { profileObj } = useAppSelector(profileSelector);
  const navigate = useNavigate();

  const handleEditPress = () => {
    setIsEditing(true);
    setIsEmailEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsEmailEdit(false);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography>Email</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            {profileObj.data?.emailVerified ? (
              <Grid item>
                {isEmailEdit ? (
                  <BorderButton onClick={handleCancelPress}>Cancel</BorderButton>
                ) : (
                  <BorderButton onClick={handleEditPress} disabled={isEditing}>
                    Edit
                  </BorderButton>
                )}
              </Grid>
            ) : (
              <Grid item>
                <BorderButton
                  onClick={() =>
                    navigate(routes.EMAIL_VERIFICATION_CODE, {
                      state: { email, resendCode: true },
                    })
                  }
                >
                  Verify
                </BorderButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isEmailEdit ? (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                email,
              }}
              onSubmit={handleSubmit}
            >
              <Grid container>
                <Grid container justifyContent="center" flexDirection="column">
                  <FormTextField name="email" label="Email" />
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading}>Save</FormSubmitButton>
            </Form>
          ) : (
            <Typography color="text.secondary">{email === "" ? "Not Provided" : email}</Typography>
          )}
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="s" />
    </>
  );
}

export default UpdateEmailForm;
