import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAPI } from "../../infrastructure/utils";
import { getReq } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getPopularDishes = createAsyncThunk("popular_dish/index", async () => {
  const response = await getReq(`${getAPI()}/popular_dishes/`);
  return response;
});

interface initialStateType {
  getPopularDishesObj: RequestObject;
}

const initialState: initialStateType = {
  getPopularDishesObj: idleObject(),
};

const popularDishSlice = createSlice({
  name: "popularDish",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPopularDishes.pending, (state) => {
        state.getPopularDishesObj.status = "pending";
      })
      .addCase(getPopularDishes.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getPopularDishesObj.status = "succeeded";
        state.getPopularDishesObj.data = data;
        state.getPopularDishesObj.successMessage = message;
      })
      .addCase(getPopularDishes.rejected, (state, action) => {
        const { message } = action.error;

        state.getPopularDishesObj.status = "failed";
        state.getPopularDishesObj.errorMessage = message;
      });
  },
});

export default popularDishSlice.reducer;

// state
export const popularDishSelector = (state: RootState) => state.popularDish;
