import { configureStore } from "@reduxjs/toolkit";
import { useSelector, TypedUseSelectorHook, useDispatch } from "react-redux";
import addressSliceServices from "./address/address-slice.services";
import authSliceService from "./auth/auth-slice.service";
import foodSetSliceServices from "./food-set/food-set-slice.services";
import highlightDishSliceServices from "./highlight-dish/highlight-dish-slice.services";
import orderBillSliceService from "./order/bill/order-bill-slice.service";
import orderSliceService from "./order/order-slice.service";
import preorderItemSlice from "./order/preorder/order-item/preorder-item.slice";
import sameDayOrderItemSlice from "./order/same-day/order-item/same-day-order-item.slice";
import popularDishSliceServices from "./popular-dish/popular-dish-slice.services";
import profileSliceService from "./profile/profile-slice.service";

const store = configureStore({
  reducer: {
    auth: authSliceService,
    profile: profileSliceService,
    address: addressSliceServices,
    order: orderSliceService,
    orderBill: orderBillSliceService,
    sameDayOrderItem: sameDayOrderItemSlice,
    preorderItem: preorderItemSlice,
    foodSet: foodSetSliceServices,
    highlightDish: highlightDishSliceServices,
    popularDish: popularDishSliceServices,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface RequestObject<T = any> {
  status: "idle" | "pending" | "succeeded" | "failed";
  data: T;
  successMessage: string | undefined | null;
  errorMessage: string | undefined | null;
}
