import ExploreIcon from "@mui/icons-material/Explore";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { BottomNavigation, BottomNavigationAction, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import { isLogin } from "../../../utils";

function MobileBottomNavBar() {
  const navigate = useNavigate();

  const handleBtmNavBar = (target: any) => {
    if (target === 0) {
      navigate(routes.HOME);
    }
    if (target === 1) {
      navigate(routes.USER_ADDRESSES);
    }
    if (target === 2) {
      navigate(routes.MY_ORDERS);
    }
    if (target === 3) {
      navigate(routes.USER_PROFILE);
    }
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        paddingY: 1,
        borderRadius: 0,
      }}
      elevation={3}
    >
      <BottomNavigation onChange={(event, newValue) => handleBtmNavBar(newValue)} showLabels>
        <BottomNavigationAction label={<Typography>Home</Typography>} icon={<HomeIcon />} />

        {isLogin() && (
          <BottomNavigationAction
            label={<Typography>Addresses</Typography>}
            icon={<ExploreIcon />}
          />
        )}
        {isLogin() && (
          <BottomNavigationAction
            label={<Typography>Orders</Typography>}
            icon={<FactCheckIcon />}
          />
        )}

        <BottomNavigationAction label={<Typography>Profile</Typography>} icon={<PersonIcon />} />
      </BottomNavigation>
    </Paper>
  );
}

export default MobileBottomNavBar;
