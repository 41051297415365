import { Box, Typography, useMediaQuery } from "@mui/material";
import BackgroundButton from "../../../components/button/background-button.component";
import theme from "../../../infrastructure/theme";
import MealPrepBackground from "../../../assets/images/close-up-delicious-meal-preparation.png";
import BannerAfterLogin from "../../../assets/images/banner_after_login.png";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import { isLogin } from "../../../infrastructure/utils";
import { profileSelector } from "../../../services/profile/profile-slice.service";
import { useAppSelector } from "../../../services/store";

function BannerSection() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { profileObj } = useAppSelector(profileSelector);

  return (
    <Box
      sx={{
        width: "100%",
        height: isMobile ? "40vh" : "55vh",
        backgroundImage: `linear-gradient(rgba(162,155,155, 0.2), rgba(0,0,0, 0.3)), url(${
          isLogin() ? BannerAfterLogin : MealPrepBackground
        })`,
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      {isLogin() ? (
        <>
          <Box
            sx={{
              position: "absolute",
              left: "10%",
            }}
          >
            <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold" color="text.white">
              Hi {profileObj.status === "succeeded" && profileObj.data && profileObj.data.firstName}
              ,
            </Typography>
            <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold" color="text.white">
              What would you like to order today?
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              position: "absolute",
              left: "10%",
            }}
          >
            <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold" color="text.white">
              Kitchen Comfort, Your Convenience:
            </Typography>
            <Typography variant={isMobile ? "h4" : "h3"} fontWeight="bold" color="text.white">
              Order, Collect, Enjoy
            </Typography>
          </Box>
          <Box
            sx={{
              filter: "grayscale(0%)",
              position: "absolute",
              bottom: "8%",
              right: "8%",
            }}
          >
            <BackgroundButton onClick={() => navigate(routes.LOGIN)}>
              Login / Sign Up
            </BackgroundButton>
          </Box>
        </>
      )}
    </Box>
  );
}

export default BannerSection;
