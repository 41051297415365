import { Grid, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";

import BorderButton from "../../../components/button/border-button.component";
import FormPhoneTextField from "../../../components/forms/form-phone-text-field.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import Spacer from "../../../components/utils/spacer.component";

interface Props {
  phoneNumber: string;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  handleSubmit: (values: any) => void;
  isPhoneEdit: boolean;
  setIsPhoneEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

function UpdatePhoneForm({
  phoneNumber,
  setIsEditing,
  isEditing,
  handleSubmit,
  isPhoneEdit,
  setIsPhoneEdit,
  isLoading,
}: Props) {
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().nullable().notRequired().min(9).label("Phone Number"),
  });

  const handleEditPress = () => {
    setIsEditing(true);
    setIsPhoneEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsPhoneEdit(false);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography>Phone Number</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isPhoneEdit ? (
                <BorderButton onClick={handleCancelPress}>Cancel</BorderButton>
              ) : (
                <BorderButton onClick={handleEditPress} disabled={isEditing}>
                  Edit
                </BorderButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isPhoneEdit ? (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                phoneNumber,
              }}
              onSubmit={handleSubmit}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Spacer size="m" />
                    <FormPhoneTextField label="Phone No" name="phoneNumber" />
                  </Grid>
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading}>Save</FormSubmitButton>
            </Form>
          ) : (
            <Typography color="text.secondary">
              {phoneNumber === "" ? "Not Provided" : phoneNumber}
            </Typography>
          )}
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="s" />
    </>
  );
}

export default UpdatePhoneForm;
