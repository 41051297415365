import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import theme from "./infrastructure/theme";
import store from "./services/store";
import { SnackbarProvider } from "./components/notification/snackbar.context";
import MetaPixel from "./infrastructure/utils/meta/metaPixel";
import GoogleTag from "./infrastructure/utils/google/googleTag";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    {process.env.REACT_APP_ENVIRONMENT === "production" && <MetaPixel />}
    {process.env.REACT_APP_ENVIRONMENT === "production" && <GoogleTag />}

    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </SnackbarProvider>
  </Provider>,
);
