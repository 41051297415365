import { Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BackgroundButton from "../../../../components/button/background-button.component";
import routes from "../../../../components/navigation/routes";
import Loading from "../../../../components/notification/backdrop-loading.component";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { orderSelector, payOrder } from "../../../../services/order/order-slice.service";
import { useAppDispatch, useAppSelector } from "../../../../services/store";
import StageProgressBar from "../../components/stage-progress-bar.component";
import { OrderFood } from "../../type/type";

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
}));

const PreorderMealSummaryScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { preorderCreatedObj, preorderUpdatingObj } = useAppSelector(orderSelector);
  const isUpdateOrder = location.pathname === routes.PREORDER_MEAL_EDIT_SUMMARY;
  const orderObject = isUpdateOrder ? preorderUpdatingObj : preorderCreatedObj;
  const snackBar = useSnackBar();
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!orderObject) {
      navigate(routes.HOME);
    }
  }, []);

  const confirmOrder = () => {
    setIsLoading(true);
    dispatch(payOrder({ payment_method: "others", order_id: orderObject?.id }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          setIsLoading(false);
          window.open(res.data.bill.remote_url, "_self");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const renderRemainingItems = () => {
    const items = [];

    if (orderObject) {
      for (
        let i = orderObject.total_ordered_meals + 1;
        i <= (viewMore ? orderObject.total_meals : 3 - orderObject.total_ordered_meals);
        i++
      ) {
        items.push(
          <Grid item key={i}>
            <Grid item>
              <Typography fontWeight="bold" color="text.secondary">
                <span style={{ color: theme.palette.text.primary }}>Meal {i}: </span>
                Decide Later
              </Typography>
            </Grid>
            <Spacer size="m" />
          </Grid>,
        );
      }
    }

    return items;
  };

  return (
    orderObject && (
      <Grid container display="flex" flexDirection="column" rowSpacing={4}>
        <Loading isLoading={isLoading} />
        <Grid item xs={12}>
          <StageProgressBar />
        </Grid>
        <Grid item marginTop="50px">
          <StyledLink to={isUpdateOrder ? routes.PREORDER_ITEM_EDIT : routes.PREORDER_ITEM}>
            <Typography fontWeight="bold" color="primary.dark">
              {"<"} Edit Meal Plan Items
            </Typography>
          </StyledLink>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            sx={{ backgroundColor: "bg.primary", borderRadius: theme.shape.borderSizes[1] }}
          >
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                borderRight: isTablet ? undefined : "1px solid",
                borderColor: "text.disabled",
              }}
            >
              <PaddedView multiples={4}>
                <Grid container flexDirection="column" spacing={1}>
                  <Grid item display="flex">
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ borderBottom: "3px solid", borderColor: "primary.main" }}
                    >
                      Items Selected
                    </Typography>
                  </Grid>
                  <Spacer size="l" />
                  <Grid item>
                    <Typography fontWeight="bold" color="text.secondary">
                      Selected Meal (s):{" "}
                    </Typography>
                  </Grid>
                  {orderObject.items.slice(0, viewMore ? undefined : 2).map((item, index) => (
                    <Grid item key={index}>
                      <Grid item>
                        <Typography fontWeight="bold" color="text.secondary">
                          <span style={{ color: theme.palette.text.primary }}>
                            Meal {index + 1}:{" "}
                          </span>
                          {dayjs(item.date_time).format("DD MMM YY, dddd, h:00 A")} -{" "}
                          {dayjs(new Date(item.date_time)).add(1, "h").format("h:mm A")}
                        </Typography>
                      </Grid>
                      <Spacer />
                      <Grid item>
                        <Typography fontWeight="bold" color="text.secondary">
                          Status:{" "}
                          <span
                            style={{
                              color: item.confirmed
                                ? item.status === "pending"
                                  ? theme.palette.primary.main
                                  : theme.palette.success.main
                                : theme.palette.error.main,
                            }}
                          >
                            {item.confirmed
                              ? item.status === "pending"
                                ? "Pending"
                                : "Delivered"
                              : "Not Confirmed"}
                          </span>
                        </Typography>
                      </Grid>
                      <Spacer />
                      <Grid item>
                        <Typography fontWeight="bold" color="text.secondary">
                          Food Selection:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {Object.entries(item.foods)
                            .map(([key, value]) =>
                              value.map((food: OrderFood) => {
                                if (key === "carb" || key === "addon") {
                                  return food.identifier + ` x ${food.no_of_pax}`;
                                } else {
                                  return food.identifier;
                                }
                              }),
                            )
                            .flat()
                            .join(", ")}
                        </Typography>
                      </Grid>
                      {item.remark && (
                        <>
                          <Spacer />
                          <Grid item>
                            <Typography fontWeight="bold" color="text.secondary">
                              Remark:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>{item.remark}</Typography>
                          </Grid>
                        </>
                      )}

                      <Spacer />
                      {item.delivery && (
                        <>
                          <Grid item>
                            <Typography fontWeight="bold" color="text.secondary">
                              Delivery Details:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              {item.delivery.address.unit_no}
                              {", "} {item.delivery.address.location}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Spacer size="m" />
                    </Grid>
                  ))}
                  {renderRemainingItems()}
                  <Grid item sx={{ cursor: "pointer" }} onClick={() => setViewMore(!viewMore)}>
                    <Typography
                      fontWeight="bold"
                      color="primary.main"
                      sx={{ textDecoration: "underline" }}
                    >
                      {viewMore ? "Hide" : "View More"}
                    </Typography>
                  </Grid>
                </Grid>
              </PaddedView>
            </Grid>
            <Grid item container xs={12} md={5}>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: "95px",
                    backgroundColor: "primary.main",
                    borderTopRightRadius: isTablet ? undefined : theme.shape.borderSizes[1],
                  }}
                >
                  <PaddedView multiples={4}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="text.white"
                      textTransform="uppercase"
                    >
                      Order Summary
                    </Typography>
                  </PaddedView>
                </Grid>
                <Grid item xs={12}>
                  <PaddedView multiples={4}>
                    <Grid container flexDirection="column" spacing={1}>
                      {isUpdateOrder && (
                        <>
                          <Grid item>
                            <Typography fontWeight="bold">Order No: #{orderObject.id}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              fontWeight="bold"
                              textTransform="capitalize"
                              color={
                                orderObject.status === "ongoing"
                                  ? "primary.main"
                                  : orderObject.status === "pending_payment"
                                    ? "error.main"
                                    : "success.main"
                              }
                            >
                              <span style={{ color: theme.palette.text.primary }}>Status: </span>{" "}
                              {orderObject.status.replace("_", " ")}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Grid item>
                        <Typography fontWeight="bold" variant="h6">
                          {orderObject.food_set.identifier}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontWeight="bold">
                          <span style={{ color: theme.palette.text.secondary }}>No of pax:</span>{" "}
                          {orderObject.no_of_pax}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontWeight="bold">
                          <span style={{ color: theme.palette.text.secondary }}>
                            Total Ordered Meals:
                          </span>{" "}
                          {orderObject.total_ordered_meals}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontWeight="bold">
                          <span style={{ color: theme.palette.text.secondary }}>Total Meals:</span>{" "}
                          {orderObject.total_meals}
                        </Typography>
                      </Grid>
                    </Grid>
                  </PaddedView>
                </Grid>
              </Grid>
              <Grid item xs={12} alignSelf="self-end">
                <PaddedView multiples={4}>
                  <Grid container flexDirection="column" spacing={1}>
                    <Grid item>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography
                            variant={isMobile ? "body2" : "h6"}
                            fontWeight="bold"
                            textTransform="uppercase"
                          >
                            Subtotal
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={isMobile ? "body2" : "h6"}
                            fontWeight="bold"
                            color="primary.dark"
                          >
                            RM {orderObject.payment_summary.subtotal}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {orderObject.payment_summary.carb !== "0.00" && (
                      <>
                        <Grid item>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography
                                variant={isMobile ? "body2" : "h6"}
                                fontWeight="bold"
                                textTransform="uppercase"
                              >
                                Extra Charge for Carb:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant={isMobile ? "body2" : "h6"}
                                fontWeight="bold"
                                color="primary.dark"
                              >
                                RM {orderObject.payment_summary.carb}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {orderObject.payment_summary.addons !== "0.00" && (
                      <>
                        <Grid item>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography
                                variant={isMobile ? "body2" : "h6"}
                                fontWeight="bold"
                                textTransform="uppercase"
                              >
                                Extra Charge for Add on:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant={isMobile ? "body2" : "h6"}
                                fontWeight="bold"
                                color="primary.dark"
                              >
                                RM {orderObject.payment_summary.addons}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {orderObject.payment_summary.delivery !== "0.00" && (
                      <>
                        <Grid item>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography
                                variant={isMobile ? "body2" : "h6"}
                                fontWeight="bold"
                                textTransform="uppercase"
                              >
                                Delivery Fee:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant={isMobile ? "body2" : "h6"}
                                fontWeight="bold"
                                color="primary.dark"
                              >
                                RM {orderObject.payment_summary.delivery}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography
                            variant={isMobile ? "body2" : "h6"}
                            fontWeight="bold"
                            textTransform="uppercase"
                          >
                            Total
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={isMobile ? "body2" : "h6"}
                            fontWeight="bold"
                            color="primary.dark"
                          >
                            RM {orderObject.payment_summary.total}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {orderObject.payment_summary.paid.total_amount !== "0.00" && (
                      <Grid item>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Typography
                              variant={isMobile ? "body2" : "h6"}
                              fontWeight="bold"
                              textTransform="uppercase"
                            >
                              Total Paid
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant={isMobile ? "body2" : "h6"}
                              fontWeight="bold"
                              color="primary.dark"
                            >
                              RM {orderObject.payment_summary.paid.total_amount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {orderObject.payment_summary.paid.total_amount !== "0.00" && (
                      <>
                        {orderObject.payment_summary.outstanding_total !== "0.00" && (
                          <>
                            <Grid item>
                              <Grid container justifyContent="space-between">
                                <Grid item>
                                  <Typography
                                    variant={isMobile ? "body2" : "h6"}
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                    color="error"
                                  >
                                    Outstanding Total
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant={isMobile ? "body2" : "h6"}
                                    fontWeight="bold"
                                    color="error"
                                  >
                                    RM {orderObject.payment_summary.outstanding_total}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                        {orderObject.payment_summary.excess_total !== "0.00" && (
                          <>
                            <Grid item>
                              <Grid container justifyContent="space-between">
                                <Grid item>
                                  <Typography
                                    variant={isMobile ? "body2" : "h6"}
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                    color="error"
                                  >
                                    Excess Total
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant={isMobile ? "body2" : "h6"}
                                    fontWeight="bold"
                                    color="error"
                                  >
                                    RM {orderObject.payment_summary.excess_total}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {orderObject.payment_summary.require_payment && (
          <>
            <Spacer size="m" />
            <Grid container display="flex" justifyContent="center">
              <Grid item xs={12} sm={3}>
                <BackgroundButton onClick={confirmOrder} isLoading={isLoading}>
                  Confirm Order
                </BackgroundButton>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    )
  );
};

export default PreorderMealSummaryScreen;
