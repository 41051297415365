import { Grid, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import routes from "../../../components/navigation/routes";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import CustomSkeleton from "../../../components/utils/custom-skeleton.component";
import {
  profileSelector,
  toggleNewsletter,
  updateDateOfBirth,
  updateEmail,
  updateFullName,
  updateGender,
  updatePassword,
  updatePhoneNumber,
} from "../../../services/profile/profile-slice.service";
import { useAppDispatch, useAppSelector } from "../../../services/store";
import UpdateDOBForm from "./update-dateOfBirth-form.component";
import UpdateEmailForm from "./update-email-form.component";
import UpdateGenderForm from "./update-gender-form.component";
import UpdateNameForm from "./update-name-form.component";
import UpdatePasswordForm from "./update-password-form.component";
import UpdatePhoneForm from "./update-phone-form.component";

function UserDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isEditing, setIsEditing] = useState(false);
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [isGenderEdit, setIsGenderEdit] = useState(false);
  const [isDOBEdit, setIsDOBEdit] = useState(false);
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const [isPhoneEdit, setIsPhoneEdit] = useState(false);
  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const { profileObj, toggleNewsletterObj } = useAppSelector(profileSelector);
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();

  const handleUpdateName = (values: { firstName: string; lastName: string }) => {
    setIsLoading(true);
    dispatch(updateFullName(values))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          setIsEditing(false);
          setIsNameEdit(false);
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleUpdateGender = (values: { gender: string }) => {
    setIsLoading(true);
    dispatch(updateGender(values))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          setIsEditing(false);
          setIsGenderEdit(false);
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleUpdateDOB = (values: { dateOfBirth: any }) => {
    setIsLoading(true);
    dispatch(updateDateOfBirth({ dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD") }))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          setIsEditing(false);
          setIsDOBEdit(false);
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleUpdateEmail = (values: { email: string }) => {
    setIsLoading(true);
    dispatch(updateEmail(values))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setIsEditing(false);
          setIsEmailEdit(false);
          navigate(routes.EMAIL_VERIFICATION_CODE, { state: { email: values.email } });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleUpdatePhoneNumber = (values: { phoneNumber: string }) => {
    setIsLoading(true);
    dispatch(updatePhoneNumber(values))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          setIsEditing(false);
          setIsPhoneEdit(false);
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleUpdatePassword = (values: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    setIsLoading(true);
    dispatch(updatePassword(values))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          setIsEditing(false);
          setIsPasswordEdit(false);
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleNewsletterPreference = () => {
    dispatch(toggleNewsletter())
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const renderContent = () => {
    if (profileObj.status === "succeeded" && profileObj.data) {
      return (
        <Grid item xs={12}>
          <Grid container sx={{ paddingLeft: isMobile ? "0px" : "10px" }}>
            <UpdateNameForm
              firstName={profileObj.data.firstName}
              lastName={profileObj.data.lastName}
              handleSubmit={handleUpdateName}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isNameEdit={isNameEdit}
              setIsNameEdit={setIsNameEdit}
              isLoading={isLoading}
            />
            <UpdateGenderForm
              gender={profileObj.data.gender}
              handleSubmit={handleUpdateGender}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isGenderEdit={isGenderEdit}
              setIsGenderEdit={setIsGenderEdit}
              isLoading={isLoading}
            />
            <UpdateDOBForm
              dateOfBirth={profileObj.data.dateOfBirth}
              handleSubmit={handleUpdateDOB}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isDOBEdit={isDOBEdit}
              setIsDOBEdit={setIsDOBEdit}
              isLoading={isLoading}
            />
            <UpdateEmailForm
              email={profileObj.data.email}
              handleSubmit={handleUpdateEmail}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isEmailEdit={isEmailEdit}
              setIsEmailEdit={setIsEmailEdit}
              isLoading={isLoading}
            />
            <UpdatePhoneForm
              phoneNumber={profileObj.data.phoneNumber}
              handleSubmit={handleUpdatePhoneNumber}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isPhoneEdit={isPhoneEdit}
              setIsPhoneEdit={setIsPhoneEdit}
              isLoading={isLoading}
            />
            <UpdatePasswordForm
              handleSubmit={handleUpdatePassword}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              isPasswordEdit={isPasswordEdit}
              setIsPasswordEdit={setIsPasswordEdit}
              isLoading={isLoading}
            />
            <Grid
              container
              spacing={1}
              sx={{ paddingY: 3, paddingBottom: "50px" }}
              display="flex"
              justifyContent="space-between"
            >
              <Grid item xs={9}>
                <Grid item xs={12}>
                  <Typography>Newsletter</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Receive special offers, promotion and other information from Yi Jia via email.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Switch
                  disabled={toggleNewsletterObj.status === "pending"}
                  checked={profileObj.data.subscribedNewsletter}
                  onChange={handleNewsletterPreference}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <CustomSkeleton height={600} />
      </Grid>
    );
  };

  return renderContent();
}

export default UserDetails;
