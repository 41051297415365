import { Grid, useMediaQuery } from "@mui/material";
import * as React from "react";
import theme from "../../../infrastructure/theme";

interface Props {
  image: string;
  content: React.JSX.Element;
}

const ValuesBox = ({ image, content }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        boxShadow: "2px 2px 10px grey",
        borderRadius: theme.shape.borderSizes[1],
        padding: "10px",
        marginY: "10px",
        height: isMobile ? "200px" : "300px",
      }}
    >
      <Grid
        item
        display="flex"
        sx={{
          height: isMobile ? "105px" : "170px",
          marginBottom: "10px",
          justifyContent: "center",
        }}
      >
        <img src={image} alt={image} width="70%" height="100%" style={{ objectFit: "contain" }} />
      </Grid>
      <Grid item textAlign="center">
        {content}
      </Grid>
    </Grid>
  );
};

export default ValuesBox;
