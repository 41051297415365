import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import theme from "../../../infrastructure/theme";

interface Props {
  image: string;
  chef: string;
  header: string;
  content: string;
}

const VisionMissionBox = ({ image, chef, header, content }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      sx={{
        position: "relative",
        height: "500px",
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: theme.shape.borderSizes[1],
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `linear-gradient(to bottom, ${theme.palette.primary.dark} 0%, #723B0C 50%, #241507 100%)`,
          borderRadius: theme.shape.borderSizes[1],
          opacity: 0.7,
          color: "#EEE",
          padding: "10px",
        }}
      />

      {!isMobile && (
        <Grid item xs={4}>
          <img
            src={chef}
            alt={chef}
            width="35%"
            style={{ position: "absolute", bottom: 0, left: 0, zIndex: 99 }}
          />
        </Grid>
      )}

      <Grid item xs={isMobile ? 12 : 8} sx={{ zIndex: 99 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          color="text.white"
          textAlign={isMobile ? "center" : "start"}
          sx={{ padding: isMobile ? "10px" : "20px" }}
        >
          {header}
        </Typography>
        <Typography
          variant={isMobile ? "body1" : "h6"}
          color="text.white"
          sx={{ padding: isMobile ? "10px" : "20px" }}
          textAlign="justify"
        >
          {content}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VisionMissionBox;
