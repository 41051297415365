import CallIcon from "@mui/icons-material/Call";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { FormikValues, useFormikContext } from "formik";
import { KeyboardEvent } from "react";
import Spacer from "../utils/spacer.component";
import theme from "../../infrastructure/theme";

interface Props {
  label: string;
  name: string;
  isTransparent?: boolean;
}

const FormPhoneTextField = ({ label, name, isTransparent }: Props) => {
  const { values, setFieldValue, touched, errors, setFieldTouched, handleSubmit } =
    useFormikContext<FormikValues>();
  const showError = touched[name] && typeof errors[name] === "string";
  const phoneNo = values[name] as string;

  const keyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        "& .MuiTextField-root": {
          "& .MuiOutlinedInput-root": {
            backgroundColor: isTransparent ? "transparent" : theme.palette.common.white,
          },
        },
      }}
    >
      <TextField
        name={name}
        onKeyDown={(event) => keyPress(event)}
        fullWidth
        label={label}
        error={showError}
        variant="outlined"
        helperText={showError ? String(errors[name]) : null}
        onBlur={() => setFieldTouched(name)}
        size="small"
        onChange={(e) => {
          setFieldValue(name, `+60${parseInt(e.target.value.replace("+60", ""), 10) || ""}`);
        }}
        value={phoneNo.replace("+60", "")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CallIcon />
              <Spacer position="right" />
              <Typography>+60</Typography>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

FormPhoneTextField.defaultProps = {
  isTransparent: true,
};

export default FormPhoneTextField;
