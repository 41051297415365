import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useRef } from "react";
import * as Yup from "yup";

import { FormikProps } from "formik";
import ReactCodeInput from "react-verification-code-input";
import ResendButton from "../../../../components/button/resend-button.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormTextField from "../../../../components/forms/form-text-field.component";
import Form from "../../../../components/forms/form.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { ResetPasswordFormValues } from "../../type/type";

interface Props {
  userEmail: string;
  handleSubmit: (values: ResetPasswordFormValues) => void;
  handleSubmitResendCodes: () => void;
  isLoading: boolean;
}

function VerificationForm({ userEmail, handleSubmit, handleSubmitResendCodes, isLoading }: Props) {
  const validationSchema = Yup.object().shape({
    verificationCode: Yup.string().required().label("Verification Code"),
    password: Yup.string().required().min(4).label("Password"),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password")], "Password and confirm password must be same.")
      .label("Password confirmation"),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const formRef = useRef<FormikProps<ResetPasswordFormValues>>();

  return (
    <Form
      initialValues={{ verificationCode: "", password: "", confirmPassword: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      <PaddedView multiples={isMobile ? 3 : 4}>
        <Typography textAlign="center">A verification code has been sent to {userEmail}</Typography>
        <Spacer size="s" />
        <Grid container justifyContent="center">
          <ReactCodeInput
            onChange={(value) => formRef?.current?.setFieldValue("verificationCode", value)}
            fieldWidth={50}
          />
        </Grid>

        <Grid container justifyContent="center">
          <ResendButton handleSubmit={handleSubmitResendCodes} />
        </Grid>
        <Spacer size="xl" />
        <FormTextField name="password" label="New Password" />
        <Spacer size="s" />
        <FormTextField type="password" name="confirmPassword" label="Confirm New Password" />
        <Spacer size="xl" />
        <FormSubmitButton isLoading={isLoading}>Confirm</FormSubmitButton>
      </PaddedView>
    </Form>
  );
}

export default VerificationForm;
