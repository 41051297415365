import { Grid, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";

import BorderButton from "../../../components/button/border-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTextField from "../../../components/forms/form-text-field.component";
import Form from "../../../components/forms/form.component";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import Spacer from "../../../components/utils/spacer.component";

interface Props {
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  handleSubmit: (values: any) => void;
  isPasswordEdit: boolean;
  setIsPasswordEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

function UpdatePasswordForm({
  setIsEditing,
  isEditing,
  handleSubmit,
  isPasswordEdit,
  setIsPasswordEdit,
  isLoading,
}: Props) {
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required().min(4).label("Password"),
    newPassword: Yup.string().required().min(4).label("Password"),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("newPassword")], "Password and confirm password must be same.")
      .label("Password confirmation"),
  });

  const handleEditPress = () => {
    setIsEditing(true);
    setIsPasswordEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsPasswordEdit(false);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography>Password</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isPasswordEdit ? (
                <BorderButton onClick={handleCancelPress}>Cancel</BorderButton>
              ) : (
                <BorderButton onClick={handleEditPress} disabled={isEditing}>
                  Edit
                </BorderButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isPasswordEdit && (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <FormTextField
                      type="password"
                      name="currentPassword"
                      label="Current Password"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <FormTextField type="password" name="newPassword" label="New Password" />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <FormTextField
                      type="password"
                      name="confirmPassword"
                      label="Confirm Password"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading}>Save</FormSubmitButton>
            </Form>
          )}
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="s" />
    </>
  );
}

export default UpdatePasswordForm;
