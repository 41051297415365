import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useNavigate } from "react-router-dom";
import BackgroundButton from "../../../../components/button/background-button.component";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";

function ResetSuccessful() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: theme.shape.borderSizes[1],
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
          paddingTop: "10px",
        }}
      >
        <TopTitleBox title="Reset Password Successfully" requireCloseButton={false} />
        <Spacer size="m" />
        <PaddedView multiples={isMobile ? 3 : 4}>
          <Grid container justifyContent="center">
            <CheckCircleRoundedIcon color="success" sx={{ fontSize: "150px" }} />
          </Grid>
          <Spacer size="m" />
          <Typography textAlign="center">You can sign in with your new password now.</Typography>
          <Box sx={{ width: 50, height: 50 }} />
          <Box>
            <BackgroundButton
              onClick={() => {
                navigate(routes.LOGIN);
              }}
            >
              Sign In
            </BackgroundButton>
          </Box>
        </PaddedView>
      </Box>
    </Box>
  );
}

export default ResetSuccessful;
