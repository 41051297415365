import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq, setAxiosToken } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const login = createAsyncThunk("auth/login", async (payload: any) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/login`, payload);
  return response;
});

export const register = createAsyncThunk("auth/register", async (payload: any) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/register`, payload);
  return response;
});

export const emailVerification = createAsyncThunk(
  "auth/email_verification",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/email_verification`,
      payload,
    );
    return response;
  },
);

export const resendVerificationCode = createAsyncThunk(
  "auth/resend_verification_code",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/resend_verification_code`,
      payload,
    );
    return response;
  },
);

export const forgotPassword = createAsyncThunk("auth/forgot_password", async (payload: any) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/forgot_password`,
    payload,
  );
  return response;
});

export const resetPassword = createAsyncThunk("auth/reset_password", async (payload: any) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/reset_password`, payload);
  return response;
});

export const logout = createAsyncThunk("auth/logout", async (payload: any) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/logout`, payload);
  return response;
});

interface initialStateType {
  user: any;
  loginObj: RequestObject;
  logoutObj: RequestObject;
  registerObj: RequestObject;
  forgotPasswordObj: RequestObject;
  emailVerificationObj: RequestObject;
  resendVerificationCodeObj: RequestObject;
  resetPasswordObj: RequestObject;
}

const initialState: initialStateType = {
  user: null,
  loginObj: idleObject(),
  logoutObj: idleObject(),
  registerObj: idleObject(),
  forgotPasswordObj: idleObject(),
  emailVerificationObj: idleObject(),
  resendVerificationCodeObj: idleObject(),
  resetPasswordObj: idleObject(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetUser: (state) => {
      state.user = null;
      state.loginObj.data = null;
      setAxiosToken("");
      localStorage.removeItem("YiJiaUserAuthToken");
      localStorage.removeItem("YiJiaUserExpireAt");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginObj.status = "pending";
      })
      .addCase(login.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.loginObj.status = "succeeded";
        state.loginObj.data = data;
        state.user = data;
        localStorage.setItem("YiJiaUserAuthToken", data.auth.token);
        localStorage.setItem("YiJiaUserExpireAt", data.auth.expireAt);
        setAxiosToken(data.auth.token);
        state.loginObj.successMessage = message;
      })
      .addCase(login.rejected, (state, action) => {
        const { message } = action.error;

        localStorage.removeItem("YiJiaUserAuthToken");
        localStorage.removeItem("YiJiaUserExpireAt");
        state.loginObj.status = "failed";
        state.loginObj.errorMessage = message;
      })
      .addCase(register.pending, (state) => {
        state.registerObj.status = "pending";
      })
      .addCase(register.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.registerObj.status = "succeeded";
        state.registerObj.data = data;
        state.user = data;
        localStorage.setItem("YiJiaUserAuthToken", data.auth.token);
        localStorage.setItem("YiJiaUserExpireAt", data.auth.expireAt);
        setAxiosToken(data.auth.token);
        state.registerObj.successMessage = message;
      })
      .addCase(register.rejected, (state, action) => {
        const { message } = action.error;

        localStorage.removeItem("YiJiaUserAuthToken");
        localStorage.removeItem("YiJiaUserExpireAt");
        state.registerObj.status = "failed";
        state.registerObj.errorMessage = message;
      })
      .addCase(emailVerification.pending, (state) => {
        state.emailVerificationObj.status = "pending";
      })
      .addCase(emailVerification.fulfilled, (state, action) => {
        const { message, data } = action.payload;

        state.emailVerificationObj.status = "succeeded";
        state.emailVerificationObj.successMessage = message;
        state.emailVerificationObj.data = data;
      })
      .addCase(emailVerification.rejected, (state, action) => {
        const { message } = action.error;

        state.emailVerificationObj.status = "failed";
        state.emailVerificationObj.errorMessage = message;
      })
      .addCase(resendVerificationCode.pending, (state) => {
        state.resendVerificationCodeObj.status = "pending";
      })
      .addCase(resendVerificationCode.fulfilled, (state, action) => {
        const { message, data } = action.payload;

        state.resendVerificationCodeObj.status = "succeeded";
        state.resendVerificationCodeObj.successMessage = message;
        state.resendVerificationCodeObj.data = data;
      })
      .addCase(resendVerificationCode.rejected, (state, action) => {
        const { message } = action.error;

        state.resendVerificationCodeObj.status = "failed";
        state.resendVerificationCodeObj.errorMessage = message;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordObj.status = "pending";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        const { message, data } = action.payload;

        state.forgotPasswordObj.status = "succeeded";
        state.forgotPasswordObj.successMessage = message;
        state.forgotPasswordObj.data = data;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        const { message } = action.error;

        state.forgotPasswordObj.status = "failed";
        state.forgotPasswordObj.errorMessage = message;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordObj.status = "pending";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        const { message, data } = action.payload;

        state.resetPasswordObj.status = "succeeded";
        state.resetPasswordObj.successMessage = message;
        state.resetPasswordObj.data = data;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        const { message } = action.error;

        state.resetPasswordObj.status = "failed";
        state.resetPasswordObj.errorMessage = message;
      })
      .addCase(logout.pending, (state) => {
        state.logoutObj.status = "pending";
      })
      .addCase(logout.fulfilled, (state, action) => {
        const { message } = action.payload;

        state.logoutObj.status = "succeeded";
        state.logoutObj.successMessage = message;

        state.user = null;
        state.logoutObj.data = null;
        setAxiosToken(null);
        localStorage.removeItem("YiJiaUserAuthToken");
        localStorage.removeItem("YiJiaUserExpireAt");
      })
      .addCase(logout.rejected, (state, action) => {
        const { message } = action.error;

        state.logoutObj.status = "failed";
        state.logoutObj.errorMessage = message;
      });
  },
});

export default authSlice.reducer;

// state
export const authSelector = (state: RootState) => state.auth;

export const { resetUser } = authSlice.actions;
