import { Grid, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";

import BorderButton from "../../../components/button/border-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTextField from "../../../components/forms/form-text-field.component";
import Form from "../../../components/forms/form.component";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import Spacer from "../../../components/utils/spacer.component";

interface Props {
  firstName: string;
  lastName: string;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  handleSubmit: (values: any) => void;
  isNameEdit: boolean;
  setIsNameEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

function UpdateNameForm({
  firstName,
  lastName,
  setIsEditing,
  isEditing,
  handleSubmit,
  isNameEdit,
  setIsNameEdit,
  isLoading,
}: Props) {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().min(2).label("First Name"),
    lastName: Yup.string().required().min(2).label("Last Name"),
  });

  const handleEditPress = () => {
    setIsEditing(true);
    setIsNameEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsNameEdit(false);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography>Name</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isNameEdit ? (
                <BorderButton onClick={handleCancelPress}>Cancel</BorderButton>
              ) : (
                <BorderButton onClick={handleEditPress} disabled={isEditing}>
                  Edit
                </BorderButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isNameEdit ? (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                firstName,
                lastName,
              }}
              onSubmit={handleSubmit}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <FormTextField name="firstName" label="First Name" />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <FormTextField name="lastName" label="Last Name" />
                  </Grid>
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading}>Save</FormSubmitButton>
            </Form>
          ) : (
            <Typography color="text.secondary">
              {firstName === "" ? "Not Provided" : `${firstName} ${lastName}`}
            </Typography>
          )}
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="s" />
    </>
  );
}

export default UpdateNameForm;
