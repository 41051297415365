import { Box, Button, Typography, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";
import Loader from "react-js-loader";

interface Props {
  isLoading?: boolean;
  disabled?: boolean;
  error: boolean;
  onClick: () => void;
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}

const BorderButton = ({
  children,
  disabled,
  isLoading,
  onClick,
  error,
}: PropsWithChildren<Props>) => {
  const theme = useTheme();

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      color={error ? "error" : "primary"}
      disabled={disabled || isLoading}
      disableElevation={true}
      sx={{ textTransform: "none", width: "100%" }}
    >
      {isLoading ? (
        <Box
          sx={{
            height: "24px",
            width: "100px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Loader type="bubble-loop" bgColor={theme.palette.primary.dark} size={35} />
        </Box>
      ) : (
        <Typography fontWeight="bold">{children}</Typography>
      )}
    </Button>
  );
};

BorderButton.defaultProps = {
  error: false,
};

export default BorderButton;
