import { Box, Grid, List, ListItem, styled, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import carb from "../../../../src/assets/images/carb.png";
import meat from "../../../../src/assets/images/meat.png";
import side from "../../../../src/assets/images/side.png";
import vege from "../../../../src/assets/images/vege.png";
import Spacer from "../../../components/utils/spacer.component";
import theme from "../../../infrastructure/theme";
import { FoodSetObjectType } from "../../order/preorder/type/type";

interface Props {
  item: FoodSetObjectType;
  image?: string;
}

const FoodTypeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "110px",
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderSizes[1],
  overflow: "hidden",
  padding: "5px",
}));

const PackageItem = ({ item, image }: Props) => {
  const [showPrice, setShowPrice] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const renderNoPax = () => {
    const arr = [];
    for (let i = item.rule.minNoPax; i <= item.rule.maxNoPax; i++)
      arr.push(
        <Typography variant="h6" fontWeight="bold" color="primary" textAlign="center">
          {i} pax
        </Typography>,
      );
    return arr;
  };

  const renderPrice = () => {
    const arr = [];
    for (let i = item.rule.minNoPax; i <= item.rule.maxNoPax; i++)
      arr.push(
        <Typography variant="h6" fontWeight="bold" color="text.secondary" textAlign="center">
          RM {(parseFloat(item.pricePerPax) * i).toFixed(2)}
        </Typography>,
      );
    return arr;
  };
  return (
    <Box
      sx={{
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderSizes[1],
        padding: "10px",
        cursor: "pointer",
        minHeight: showPrice ? "780px" : "550px",
      }}
    >
      <Grid
        onClick={() => setShowPrice(!showPrice)}
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        rowSpacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="bold">
            {item.name}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <img
            src={
              item.image_path
                ? item.image_path
                : "https://yijiacanteen-production.sgp1.cdn.digitaloceanspaces.com/default/yijia-logo.jpeg"
            }
            alt={image}
            width="80%"
            style={{
              objectFit: "cover",
              aspectRatio: 1 / 1,
              borderRadius: theme.shape.borderSizes[1],
            }}
          />
        </Grid>
        <Grid
          item
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          columnSpacing={isMobile ? 0.5 : 1}
        >
          {item.carb.max > 0 && (
            <Grid item xs={3}>
              <FoodTypeContainer>
                <Box width="40px" height="40px">
                  <img src={carb} alt={carb} width="100%" />
                </Box>
                <Typography fontSize={13}>Carb</Typography>
              </FoodTypeContainer>
            </Grid>
          )}
          {item.meat.max > 0 && (
            <Grid item xs={3}>
              <FoodTypeContainer>
                <Box width="50px" height="50px">
                  <img src={meat} alt={meat} width="100%" />
                </Box>

                <Box>
                  <Typography textAlign="center" fontSize={13}>
                    Meat
                  </Typography>
                  <Typography color="primary" fontSize={8} textAlign="center">
                    Pork / Chicken
                  </Typography>
                </Box>
              </FoodTypeContainer>
            </Grid>
          )}
          {item.vege.max > 0 && (
            <Grid item xs={3}>
              <FoodTypeContainer>
                <Box width="40px" height="50px">
                  <img src={vege} alt={vege} width="100%" />
                </Box>

                <Typography textAlign="center" fontSize={13}>
                  Vegetable
                </Typography>
                <Typography color="primary" fontSize={8} textAlign="center">
                  Daily From Farm
                </Typography>
              </FoodTypeContainer>
            </Grid>
          )}
          {item.side.max > 0 && (
            <Grid item xs={3}>
              <FoodTypeContainer>
                <Box width="50px" height="50px">
                  <img src={side} alt={side} width="100%" />
                </Box>

                <Typography textAlign="center" fontSize={13}>
                  Side
                </Typography>
              </FoodTypeContainer>
            </Grid>
          )}
        </Grid>

        <Grid item sx={{ minHeight: "160px" }}>
          <Grid container alignItems="center">
            <Typography variant="h6" fontWeight="bold">
              {item.name}
            </Typography>
            <Spacer position="left" />
            <Typography color="text.secondary">
              {item.rule.minNoPax === item.rule.maxNoPax
                ? `(only for ${item.rule.minNoPax} pax)`
                : `(min. ${item.rule.minNoPax} pax, max. ${item.rule.maxNoPax} pax)`}
            </Typography>
          </Grid>
          <List sx={{ listStyleType: "disc", pl: 5 }}>
            {item.vege_side_combination.map((comb) => (
              <ListItem
                sx={{
                  display: "list-item",
                  textAlign: "justify",
                  padding: 0,
                  paddingBottom: "5px",
                }}
                key={Math.random()}
              >
                <Typography>
                  {item.meat.max !== 0 && `${item.meat.max} meat`}{" "}
                  {comb.vege !== 0 && `+ ${comb.vege} vege`}{" "}
                  {comb.side !== 0 && `+ ${comb.side} side`}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>

        {showPrice && (
          <Grid item container xs={12} flexDirection="row" justifyContent="flex-end">
            <Grid item xs={8}>
              <Typography variant="h6" color="text.secondary" textAlign="center">
                ({item.totalMeals} meals)
              </Typography>
            </Grid>
          </Grid>
        )}

        {showPrice && (
          <Grid
            item
            container
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            columnSpacing={1}
          >
            <Grid item xs={4}>
              {renderNoPax()}
            </Grid>
            <Grid item xs={8}>
              {renderPrice()}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PackageItem;
