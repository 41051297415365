import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

const PickUpAddressSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container display="flex" alignItems="center" justifyContent="center">
      <Grid item xs={isMobile ? 12 : 8}>
        <Typography fontWeight="bold" color="text.secondary">
          Pickup Address:
        </Typography>
        <Typography>77-G, Jalan Bandar 1, Pusat Bandar Puchong, 47100 Puchong, Selangor</Typography>
      </Grid>
    </Grid>
  );
};

export default PickUpAddressSection;
