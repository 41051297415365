import { Box, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import Loader from "react-js-loader";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";
import { getOrderBill } from "../../../services/order/bill/order-bill-slice.service";
import { useAppDispatch } from "../../../services/store";

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "50px",
});

const StyledTitle = styled(Typography)({
  fontWeight: "bold",
  textAlign: "center",
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: "center",
}));

export default function BillCheckScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const loadDataFromNet = () => {
    let remoteBillId = new URLSearchParams(location.search).get("orderId");
    if (!remoteBillId) {
      remoteBillId = new URLSearchParams(location.search).get("order");
    }
    if (new URLSearchParams(location.search).get("status") === "CANCELLED") {
      navigate(routes.BILL_FAILED, { state: { remoteBillId } });
      return;
    }
    dispatch(getOrderBill(remoteBillId)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        if (payload.data.bill.status === "paid") {
          navigate(routes.BILL_SUCCESS);
        } else if (payload.data.status === "due") {
          navigate(routes.BILL_FAILED, { state: { remoteBillId } });
        } else {
          setTimeout(() => {
            loadDataFromNet();
          }, 3000);
        }
      }
    });
  };

  useEffect(() => {
    loadDataFromNet();
  }, []);

  return (
    <StyledContainer>
      <Spacer size="xxxl" />
      <Loader type="bubble-loop" bgColor={theme.palette.primary.main} size={100} />
      <Spacer size="xxl" />
      <StyledTitle variant={isMobile ? "h4" : "h3"}>Payment Processing</StyledTitle>
      <Spacer size="m" />
      <GreyText>Your payment request is being processed.</GreyText>
      <Spacer size="m" />
      <GreyText>Please do not close or refresh the page.</GreyText>
      <Spacer size="xxxl" />
      <Spacer size="xxl" />
    </StyledContainer>
  );
}
