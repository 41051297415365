import { Box, ButtonBase, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useLocation } from "react-router-dom";
import { ReactComponent as DeliveryLogo } from "../../../assets/images/delivery.svg";
import { ReactComponent as SelfPickUpLogo } from "../../../assets/images/self-pick-up.svg";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";
import theme from "../../../infrastructure/theme";
import { PreorderItemFormValues } from "../preorder/type/type";
import { SameDayFormValues } from "../same-day/type/type";

const ButtonBox = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>(({ isMobile }) => ({
  height: "65px",
  width: isMobile ? "100%" : "220px",
  border: "2px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderSizes[2],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const DeliveryOptionSection = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setFieldValue, values } = useFormikContext<SameDayFormValues | PreorderItemFormValues>();
  const editable =
    location.pathname === routes.PREORDER_ITEM_EDIT
      ? (values as PreorderItemFormValues).currentMealEditable
      : true;
  return (
    <Grid container alignItems="center" justifyContent="center" columnSpacing={isMobile ? 0.5 : 10}>
      {location.pathname !== routes.SAME_DAY_ORDER && (
        <Grid item xs={isMobile ? 6 : undefined}>
          <ButtonBox
            isMobile={isMobile}
            disabled={!editable}
            sx={{
              backgroundColor: values.type === "delivery" ? "primary.main" : "white",
              filter: values.type === "delivery" ? "" : "grayscale(100%)",
            }}
            onClick={() => setFieldValue("type", "delivery")}
          >
            <DeliveryLogo
              width={40}
              fill={values.type === "delivery" ? "white" : theme.palette.primary.main}
            />
            <Spacer position="left" size="m" />
            <Typography
              color={values.type === "delivery" ? "text.white" : "primary"}
              fontWeight="bold"
            >
              Delivery
            </Typography>
          </ButtonBox>
        </Grid>
      )}
      <Grid item xs={isMobile ? 6 : undefined}>
        <ButtonBox
          isMobile={isMobile}
          disabled={!editable}
          sx={{
            backgroundColor: values.type === "pickup" ? "primary.main" : "white",
            filter: values.type === "pickup" ? "" : "grayscale(100%)",
          }}
          onClick={() => setFieldValue("type", "pickup")}
        >
          <SelfPickUpLogo
            width={40}
            fill={values.type === "pickup" ? "white" : theme.palette.primary.main}
          />
          <Spacer position="left" size="m" />
          <Typography color={values.type === "pickup" ? "text.white" : "primary"} fontWeight="bold">
            Self Pick Up
          </Typography>
        </ButtonBox>
      </Grid>
      {location.pathname.includes(routes.SAME_DAY_ORDER) && (
        <Grid item xs={isMobile ? 6 : undefined}>
          <Box
            sx={{
              height: "65px",
              width: isMobile ? "100%" : "220px",
              border: "2px solid",
              borderColor: theme.palette.text.secondary,
              backgroundColor: theme.palette.text.secondary,
              borderRadius: theme.shape.borderSizes[2],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DeliveryLogo width={40} fill="white" />
            <Spacer position="left" size="m" />
            <Grid item>
              <Typography color="text.white" fontWeight="bold" textAlign="center">
                Delivery
              </Typography>
              <Typography color="primary" fontWeight="bold" fontStyle="italic">
                Coming Soon!
              </Typography>
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DeliveryOptionSection;
