import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../infrastructure/theme";
import { logout, resetUser } from "../../services/auth/auth-slice.service";
import { resetProfile } from "../../services/profile/profile-slice.service";
import { useAppDispatch } from "../../services/store";
import BackgroundButton from "../button/background-button.component";
import routes from "../navigation/routes";
import Spacer from "../utils/spacer.component";
import TopTitleBox from "../utils/top-title-box.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>(({ isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24",
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

interface Props {
  isShow: boolean;
  setIsShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SessionExpiredBox({ isShow, setIsShowAlert }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleConfirm = () => {
    setIsShowAlert(false);
    dispatch(logout({})).then(() => {
      dispatch(resetProfile());
      dispatch(resetUser());
      navigate(routes.LOGIN);
      navigate(routes.LOGIN);
    });
  };

  return (
    <Modal open={isShow}>
      <ModalBox isMobile={isMobile}>
        <Grid container>
          <TopTitleBox
            title="Session Expired"
            requireCloseButton={false}
            setShowModal={setIsShowAlert}
          />
          <Grid item xs={12}>
            <Spacer size="l" />
            Please log back in to continue using the app.
          </Grid>
          <Grid item xs={12} paddingTop="50px">
            <BackgroundButton onClick={handleConfirm}>Continue</BackgroundButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}
