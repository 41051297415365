import { Avatar, Box, Grid, styled, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import CustomSkeleton from "../../../../components/utils/custom-skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";
import { profileSelector } from "../../../../services/profile/profile-slice.service";
import theme from "../../../theme";
import { isLogin } from "../../../utils";

const StyledBox = styled(Box)(() => ({
  display: "flex",
  height: "25%",
  minHeight: "160px",
  background: theme.palette.primary.main,
  alignItems: "center",
}));

const CenteredRowBox = styled(Box)({
  alignItems: "center",
  flexDirection: "row",
  display: "flex",
});

const StyledAvatar = styled(Avatar)(() => ({
  width: 75,
  height: 75,
  border: `2px solid #fff`,
}));

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  color: "white",
}));

interface Props {
  handleDrawerToggle: () => void;
}

function DrawerAvatar({ handleDrawerToggle }: Props) {
  const { profileObj } = useSelector(profileSelector);
  const navigate = useNavigate();

  const renderLoggedInData = () => {
    if (profileObj.status === "succeeded" && profileObj.data) {
      return (
        <>
          <Grid
            item
            xs={12}
            onClick={() => {
              navigate(routes.USER_PROFILE);
              handleDrawerToggle();
            }}
          >
            <CenteredRowBox>
              <StyledAvatar src={profileObj.data.imagePath} />
              <Spacer position="left" size="m" />
              <Grid item>
                <Typography variant="h5" fontWeight="bold" color="text.white">
                  Welcome.
                </Typography>
                <Typography
                  variant="h6"
                  color="text.white"
                  sx={{
                    textAlign: "start",
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                  }}
                >
                  {profileObj.data.firstName || "there"}!
                </Typography>
              </Grid>
            </CenteredRowBox>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item xs={12}>
          <CenteredRowBox>
            <StyledAvatar />
            <Spacer position="left" size="m" />
            <CustomSkeleton width="100px" height="30px" />
          </CenteredRowBox>
        </Grid>
      </>
    );
  };

  const renderGuestData = () => (
    <Grid item xs={12}>
      <Grid
        container
        sx={{
          paddingLeft: "30px",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          color="text.white"
          sx={{
            paddingBottom: "10px",
          }}
        >
          Welcome.
        </Typography>
        <Grid container item columnSpacing={1}>
          <Grid item>
            <StyledLink to={routes.LOGIN} onClick={handleDrawerToggle}>
              <Typography>Log In</Typography>
            </StyledLink>
          </Grid>
          <Grid item>
            <Typography
              color="text.white"
              sx={{
                paddingRight: "2px",
                paddingTop: "2px",
              }}
            >
              |
            </Typography>
          </Grid>
          <Grid item>
            <StyledLink to={routes.SIGN_UP} onClick={handleDrawerToggle}>
              <Typography>Sign Up</Typography>
            </StyledLink>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <StyledBox>
      <Grid container spacing={2} sx={{ paddingLeft: "15px" }}>
        {isLogin() ? renderLoggedInData() : renderGuestData()}
      </Grid>
    </StyledBox>
  );
}

export default DrawerAvatar;
