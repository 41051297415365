import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PreorderResource, SameDayOrderResource } from "../../features/order/type/type";
import { getReq, postReq } from "../api.services";
import { idleObject, PaginatedCollection } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const createOrder = createAsyncThunk("order/create", async (payload: any) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders`, payload);
  return response;
});

export const getOrderList = createAsyncThunk("order/index", async (payload: any) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/orders?${
      payload.page ? "page=".concat(payload.page) : "page=1"
    }${payload.status ? "&status=".concat(payload.status) : ""}`,
  );
  return response;
});

export const getOrder = createAsyncThunk("order/{id}", async (payload: any) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/orders/${payload.id}${
      payload.calendarDate ? "?calendarDate=".concat(payload.calendarDate) : ""
    }`,
  );
  return response;
});

export const payOrder = createAsyncThunk("order/pay", async (payload: any) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders/pay`, payload);
  return response;
});

export const cancelOrder = createAsyncThunk("order/cancel", async (payload: any) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/orders/cancel`, payload);
  return response;
});

interface initialStateType {
  sameDayOrderCreatedObj: SameDayOrderResource | null;
  sameDayOrderUpdatingObj: SameDayOrderResource | null;
  preorderCreatedObj: PreorderResource | null;
  preorderUpdatingObj: PreorderResource | null;
  createOrderObj: RequestObject<PreorderResource | SameDayOrderResource>;
  getOrderObj: RequestObject<PreorderResource | SameDayOrderResource>;
  getOrderListObj: RequestObject<PaginatedCollection<PreorderResource | SameDayOrderResource>>;
  payOrderObj: RequestObject;
  cancelOrderObj: RequestObject;
}

const initialState: initialStateType = {
  sameDayOrderCreatedObj: null,
  sameDayOrderUpdatingObj: null,
  preorderCreatedObj: null,
  preorderUpdatingObj: null,
  createOrderObj: idleObject(),
  getOrderObj: idleObject(),
  getOrderListObj: idleObject(),
  payOrderObj: idleObject(),
  cancelOrderObj: idleObject(),
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setSameDayOrderCreated: (state, action) => {
      state.sameDayOrderCreatedObj = action.payload;
    },
    resetSameDayOrderCreated: (state) => {
      state.sameDayOrderCreatedObj = null;
    },
    setSameDayOrderUpdating: (state, action) => {
      state.sameDayOrderUpdatingObj = action.payload;
    },
    resetSameDayOrderUpdating: (state) => {
      state.sameDayOrderUpdatingObj = null;
    },
    setPreorderCreated: (state, action) => {
      state.preorderCreatedObj = action.payload;
    },
    resetPreorderCreated: (state) => {
      state.preorderCreatedObj = null;
    },
    setPreorderUpdating: (state, action) => {
      state.preorderUpdatingObj = action.payload;
    },
    resetPreorderUpdating: (state) => {
      state.preorderUpdatingObj = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.createOrderObj.status = "pending";
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createOrderObj.status = "succeeded";
        state.createOrderObj.data = data;
        state.createOrderObj.successMessage = message;
      })
      .addCase(createOrder.rejected, (state, action) => {
        const { message } = action.error;

        state.createOrderObj.status = "failed";
        state.createOrderObj.errorMessage = message;
      })
      .addCase(getOrder.pending, (state) => {
        state.getOrderObj.status = "pending";
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getOrderObj.status = "succeeded";
        state.getOrderObj.data = data;
        state.getOrderObj.successMessage = message;
      })
      .addCase(getOrder.rejected, (state, action) => {
        const { message } = action.error;

        state.getOrderObj.status = "failed";
        state.getOrderObj.errorMessage = message;
      })
      .addCase(getOrderList.pending, (state) => {
        state.getOrderListObj.status = "pending";
      })
      .addCase(getOrderList.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getOrderListObj.status = "succeeded";
        state.getOrderListObj.data = data;
        state.getOrderListObj.successMessage = message;
      })
      .addCase(getOrderList.rejected, (state, action) => {
        const { message } = action.error;

        state.getOrderListObj.status = "failed";
        state.getOrderListObj.errorMessage = message;
      })
      .addCase(payOrder.pending, (state) => {
        state.payOrderObj.status = "pending";
      })
      .addCase(payOrder.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.payOrderObj.status = "succeeded";
        state.payOrderObj.data = data;
        state.payOrderObj.successMessage = message;
      })
      .addCase(payOrder.rejected, (state, action) => {
        const { message } = action.error;

        state.payOrderObj.status = "failed";
        state.payOrderObj.errorMessage = message;
      })
      .addCase(cancelOrder.pending, (state) => {
        state.cancelOrderObj.status = "pending";
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.cancelOrderObj.status = "succeeded";
        state.cancelOrderObj.data = data;
        state.cancelOrderObj.successMessage = message;
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        const { message } = action.error;

        state.cancelOrderObj.status = "failed";
        state.cancelOrderObj.errorMessage = message;
      });
  },
});

export default orderSlice.reducer;

// state
export const orderSelector = (state: RootState) => state.order;

export const {
  setSameDayOrderCreated,
  resetSameDayOrderCreated,
  setSameDayOrderUpdating,
  resetSameDayOrderUpdating,
  setPreorderCreated,
  resetPreorderCreated,
  setPreorderUpdating,
  resetPreorderUpdating,
} = orderSlice.actions;
