import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAPI } from "../../infrastructure/utils";
import { getReq } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getFoodSets = createAsyncThunk("foodSet/index", async () => {
  const response = await getReq(`${getAPI()}/food_sets/`);
  return response;
});

interface initialStateType {
  getFoodSetsObj: RequestObject;
}

const initialState: initialStateType = {
  getFoodSetsObj: idleObject(),
};

const foodSetSlice = createSlice({
  name: "foodSet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFoodSets.pending, (state) => {
        state.getFoodSetsObj.status = "pending";
      })
      .addCase(getFoodSets.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getFoodSetsObj.status = "succeeded";
        state.getFoodSetsObj.data = data;
        state.getFoodSetsObj.successMessage = message;
      })
      .addCase(getFoodSets.rejected, (state, action) => {
        const { message } = action.error;

        state.getFoodSetsObj.status = "failed";
        state.getFoodSetsObj.errorMessage = message;
      });
  },
});

export default foodSetSlice.reducer;

// state
export const foodSetSelector = (state: RootState) => state.foodSet;
