import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
  label: string;
  pushTo: string;
}

function TopLeftLink({ label, pushTo }: Props) {
  return (
    <Box sx={{ position: "absolute", left: "25px", top: "15px" }}>
      <Link to={pushTo} style={{ textDecoration: "none" }}>
        <Typography color="primary">{label}</Typography>
      </Link>
    </Box>
  );
}

export default TopLeftLink;
