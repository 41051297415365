import {
  Button,
  Grid,
  Pagination,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import SideMenu from "../../../components/profile-side-menu/side-menu.component";
import CustomSkeleton from "../../../components/utils/custom-skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { getOrderList, orderSelector, payOrder } from "../../../services/order/order-slice.service";
import { useAppDispatch, useAppSelector } from "../../../services/store";
import { PreorderResource, SameDayOrderResource } from "../../order/type/type";
import OrderDetailBox from "../components/order-detail-box.component";
import OrderDetailModal from "../components/order-detail-modal.component";
import StatusButtonSection from "../components/status-button-section.component";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";

const StyledButton = styled(Button)({
  textTransform: "none",
});

const MyOrderScreen = () => {
  const dispatch = useAppDispatch();
  const { getOrderListObj } = useAppSelector(orderSelector);
  const [status, setStatus] = useState<string>("all");
  const [page, setPage] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<
    PreorderResource | SameDayOrderResource | null
  >(null);
  const snackBar = useSnackBar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const getOrders = (newPage: number, newStatus: string) => {
    dispatch(getOrderList({ page: newPage, status: newStatus === "all" ? null : newStatus }));
  };

  const onChangeStatus = (newStatus: string) => {
    setStatus(newStatus);
    getOrders(page, newStatus);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    getOrders(newPage, status);
  };

  useEffect(() => {
    getOrders(page, status);
  }, []);

  const payPendingOrder = (order_id: number) => {
    dispatch(payOrder({ payment_method: "others", order_id }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          window.open(res.data.bill.remote_url, "_self");
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <Grid container display="flex">
      <OrderDetailModal
        showModal={showModal}
        setShowModal={setShowModal}
        itemDetails={selectedOrder}
        payPendingOrder={payPendingOrder}
      />
      {!isMobile && (
        <Grid item xs={3.5}>
          <SideMenu />
        </Grid>
      )}

      <Grid item container xs={isMobile ? 12 : 8.5} spacing={2} display="flex">
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            My Orders
          </Typography>

          {isMobile &&
            getOrderListObj.status === "succeeded" &&
            getOrderListObj.data.items.length === 0 && (
              <StyledButton
                onClick={() => navigate(routes.HOME)}
                variant="contained"
                sx={{ textTransform: "none", width: "30%" }}
              >
                <Typography fontWeight="bold">Order Now</Typography>
              </StyledButton>
            )}
        </Grid>
        <StatusButtonSection status={status} onChangeStatus={onChangeStatus} />
        <Grid
          container
          item
          spacing={1}
          sx={{ minHeight: "50vh", alignItems: "center", justifyContent: "center" }}
        >
          {getOrderListObj.status === "succeeded" ? (
            getOrderListObj.data.items.length > 0 ? (
              <>
                {getOrderListObj.data.items.map((order) => (
                  <Grid
                    item
                    xs={12}
                    key={order.id}
                    onClick={() => {
                      setSelectedOrder(order);
                      setShowModal(true);
                    }}
                  >
                    <OrderDetailBox itemDetails={order} payPendingOrder={payPendingOrder} />
                  </Grid>
                ))}
                <Grid item>
                  <Spacer />
                  <Pagination
                    page={page}
                    onChange={(event, newPage) => onPageChange(newPage)}
                    count={getOrderListObj.data.pagination.totalPages}
                    variant="outlined"
                  />
                </Grid>
              </>
            ) : (
              <Typography variant="h6" fontWeight="bold">
                No Orders
              </Typography>
            )
          ) : (
            <>
              <Grid item xs={12}>
                <CustomSkeleton height={280} />
              </Grid>
              <Grid item xs={12}>
                <CustomSkeleton height={280} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyOrderScreen;
