import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import MealPrepBackground from "../../../assets/images/close-up-delicious-meal-preparation.png";
import Form from "../../../components/forms/form.component";
import routes from "../../../components/navigation/routes";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import { register } from "../../../services/auth/auth-slice.service";
import { getProfile } from "../../../services/profile/profile-slice.service";
import { useAppDispatch } from "../../../services/store";
import SignUpForm from "../components/sign_up/sign-up-form.component";
import { SignUpFormValues } from "../type/type";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().min(2).label("First Name"),
  lastName: Yup.string().required().min(2).label("Last Name"),
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
  phoneNumber: Yup.number().min(9).required().label("Phone Number"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), ""], "Password and confirm password must be same.")
    .label("Password confirmation"),
  optForNewsletter: Yup.boolean().required(),
});

function SignUpScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmitRegister = (values: SignUpFormValues) => {
    setIsLoading(true);
    dispatch(register(values))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          dispatch(getProfile());
          navigate(routes.EMAIL_VERIFICATION_CODE, { state: { email: values.email } });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const initialValues: SignUpFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    optForNewsletter: true,
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "115vh",
        backgroundImage: `url(${MealPrepBackground})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        paddingX: isMobile ? "0px" : "100px",
        overflow: "hidden",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12}>
                <Form
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmitRegister}
                >
                  <SignUpForm isLoading={isLoading} />
                </Form>
              </Grid>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default SignUpScreen;
