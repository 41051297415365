import { Divider, Grid } from "@mui/material";
import Spacer from "./spacer.component";

interface Props {
  spacerSize: "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl";
}

function HorizontalDivider({ spacerSize }: Props) {
  return (
    <Grid item xs={12}>
      <Spacer size={spacerSize} />
      <Divider light />
      <Spacer size={spacerSize} />
    </Grid>
  );
}

HorizontalDivider.defaultProps = {
  spacerSize: "m",
};

export default HorizontalDivider;
