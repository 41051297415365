import InfoIcon from "@mui/icons-material/Info";
import { Grid, styled, Tooltip, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import Spacer from "../../../components/utils/spacer.component";
import theme from "../../../infrastructure/theme";
import { OrderFood, PreorderResource } from "../../order/type/type";

interface Props {
  itemDetails: PreorderResource;
  showAllInformation: boolean;
}

const SpaceBetweenGrid = styled(Grid)({
  justifyContent: "space-between",
});

const BoldText = styled(Typography)({
  fontWeight: "bold",
});

const PreorderDetail = ({ itemDetails, showAllInformation }: Props) => {
  const [viewMore, setViewMore] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const getStatusColor = (status: string) => {
    if (status === "ongoing") {
      return "warning.main";
    }
    if (status === "completed") {
      return "success.main";
    }

    if (status === "pending_payment") {
      return "error";
    }

    if (status === "expired") {
      return "error";
    }

    return "text.primary";
  };

  const renderRemainingItems = () => {
    const items = [];

    if (itemDetails) {
      for (
        let i = itemDetails.total_ordered_meals + 1;
        i <= (viewMore ? itemDetails.total_meals : 3 - itemDetails.total_ordered_meals);
        i++
      ) {
        items.push(
          <Grid item container key={i}>
            <Grid item xs={12} sx={{ paddingBottom: "5px" }}>
              <Typography fontWeight="bold" color="text.secondary">
                <span style={{ color: theme.palette.text.primary }}>Meal {i}: </span>
                Decide Later
              </Typography>
            </Grid>
          </Grid>,
        );
      }
    }

    return items;
  };

  return (
    <>
      <SpaceBetweenGrid container item>
        <BoldText variant="h6">Order No: #{itemDetails.id}</BoldText>{" "}
        <BoldText variant="h6" textTransform="capitalize" color="info.main">
          {itemDetails.type.replace("_", " ")}
        </BoldText>
      </SpaceBetweenGrid>
      <Grid item />
      {!showAllInformation && (
        <SpaceBetweenGrid container item>
          <Typography>Status</Typography>
          <BoldText textTransform="capitalize" color={getStatusColor(itemDetails.status)}>
            {itemDetails.status.replace("_", " ")}
          </BoldText>
        </SpaceBetweenGrid>
      )}

      <SpaceBetweenGrid container item>
        <Typography>Food Set</Typography>
        <BoldText>{itemDetails.food_set?.identifier}</BoldText>
      </SpaceBetweenGrid>
      <SpaceBetweenGrid container item>
        <Typography>No of Pax</Typography>
        <BoldText>{itemDetails.no_of_pax}</BoldText>
      </SpaceBetweenGrid>
      <SpaceBetweenGrid container item>
        <Typography>Ordered Meals</Typography>
        <BoldText>
          {itemDetails.total_ordered_meals} / {itemDetails.total_meals}
        </BoldText>
      </SpaceBetweenGrid>
      <SpaceBetweenGrid container item>
        <Typography>Validity Period</Typography>
        <BoldText>Till {itemDetails.expired_at}</BoldText>
      </SpaceBetweenGrid>
      {showAllInformation && (
        <>
          <HorizontalDivider />
          <Grid item container xs={12}>
            <Grid item container>
              <Grid item xs={isMobile ? 12 : 10}>
                <Typography fontWeight="bold">Order Details</Typography>
              </Grid>
              {!isMobile && (
                <Grid item xs={2}>
                  <Typography fontWeight="bold" textAlign="center">
                    Order Status
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Spacer size="m" />
            </Grid>
            {itemDetails.items.slice(0, viewMore ? undefined : 2).map((item, index) => (
              <Grid item container key={index} columnSpacing={0.2}>
                <Grid item xs={isMobile ? 12 : 10}>
                  <Grid item>
                    <Typography fontWeight="bold" color="text.secondary">
                      <span style={{ color: theme.palette.text.primary }}>Meal {index + 1}: </span>
                      {dayjs(item.date_time).format("DD MMM YY, dddd, h:00 A")} -{" "}
                      {dayjs(new Date(item.date_time)).add(1, "h").format("h:mm A")}
                    </Typography>
                  </Grid>
                  {isMobile && (
                    <>
                      <Spacer />
                      <Grid item>
                        <Typography fontWeight="bold" color="text.secondary">
                          Status:{" "}
                          <span
                            style={{
                              color: item.confirmed
                                ? item.status === "pending"
                                  ? theme.palette.primary.main
                                  : theme.palette.success.main
                                : theme.palette.error.main,
                            }}
                          >
                            {item.confirmed
                              ? item.status === "pending"
                                ? "Pending"
                                : "Delivered"
                              : "Not Confirmed"}
                          </span>
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Spacer />
                  <Grid item>
                    <Typography fontWeight="bold" color="text.secondary">
                      Food Selection:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {Object.entries(item.foods)
                        .map(([key, value]) =>
                          value.map((food: OrderFood) => {
                            if (key === "carb" || key === "addon") {
                              return food.identifier + ` x ${food.no_of_pax}`;
                            } else {
                              return food.identifier;
                            }
                          }),
                        )
                        .flat()
                        .join(", ")}
                    </Typography>
                  </Grid>
                  <Spacer />
                  {item.delivery && (
                    <>
                      <Grid item>
                        <Typography fontWeight="bold" color="text.secondary">
                          Delivery Details:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {item.delivery.address.unit_no}
                          {", "} {item.delivery.address.location}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Spacer size="m" />
                </Grid>
                {!isMobile && (
                  <Grid item xs={2}>
                    <Grid item>
                      <Typography
                        textAlign="center"
                        fontWeight="bold"
                        color={
                          item.confirmed
                            ? item.status === "pending"
                              ? theme.palette.primary.main
                              : theme.palette.success.main
                            : theme.palette.error.main
                        }
                      >
                        {item.confirmed
                          ? item.status === "pending"
                            ? "Pending"
                            : "Delivered"
                          : "Not Confirmed"}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <HorizontalDivider />
                </Grid>
              </Grid>
            ))}
            {renderRemainingItems()}
            <Grid item xs={12} sx={{ cursor: "pointer" }} onClick={() => setViewMore(!viewMore)}>
              <Typography
                fontWeight="bold"
                color="primary.main"
                sx={{ textDecoration: "underline" }}
              >
                {viewMore ? "Hide" : "View More"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="l" />
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">Payment Summary:</Typography>
          </Grid>
        </>
      )}
      <SpaceBetweenGrid container item>
        <Typography>Last Paid At</Typography>
        <BoldText>
          {dayjs(itemDetails.payment_summary.transactions.at(-1)?.paid_at).format(
            "DD MMM YY, dddd, h:mm A",
          )}
        </BoldText>
      </SpaceBetweenGrid>
      {showAllInformation && (
        <>
          <SpaceBetweenGrid container item>
            <Typography>Subtotal</Typography>
            <BoldText color="success.main">RM {itemDetails.payment_summary.subtotal}</BoldText>
          </SpaceBetweenGrid>
          {itemDetails.payment_summary.carb !== "0.00" && (
            <SpaceBetweenGrid container item>
              <Typography>Extra Charge for Carb</Typography>
              <BoldText color="success.main">RM {itemDetails.payment_summary.carb}</BoldText>
            </SpaceBetweenGrid>
          )}

          {itemDetails.payment_summary.addons !== "0.00" && (
            <SpaceBetweenGrid container item>
              <Typography>Extra Charge for Add on</Typography>
              <BoldText color="success.main">RM {itemDetails.payment_summary.addons}</BoldText>
            </SpaceBetweenGrid>
          )}
          {itemDetails.payment_summary.delivery !== "0.00" && (
            <SpaceBetweenGrid container item>
              <Typography>Total Delivery Fee</Typography>
              <BoldText color="success.main">RM {itemDetails.payment_summary.delivery}</BoldText>
            </SpaceBetweenGrid>
          )}
        </>
      )}
      <SpaceBetweenGrid container item>
        <Typography>Total</Typography>
        <BoldText color="success.main">RM {itemDetails.payment_summary.total}</BoldText>
      </SpaceBetweenGrid>
      <SpaceBetweenGrid container item>
        <Typography>Total Paid</Typography>
        <BoldText color="success.main">RM {itemDetails.payment_summary.paid.total_amount}</BoldText>
      </SpaceBetweenGrid>
      {itemDetails.payment_summary.outstanding_total !== "0.00" && (
        <SpaceBetweenGrid container item>
          <Grid container item xs={7} alignItems="center">
            <Typography color="error">Outstanding Total</Typography>
            <Spacer position="left" />
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: { padding: "10px" },
                },
              }}
              title="Please ensure that any outstanding balance is paid to confirm the processing of your order. Thank you!"
              arrow
            >
              <InfoIcon color="error" />
            </Tooltip>
          </Grid>
          <BoldText color="error">RM {itemDetails.payment_summary.outstanding_total}</BoldText>
        </SpaceBetweenGrid>
      )}
      {itemDetails.payment_summary.excess_total !== "0.00" && (
        <SpaceBetweenGrid container item>
          <Grid container item xs={6} alignItems="center">
            <Typography color="error">Excess Total</Typography>
            <Spacer position="left" />
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: { padding: "10px" },
                },
              }}
              title="Any additional EXCESS payments cannot be refunded once processed."
              arrow
            >
              <InfoIcon color="error" />
            </Tooltip>
          </Grid>{" "}
          <BoldText color="error">RM {itemDetails.payment_summary.excess_total}</BoldText>
        </SpaceBetweenGrid>
      )}
    </>
  );
};

PreorderDetail.defaultProps = {
  showAllInformation: false,
};

export default PreorderDetail;
