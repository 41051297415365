import AddIcon from "@mui/icons-material/Add";
import { Grid, Pagination, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import BackgroundButton from "../../../components/button/background-button.component";
import Form from "../../../components/forms/form.component";
import ConfirmationModal from "../../../components/notification/confirmation-modal.component";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import SideMenu from "../../../components/profile-side-menu/side-menu.component";
import CustomSkeleton from "../../../components/utils/custom-skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  addressSelector,
  createAddress,
  deleteAddress,
  getAddressList,
  updateAddress,
} from "../../../services/address/address-slice.services";
import { useAppDispatch, useAppSelector } from "../../../services/store";
import AddressBox from "../components/address-box.component";
import AddressFormModal from "../components/address-form-modal.component";
import { AddressFormValues, AddressResource } from "../type/type";

const validationSchema = Yup.object().shape({
  identifier: Yup.string().required().label("Address Name"),
  remark: Yup.string().required().label("Remark"),
  pic: Yup.string().required().label("Person in charge"),
  pic_contact_no: Yup.string().required().label("Contact No"),
  unit_no: Yup.string().required().label("Unit No"),
  location: Yup.string().required().label("Location"),
  location_lat: Yup.string().required().label("Lat"),
  location_long: Yup.string().required().label("Long"),
  postcode: Yup.string().required().label("Postcode"),
  city: Yup.string().required().label("City"),
  state: Yup.string().required().label("State"),
});

const UserAddressesScreen = () => {
  const dispatch = useAppDispatch();
  const { getAddressListObj } = useAppSelector(addressSelector);
  const [page, setPage] = useState(1);
  const [showAddressFormModal, setShowAddressFormModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressResource | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const formRef = useRef<FormikProps<AddressFormValues>>();
  const snackBar = useSnackBar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    dispatch(getAddressList({ page: newPage }));
  };

  useEffect(() => {
    onPageChange(1);
  }, []);

  const initialValues: AddressFormValues = {
    identifier: "",
    remark: "",
    pic: "",
    pic_contact_no: "",
    unit_no: "",
    location: "",
    location_lat: "",
    location_long: "",
    postcode: "",
    city: "",
    state: "",
  };
  const submitAddressForm = (values: AddressFormValues) => {
    let dispatchFunction = null;
    if (selectedAddress) {
      dispatchFunction = updateAddress;
    } else {
      dispatchFunction = createAddress;
    }

    dispatch(dispatchFunction(values))
      .unwrap()
      .then((res) => {
        if (res.success) {
          setShowAddressFormModal(false);
          formRef.current?.resetForm();
          onPageChange(page);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const removeAddress = () => {
    dispatch(deleteAddress(selectedAddress?.id)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteConfirmation(false);
        onPageChange(page);
        snackBar.createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
    });
  };

  return (
    <Grid container display="flex">
      <ConfirmationModal
        showModal={showDeleteConfirmation}
        setShowModal={setShowDeleteConfirmation}
        onConfirm={removeAddress}
        title="Remove Address"
        message={`Are you sure to remove address name ${selectedAddress?.identifier}?`}
      />
      {!isMobile && (
        <Grid item xs={3.5}>
          <SideMenu />
        </Grid>
      )}

      <Grid
        item
        container
        xs={isMobile ? 12 : 8.5}
        spacing={2}
        display="flex"
        sx={{ height: "100%" }}
      >
        <Form
          initialValues={initialValues}
          onSubmit={submitAddressForm}
          validationSchema={validationSchema}
          innerRef={formRef}
        >
          <AddressFormModal
            showModal={showAddressFormModal}
            setShowModal={setShowAddressFormModal}
            edit={selectedAddress ? true : false}
          />
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                My Addresses
              </Typography>
            </Grid>
            <Grid item>
              <BackgroundButton
                startIcon={<AddIcon />}
                onClick={() => {
                  if (selectedAddress) {
                    formRef.current?.resetForm();
                    formRef.current?.setErrors({});
                    setSelectedAddress(null);
                  }
                  setShowAddressFormModal(true);
                }}
              >
                Add New Address
              </BackgroundButton>
            </Grid>
          </Grid>
          <Grid container item spacing={1} sx={{ minHeight: "40vh", justifyContent: "center" }}>
            {getAddressListObj.status === "succeeded" ? (
              getAddressListObj.data.items.length > 0 ? (
                <>
                  {getAddressListObj.data.items.map((item) => (
                    <Grid item xs={12} key={item.id}>
                      <AddressBox
                        itemDetails={item}
                        setSelectedAddress={setSelectedAddress}
                        setShowDeleteConfirmation={setShowDeleteConfirmation}
                        setShowAddressFormModal={setShowAddressFormModal}
                      />
                    </Grid>
                  ))}
                  <Grid item>
                    <Spacer />
                    <Pagination
                      page={page}
                      onChange={(event, newPage) => onPageChange(newPage)}
                      count={getAddressListObj.data.pagination.totalPages}
                      variant="outlined"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item sx={{ alignSelf: "center" }}>
                  <Typography variant="h6" fontWeight="bold">
                    No Addresses
                  </Typography>
                </Grid>
              )
            ) : (
              <>
                <Grid item xs={12}>
                  <CustomSkeleton height={180} />
                </Grid>
                <Grid item xs={12}>
                  <CustomSkeleton height={180} />
                </Grid>
                <Grid item xs={12}>
                  <CustomSkeleton height={180} />
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      </Grid>
    </Grid>
  );
};

export default UserAddressesScreen;
