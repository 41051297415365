import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAPI } from "../../infrastructure/utils";
import { getReq } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getHighlightDishes = createAsyncThunk("highlight_dish/index", async () => {
  const response = await getReq(`${getAPI()}/highlight_dishes/`);
  return response;
});

interface initialStateType {
  getHighlightDishesObj: RequestObject;
}

const initialState: initialStateType = {
  getHighlightDishesObj: idleObject(),
};

const highlightDishSlice = createSlice({
  name: "highlightDish",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHighlightDishes.pending, (state) => {
        state.getHighlightDishesObj.status = "pending";
      })
      .addCase(getHighlightDishes.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getHighlightDishesObj.status = "succeeded";
        state.getHighlightDishesObj.data = data;
        state.getHighlightDishesObj.successMessage = message;
      })
      .addCase(getHighlightDishes.rejected, (state, action) => {
        const { message } = action.error;

        state.getHighlightDishesObj.status = "failed";
        state.getHighlightDishesObj.errorMessage = message;
      });
  },
});

export default highlightDishSlice.reducer;

// state
export const highlightDishSelector = (state: RootState) => state.highlightDish;
