import { Box, Grid, LinearProgress, styled, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";
import routes from "../../../components/navigation/routes";

const CircleBox = styled(Box)({
  height: "40px",
  width: "40px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
});

const StyledGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const StageProgressBar = () => {
  const theme = useTheme();
  const location = useLocation();
  const stages = [17, 50, 100];

  const getCurrentStage = () => {
    if (
      location.pathname.includes(routes.SAME_DAY_ORDER) &&
      !location.pathname.includes("summary")
    ) {
      return 1;
    }
    if (
      location.pathname.includes(routes.PREORDER_ITEM) &&
      !location.pathname.includes("summary")
    ) {
      return 1;
    }
    if (
      (
        [
          routes.PREORDER_MEAL_SUMMARY,
          routes.PREORDER_MEAL_EDIT_SUMMARY,
          routes.SAME_DAY_ORDER_SUMMARY,
          routes.SAME_DAY_ORDER_EDIT_SUMMARY,
        ] as string[]
      ).includes(location.pathname)
    ) {
      return 2;
    }
    return 0;
  };

  return (
    <Grid container position="relative" paddingTop="20px">
      <Grid item xs={12}>
        <LinearProgress
          variant="determinate"
          value={stages[getCurrentStage()]}
          sx={{ borderRadius: theme.shape.borderRadius, height: 6 }}
        />
      </Grid>
      <Grid
        item
        container
        sx={{
          position: "absolute",
          top: 0,
        }}
      >
        <Grid container>
          <StyledGrid item xs={4}>
            <CircleBox
              sx={{
                backgroundColor:
                  getCurrentStage() >= 0
                    ? theme.palette.primary.main
                    : theme.palette.secondary.dark,
              }}
            >
              1
            </CircleBox>
          </StyledGrid>
          <StyledGrid item xs={4}>
            <CircleBox
              sx={{
                backgroundColor:
                  getCurrentStage() >= 1
                    ? theme.palette.primary.main
                    : theme.palette.secondary.dark,
              }}
            >
              2
            </CircleBox>
          </StyledGrid>
          <StyledGrid item xs={4}>
            <CircleBox
              sx={{
                backgroundColor:
                  getCurrentStage() >= 2
                    ? theme.palette.primary.main
                    : theme.palette.secondary.dark,
              }}
            >
              3
            </CircleBox>
          </StyledGrid>
        </Grid>
        <Grid container>
          <StyledGrid item xs={4}>
            <Typography>
              {location.pathname.includes(routes.SAME_DAY_ORDER) ? "Menu" : "Meal"}
            </Typography>
          </StyledGrid>
          <StyledGrid item xs={4}>
            <Typography>Order</Typography>
          </StyledGrid>
          <StyledGrid item xs={4}>
            <Typography>Checkout</Typography>
          </StyledGrid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StageProgressBar;
