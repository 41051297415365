import { Button, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";

const StyledButton = styled(Button)({
  textTransform: "none",
});

interface Props {
  status: string;
  onChangeStatus: (newStatus: string) => void;
}

const StatusButtonSection = ({ status, onChangeStatus }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item container columnSpacing={2} rowSpacing={1}>
      <Grid item xs={isMobile ? 6 : 2}>
        <StyledButton
          onClick={() => onChangeStatus("all")}
          variant={status === "all" ? "contained" : "outlined"}
          color="info"
          sx={{ textTransform: "none", width: "100%" }}
        >
          <Typography fontWeight="bold">All</Typography>
        </StyledButton>
      </Grid>
      <Grid item xs={isMobile ? 6 : 2}>
        <StyledButton
          variant={status === "ongoing" ? "contained" : "outlined"}
          color="warning"
          sx={{ textTransform: "none", width: "100%" }}
          onClick={() => onChangeStatus("ongoing")}
        >
          <Typography fontWeight="bold">Ongoing</Typography>
        </StyledButton>
      </Grid>
      <Grid item xs={isMobile ? 6 : 3}>
        <StyledButton
          variant={status === "pending_payment" ? "contained" : "outlined"}
          color="error"
          sx={{ textTransform: "none", width: "100%" }}
          onClick={() => onChangeStatus("pending_payment")}
        >
          <Typography fontWeight="bold">Pending Payment</Typography>
        </StyledButton>
      </Grid>
      <Grid item xs={isMobile ? 6 : 2}>
        <StyledButton
          variant={status === "completed" ? "contained" : "outlined"}
          color="success"
          sx={{ textTransform: "none", width: "100%" }}
          onClick={() => onChangeStatus("completed")}
        >
          <Typography fontWeight="bold">Completed</Typography>
        </StyledButton>
      </Grid>
      <Grid item xs={isMobile ? 6 : 2}>
        <StyledButton
          variant={status === "expired" ? "contained" : "outlined"}
          color="error"
          sx={{ textTransform: "none", width: "100%" }}
          onClick={() => onChangeStatus("expired")}
        >
          <Typography fontWeight="bold">Expired</Typography>
        </StyledButton>
      </Grid>
    </Grid>
  );
};

export default StatusButtonSection;
