import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";

import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormTextField from "../../../../components/forms/form-text-field.component";
import Form from "../../../../components/forms/form.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import { ForgotPasswordFormValues } from "../../type/type";

interface Props {
  handleSubmit: (values: ForgotPasswordFormValues) => void;
  isLoading: boolean;
}

function EmailForm({ handleSubmit, isLoading }: Props) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: theme.shape.borderSizes[1],
        flex: 1,
        maxWidth: 500,
        minWidth: isMobile ? null : 450,
        alignItems: "center",
        justifyContent: "center",
        boxShadow: 2,
        paddingTop: "10px",
      }}
    >
      <TopTitleBox title="Forgot Password?" requireCloseButton={false} />
      <Form
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <PaddedView multiples={isMobile ? 3 : 4}>
          <Typography textAlign="center">
            Enter your email address to receive verification code.
          </Typography>
          <Spacer size="l" />

          <FormTextField name="email" label="Email Address" />

          <Spacer size="xxxl" />

          <FormSubmitButton isLoading={isLoading}>Confirm</FormSubmitButton>
        </PaddedView>
      </Form>
    </Box>
  );
}

export default EmailForm;
