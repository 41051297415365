import { Box, Grid, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import CustomSkeleton from "../../../components/utils/custom-skeleton.component";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import theme from "../../../infrastructure/theme";
import {
  getPopularDishes,
  popularDishSelector,
} from "../../../services/popular-dish/popular-dish-slice.services";
import { useAppDispatch, useAppSelector } from "../../../services/store";

interface PopularDish {
  id: number;
  image_path: string;
}

function PopularDishSection() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const { getPopularDishesObj } = useAppSelector(popularDishSelector);

  useEffect(() => {
    dispatch(getPopularDishes());
  }, []);

  return (
    <Grid container paddingTop="50px">
      <Grid item xs={12}>
        {getPopularDishesObj.status === "succeeded" ? (
          <ItemCarousel
            slidesToShow={isMobile ? 1.2 : isTablet ? 2 : 3}
            slidesToScroll={isTablet ? 1 : 2}
          >
            {getPopularDishesObj.data.map((item: PopularDish) => (
              <Box key={item.id}>
                <img
                  src={
                    item.image_path
                      ? item.image_path
                      : "https://yijiacanteen-production.sgp1.cdn.digitaloceanspaces.com/default/yijia-logo.jpeg"
                  }
                  alt={"popular dish"}
                  width="100%"
                  style={{
                    borderRadius: theme.shape.borderSizes[1],
                    objectFit: "cover",
                    aspectRatio: 1 / 1,
                  }}
                />
              </Box>
            ))}
          </ItemCarousel>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={isTablet ? 12 : 4}>
              <CustomSkeleton height={271} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default PopularDishSection;
