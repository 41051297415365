import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";

import YiJiaLogo from "../../../assets/images/YiJia_logo";
import TopLeftLink from "../../../components/button/top-left-link.component";
import routes from "../../../components/navigation/routes";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  forgotPassword,
  resendVerificationCode,
  resetPassword,
} from "../../../services/auth/auth-slice.service";
import { useAppDispatch } from "../../../services/store";
import CreateNewPassword from "../components/forgot_password/create-new-password.component";
import EmailForm from "../components/forgot_password/enter-email-form.component";
import ResetSuccess from "../components/forgot_password/password-reset-successful.component";
import { ForgotPasswordFormValues, ResetPasswordFormValues } from "../type/type";

function ForgotPassword() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentScreen, setCurrentScreen] = useState("Forgot Password");
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();

  const handleSubmitResendCodes = () => {
    const values = {
      email: userEmail,
      type: "forgotPassword",
    };
    dispatch(resendVerificationCode(values))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleSubmitCreateNewPassword = (values: ResetPasswordFormValues) => {
    setIsLoading(true);
    values.email = userEmail;

    dispatch(resetPassword(values))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          setCurrentScreen("Reset Successful");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleSubmitEmail = (values: ForgotPasswordFormValues) => {
    setUserEmail(values.email);
    setIsLoading(true);
    dispatch(forgotPassword(values))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          setCurrentScreen("Create New Password");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const renderComponent = () => {
    if (currentScreen === "Create New Password") {
      return (
        <CreateNewPassword
          isLoading={isLoading}
          userEmail={userEmail}
          handleSubmitResendCodes={handleSubmitResendCodes}
          handleSubmit={handleSubmitCreateNewPassword}
        />
      );
    }
    if (currentScreen === "Reset Successful") {
      return <ResetSuccess />;
    }
    return <EmailForm isLoading={isLoading} handleSubmit={handleSubmitEmail} />;
  };

  return (
    <Box
      sx={{
        backgroundColor: "bg.primary",
        opacity: 1,
        width: "100%",
        minHeight: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <TopLeftLink label="< Back to Login" pushTo={routes.LOGIN} />
      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <YiJiaLogo />
              </Grid>
              <Spacer size="m" />
              <Grid item xs={12}>
                {renderComponent()}
              </Grid>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default ForgotPassword;
