import { Grid, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";

import dayjs from "dayjs";
import BorderButton from "../../../components/button/border-button.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import Spacer from "../../../components/utils/spacer.component";

interface Props {
  dateOfBirth: any;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  handleSubmit: (values: any) => void;
  isDOBEdit: boolean;
  setIsDOBEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

function UpdateDOBForm({
  dateOfBirth,
  setIsEditing,
  isEditing,
  handleSubmit,
  isDOBEdit,
  setIsDOBEdit,
  isLoading,
}: Props) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const validationSchema = Yup.object().shape({
    dateOfBirth: Yup.string().notRequired().nullable(),
  });

  const handleEditPress = () => {
    setIsEditing(true);
    setIsDOBEdit(true);
  };

  const handleCancelPress = () => {
    setIsEditing(false);
    setIsDOBEdit(false);
  };

  const dateConverter = (dob: any) => {
    const newDate = new Date(Date.parse(dob));
    const date = newDate.getDate();
    const month = months[newDate.getMonth()];
    const year = newDate.getFullYear();
    return `${month} ${date}, ${year}`;
  };

  return (
    <>
      <Grid container spacing={1} sx={{ paddingY: 3 }}>
        <Grid item xs={6}>
          <Typography>Date of Birth</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isDOBEdit ? (
                <BorderButton onClick={handleCancelPress}>Cancel</BorderButton>
              ) : (
                <BorderButton onClick={handleEditPress} disabled={isEditing}>
                  Edit
                </BorderButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isDOBEdit ? (
            <Form
              validationSchema={validationSchema}
              initialValues={{
                dateOfBirth: dayjs(dateOfBirth),
              }}
              onSubmit={handleSubmit}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="center" flexDirection="column">
                    <Spacer size="m" />
                    <FormDatePicker name="dateOfBirth" disablePast={false} />
                  </Grid>
                </Grid>
              </Grid>
              <Spacer position="top" size="m" />
              <FormSubmitButton isLoading={isLoading}>Save</FormSubmitButton>
            </Form>
          ) : (
            <Typography color="text.secondary">
              {dateOfBirth === "" ? "Not Provided" : dateConverter(dateOfBirth)}
            </Typography>
          )}
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="s" />
    </>
  );
}

export default UpdateDOBForm;
