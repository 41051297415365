import { Box, Grid, useTheme } from "@mui/material";
import SideMenuAvatar from "./side-menu-avatar.component";
import SideMenuItem from "./side-menu-item.component";

function SideMenu() {
  const theme = useTheme();

  return (
    <Box sx={{ paddingBottom: 0, width: "100%" }}>
      <Grid justifyContent="flex-start" container sx={{ width: "100%" }}>
        <Grid
          container
          sx={{
            width: "95%",
            boxShadow: 2,
            minWidth: "250px",
            borderRadius: theme.shape.borderSizes[1],
          }}
        >
          <Grid item xs={12}>
            <SideMenuAvatar />
          </Grid>
          <Grid item xs={12}>
            <SideMenuItem />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SideMenu;
