import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FormikValues, useFormikContext } from "formik";

export type selections = {
  type: string;
  value: string | number;
};

interface Props {
  name: string;
  disabled?: boolean;
  label?: string;
  selections: selections[];
  submitOnChange?: boolean;
}

function FormSelectFieldText({ name, selections, disabled, label, submitOnChange }: Props) {
  const { setFieldValue, values, setFieldTouched, touched, errors, handleSubmit } =
    useFormikContext<FormikValues>();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (e: SelectChangeEvent) => {
    setFieldValue(name, e.target.value);
    if (submitOnChange) {
      handleSubmit();
    }
  };

  return (
    <FormControl fullWidth size="small">
      {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
      <Select
        size="small"
        label={label}
        onBlur={() => setFieldTouched(name)}
        disabled={disabled}
        value={values[name]}
        onChange={handleChange}
        error={showError}
      >
        {selections.map((selection) => (
          <MenuItem value={selection.value} key={`${selection.type}-${selection.value}`}>
            {selection.type}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error>{String(errors[name])}</FormHelperText>}
    </FormControl>
  );
}

FormSelectFieldText.defaultProps = {
  disabled: false,
  submitOnChange: false,
};

export default FormSelectFieldText;
