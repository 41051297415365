import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import FormAddressAutoComplete from "../../../components/forms/form-address-auto-complete.component";
import Form from "../../../components/forms/form.component";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../services/store";
import { CheckDeliveryRateFormValues } from "../../address/type/type";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import {
  addressSelector,
  checkDeliveryRate,
} from "../../../services/address/address-slice.services";
import CheckRate from "../../../assets/images/check_rate.png";
import CustomSkeleton from "../../../components/utils/custom-skeleton.component";

const validationSchema = Yup.object().shape({
  location: Yup.string().required().label("Location"),
  location_lat: Yup.string().required().label("Lat"),
  location_long: Yup.string().required().label("Long"),
  postcode: Yup.string().required().label("Postcode"),
  city: Yup.string().required().label("City"),
  state: Yup.string().required().label("State"),
});

const CheckDeliveryRateSection = () => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { checkDeliveryRateObj } = useAppSelector(addressSelector);

  const initialValues: CheckDeliveryRateFormValues = {
    location: "",
    location_lat: "",
    location_long: "",
    postcode: "",
    city: "",
    state: "",
  };
  const submitAddressForm = (values: CheckDeliveryRateFormValues) => {
    dispatch(checkDeliveryRate(values))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: `The delivery fee is RM ${res.data.polygon.total_chargeable}`,
            type: "success",
            open: true,
          });
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <Grid
      container
      sx={{ height: "100%" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item container xs={isMobile ? 11.5 : 6} spacing={2}>
        <Grid item xs={12}>
          <Typography
            textAlign={isMobile ? "center" : undefined}
            variant="h5"
            fontWeight="bold"
            color="text.secondary"
          >
            Delivery Coverage
          </Typography>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography>Within 3 km from Puchong</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">FREE</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography>Klang Valley</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">From RM X.XX</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography>Check Delivery Rates</Typography>
          </Grid>
          {checkDeliveryRateObj.status === "succeeded" && (
            <Grid item>
              <Typography fontWeight="bold" color="primary.dark" textAlign="right">
                {checkDeliveryRateObj.data.polygon.total_chargeable === "0.00"
                  ? "FREE"
                  : `RM ${checkDeliveryRateObj.data.polygon.total_chargeable}`}
              </Typography>
            </Grid>
          )}
          {checkDeliveryRateObj.status === "failed" && (
            <Grid item xs={5}>
              <Typography fontWeight="bold" color="primary.dark" textAlign="right">
                Not in coverage
              </Typography>
            </Grid>
          )}
          {checkDeliveryRateObj.status === "pending" && (
            <Grid item xs={3}>
              <CustomSkeleton />
            </Grid>
          )}
        </Grid>

        <Form
          initialValues={initialValues}
          onSubmit={submitAddressForm}
          validationSchema={validationSchema}
        >
          <Grid item container justifyContent="space-between" alignItems="center" columnSpacing={1}>
            <Grid item xs={9}>
              <FormAddressAutoComplete name="location" label="Address" />
            </Grid>
            <Grid item xs={3}>
              <FormSubmitButton>Check</FormSubmitButton>
            </Grid>
          </Grid>
        </Form>
      </Grid>
      {!isMobile && (
        <Grid item xs={6} sx={{ position: "relative", overflow: "hidden", height: "500px" }}>
          <Box sx={{ position: "absolute", bottom: 0, right: -100 }}>
            <img src={CheckRate} style={{ width: "100%" }} alt="check_rate" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default CheckDeliveryRateSection;
